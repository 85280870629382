import {create} from 'zustand';
import { SelectOptionDossier } from '../../interface/selects';

interface MyState {

    startDate: Date;
    setStartDate: (startDate: Date) => void;

    interlocutors: any[];
    setInterlocutors: (interlocutor: any[]) => void;
    
    selectedOrigin: string;
    setSelectedOrigin: (origin: string) => void;

    selectedLibelle: string;
    setSelectedLibelle: (libelle: string) => void;

    origins: any[];
    setOrigins: (origins: any[]) => void;

    libelles: any[];
    setLibelles: (libelles: any[]) => void;

    totalCredit: string;
    setTotalCredit: (totalCredit: string) => void;

    compteurs: any[];
    setCompteurs: (compteur: any[]) => void;

    selectedCompteur: string;
    setSelectedCompteur: (compteur: string) => void;

    invoiceInformation: any;
    setInvoiceInformation: (invoiceInformation: any) => void;

    solde: number;
    setSolde: (solde: number) => void;

    selectedInterlocutors: number;
    setSelectedInterlocutors: (interlocutors: number) => void;

    modeCredit: string;
    setModeCredit: (modeCredit: string) => void;

    creditData: any;
    setCreditData: (creditData: any) => void;

  }
  
  export const useCreateCreditStore = create<MyState>((set) => ({

    startDate: new Date(),
    setStartDate: (startDate: Date) => set({ startDate }),

    interlocutors: [],
    setInterlocutors: (interlocutors: any[]) => set({ interlocutors }),

    selectedOrigin: '',
    setSelectedOrigin: (origin: string) => set({ selectedOrigin: origin }),

    selectedLibelle: '',
    setSelectedLibelle: (libelle: string) => set({ selectedLibelle: libelle }),

    origins: [],
    setOrigins: (origins: SelectOptionDossier[]) => set({ origins }),

    libelles: [],
    setLibelles: (libelles: SelectOptionDossier[]) => set({ libelles }),

    totalCredit: "",
    setTotalCredit: (totalCredit: string) => set({ totalCredit }),

    compteurs: [],
    setCompteurs: (compteurs: any[]) => set({ compteurs }),

    selectedCompteur: '',
    setSelectedCompteur: (compteur: string) => set({ selectedCompteur: compteur }),

    invoiceInformation: [],
    setInvoiceInformation: (invoiceInformation: any[]) => set({ invoiceInformation }),

    solde: 0,
    setSolde: (solde: number) => set({ solde }),

    selectedInterlocutors: 0,
    setSelectedInterlocutors: (interlocutors: number) => set({ selectedInterlocutors: interlocutors }),

    modeCredit: 'create',
    setModeCredit: (modeCredit: string) => set({ modeCredit }),

    creditData: [],
    setCreditData: (creditData: any) => set({ creditData }),
  }));
