export const dataHeaderInvoice =[ "Dossier", "Mission", "Origine", "Libellé", "Acteur", "Date facturation", "Px Unit.", "Qté", "Date création",  "Numéro", "HT", "TVA", "TTC", "Commentaire"] 

export const orderedColumnsInvoice = ["Dossier", "Mission", "Origine", "Libellé", "Acteur", "Date facturation", "Px Unit.", "Qté", "Date création", "Numéro", "HT", "TVA", "TTC", "Commentaire"]

type ColumnWidths = {
    [key: string]: number;
};

interface MonthMap {
  [key: string]: number;
}

export const columnWidths: ColumnWidths  = { 
  "Dossier": 15,
  "Mission": 10,
  "Origine": 10,
  "Libellé": 8,
  "Acteur": 2,
  "Date facturation": 6,
  "Px Unit.": 5,
  "Qté": 5,
  "Date création": 6,
  "Numéro": 6,
  "HT": 5,
  "TVA": 5,
  "TTC": 5,
  "Commentaire": 10,
};

export const columnMappings: any = {
    "Dossier": "DOS_TITRE",
    "Mission": "MIS_LIBELLE",
    "Origine": "AOR_CODE",
    "Libellé": "ANA_CODELIB",
    "Acteur": "ANA_OPERATEUR",
    "Date facturation": "ANA_DATEREALISATION",
    "Px Unit.": ".ANA_EPRIXUNITAIRE",
    "Qté": "ANA_QUANTITE",
    "Date création": "ANA_DATESAISIE",
    "Numéro": "ANA_FACTURE",
    "HT": "ANA_EMTHT",
    "TVA": "ANA_EMTTVA",
    "TTC": "ANA_EMTTTC",
    "Commentaire": "ANA_NOTES",
  };

export const monthMap : MonthMap = {
  "janvier": 1,
  "février": 2,
  "mars": 3,
  "avril": 4,
  "mai": 5,
  "juin": 6,
  "juillet": 7,
  "août": 8,
  "septembre": 9,
  "octobre": 10,
  "novembre": 11,
  "décembre": 12,
};
