import { useClientFileStore } from "../../../store/Crm/ClientFile";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";

/**
 * @component
 * @name CreateClientFile
 * @description CreateClientFile est un composant qui permet d'afficher la modal pour créer un dossier client.
 * @author Iris Succi
 * @date 2023
 */
const CreateClientFile = () => {
  const { setChoiceTab } = useUtilsCrmStore();
  const { updateData } = useClientFileStore();

  const handleCreate = () => {
    setChoiceTab("Create");
  };

  return (
    !updateData && (
      <button
        className="px-2 my-2 w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
        onClick={handleCreate}
      >
        Créer un dossier
      </button>
    )
  );
};

export default CreateClientFile;
