import axios from "axios";
import { axiosWithToken } from "../axiosService";
import { getFormattedDateEN } from "../datepicker";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / delete slips
 */

export const getSlips = () => {
    try {
        const response = axiosWithToken().get(`api/slips`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const postSlip = async (date: any, bankCash: any) => {
    try {
      const response = await axiosWithToken().post(`api/slips?ATR_DATE_BORDEREAU=${getFormattedDateEN(date)}&ABA_REF=${bankCash}`,  { responseType: 'blob' });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.statusText || error.message);
      }
      throw error;
    }
  };

export const deleteSlip = (date: any, bankCash: any) => {
    try {
        const response = axiosWithToken().delete(`api/slips?ATR_DATE_BORDEREAU=${date}&ABA_REF=${bankCash}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipDocument = (date: any, bankCash: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/document?ATR_DATE_BORDEREAU=${date}&ABA_REF=${bankCash}`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}