import { useViewStore } from "../../../store/TimeManagement/viewStore";

/**
 * @component
 * @name SaveDataBeforeReturn
 * @author Succi Iris
 * @date 2023
 * @description
 * `SaveDataBeforeReturn` is a React Functional Component that displays a modal warning the user about unsaved data.
 * It offers the user a choice to continue without saving or to cancel the action. This component is typically used
 * when there are pending changes in a form or a similar context, and the user attempts to navigate away or close the view.
 * @param {Function} props.closeModal - A function to close the modal dialog.
 */
const SaveDataBeforeReturn = ({ closeModal }: any) => {
  // Store
  const { setView } = useViewStore();

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t w-full">
              <h3 className="text-base md:text-3xl font-semibold text-center">
                ATTENTION
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-base text-center p-5">
              Vous avez des données non sauvegardées.
              <br />
              Voulez-vous continuer sans sauvegarder ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Non
              </button>
              <button
                className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  closeModal();
                  setView("week");
                }}
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default SaveDataBeforeReturn;
