import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useUtilsAnalyseStore } from "../../store/Analyse/utilsAnalyse";
import { useLoadingStore } from "../../store/utils/loading";
import CircleLoarder from "../TimeManager/loader/CircleLoarder";
import { getRandomColor } from "./Chart";

/**
 * @name PieCharts
 * @author Iris Succi
 * @abstract Component pour afficher pie charts
 * @date 24/01/2024
 * @returns PieCharts
 */
const PieCharts = () => {
  const { isLoading } = useLoadingStore();
  const { data } = useUtilsAnalyseStore();
  const transformDataForPie = (data: any) => {
    return data?.map((item: any) => {
      const key = Object.keys(item)[0];
      return { name: key, value: item[key] };
    });
  };

  // Prendre l'avant-dernier élément pour 'previous' et le dernier élément pour 'current'
  const dataLength = data.length;
  const dataPrevious = dataLength >= 2 ? data[dataLength - 2].previous : [];
  const dataCurrent = dataLength >= 1 ? data[dataLength - 1].current : [];

  // Récupérer les titres des séries
  const titlePrevious = dataLength >= 2 ? data[dataLength - 2].serie : "";
  const titleCurrent = dataLength >= 1 ? data[dataLength - 1].serie : "";

  const formattedDataPrevious = transformDataForPie(dataPrevious);
  const formattedDataCurrent = transformDataForPie(dataCurrent);
  // Objet pour stocker les couleurs par clé
  const colorsByKey: { [key: string]: string } = {};

  // Fonction pour obtenir la couleur pour une clé donnée
  const getColorForKey = (key: any) => {
    if (!colorsByKey[key]) {
      colorsByKey[key] = getRandomColor(); // Génére une nouvelle couleur si elle n'existe pas déjà
    }
    return colorsByKey[key];
  };

  const isIterable = (data: any) => Array.isArray(data) && data.length > 0;

  // Créer un ensemble de toutes les clés uniques
  const uniqueKeys = new Set(
    [
      ...(isIterable(formattedDataCurrent) ? formattedDataCurrent : []),
      ...(isIterable(formattedDataPrevious) ? formattedDataPrevious : []),
    ].map((item) => item.name)
  );

  // Composant de légende personnalisé
  const renderLegend = () => {
    return (
      <ul className="w-full flex justify-center items-center">
        {Array.from(uniqueKeys).map((key, index) => (
          <li
            key={index}
            style={{ color: getColorForKey(key) }}
            className="mr-4 flex justify-center items-center"
          >
            <div
              style={{ backgroundColor: getColorForKey(key) }}
              className=" w-2 h-2 mr-1"
            ></div>
            {key}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey mt-4">
          <CircleLoarder />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <text
              x="35%"
              y="10%"
              textAnchor="middle"
              dominantBaseline="central"
              className="font-bold"
            >
              {titleCurrent}
            </text>
            <Pie
              dataKey="value"
              data={formattedDataCurrent}
              cx="35%"
              cy="50%"
              outerRadius={150}
              innerRadius={75}
              label
            >
              {formattedDataCurrent?.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={getColorForKey(entry.name)} />
              ))}
            </Pie>
            <text
              x="65%"
              y="10%"
              textAnchor="middle"
              dominantBaseline="central"
              className="font-bold"
            >
              {titlePrevious}
            </text>
            <Pie
              dataKey="value"
              data={formattedDataPrevious}
              cx="65%"
              cy="50%"
              innerRadius={75}
              outerRadius={150}
              label
            >
              {formattedDataPrevious?.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={getColorForKey(entry.name)} />
              ))}
            </Pie>
            <Tooltip />
            <Legend content={renderLegend} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default PieCharts;
