import { useEffect } from "react";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useSlip } from "../../../../store/Invoice/createSlip";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import { useLoadingStore } from "../../../../store/utils/loading";
import { errorEntry } from "../../../../utils/toaster";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { getCollectingBanks } from "../../../../utils/Invoice/prepayments";
import { postSlip } from "../../../../utils/Invoice/slips";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";

const CreateSlip = () => {
  const { createSlip, setCreateSlip, setVisuPdf } = useCreateFeesOrInvoices();
  const { setDisplayDivVisuPdf } = useCreateInvoiceStore();
  const {
    startDate,
    setStartDate,
    selectedBankCash,
    setSelectedBankCash,
    bankCash,
    setBankCash,
    setSlipDocument,
  } = useSlip();
  const { setIsLoading } = useLoadingStore();
  const { setSendData } = useTabsStore();

  const handleCreateSlip = () => {
    setCreateSlip(false);
    setIsLoading(true);
    postSlip(startDate, selectedBankCash)
      .then(() => {
        setDisplayDivVisuPdf(true);
        setVisuPdf(true);
        setSendData((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
        errorEntry();
      });
  };

  useEffect(() => {
    getCollectingBanks()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.ABA_REF,
          label: file.ABA_LIBELLE,
        }));
        setBankCash(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, [createSlip]);

  return (
    createSlip && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div className="flex flex-col justify-center items-center w-full  py-4">
                <div className="text-sm md:text-base text-center p-5 font-bold border-b border-solid border-slate-200">
                  Veuillez Selectionnez les informations du bordereau(x) à
                  éditer une date d'encaissement d'impayer.
                </div>
                <div className="flex justify-start items-start w-11/12 m-auto pt-4 ">
                  <div className="flex flex-col justify-start items-start mr-6">
                    <p>Date :</p>
                    <Datepicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start">
                    <p>Banque encaissement:</p>
                    <select
                      name="banqueEncaissement"
                      id="banqueEncaissement"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                      value={selectedBankCash}
                      onChange={(event) => {
                        setSelectedBankCash(parseInt(event.target.value));
                      }}
                    >
                      <option value=""></option>
                      {bankCash?.map((banque, i) => (
                        <option key={i} value={banque?.value}>
                          {banque?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setCreateSlip(false);
                    setSlipDocument("");
                  }}
                >
                  Annuler
                </button>
                <button
                  className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleCreateSlip();
                  }}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default CreateSlip;
