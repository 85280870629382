import { create } from 'zustand';

interface MyState {
  quantityDE: any,
  setQuantityDE: (quantityDE: any) => void,

  unitPriceBuy: any,
  setUnitPriceBuy: (priceBuy: any) => void,

  unitPriceSell: any,
  setUnitPriceSell: (priceSell: any) => void,

  htBuy: any,
  setHtBuy: (htBuy: any) => void,

  htSell: any,
  setHtSell: (htSell: any) => void,
  
  tvaBuy: any,
  setTvaBuy: (tvaBuy: any) => void,

  tvaSell: any,
  setTvaSell: (tvaSell: any) => void,

  tvaRate: any[],
  setTvaRate: (tvaRate: any[]) => void,

  ttcBuy: any,
  setTtcBuy: (ttcBuy: any) => void,

  ttcSell: any,
  setTtcSell: (ttcSell: any) => void,

  selectedTvaRateDE: number,
  setSelectedTvaRateDE: (tvaDE: number) => void,

  nsBuy: any,
  setNsBuy: (nsBuy: any) => void,

  nsSell: any,
  setNsSell: (nsSell: any) => void,

  nsBlocked: any,
  setNsBlocked: (nsBlocked: any) => void,

  resetAmountsForDE : () => void,
}

export const amountsForDEStore = create<MyState>((set) => ({
    quantityDE: null,
    setQuantityDE: (quantityDE) => set({ quantityDE }),

    unitPriceBuy: null,
    setUnitPriceBuy: (price) => set({ unitPriceBuy: price }), 

    unitPriceSell: null,
    setUnitPriceSell: (price) => set({ unitPriceSell: price }),
    
    htBuy: 0,
    setHtBuy: (htBuy) => set({ htBuy }),
    
    htSell: 0,
    setHtSell: (htSell) => set({ htSell }),

    tvaBuy: 0,
    setTvaBuy: (tvaBuy) => set({ tvaBuy }),

    tvaSell: 0,
    setTvaSell: (tvaSell) => set({ tvaSell }),
    
    tvaRate: [],
    setTvaRate: (tvaRate) => set({ tvaRate }),
    
    ttcBuy: 0,
    setTtcBuy: (ttcBuy) => set({ ttcBuy }),

    ttcSell: 0,
    setTtcSell: (ttcSell) => set({ ttcSell }),
    
    selectedTvaRateDE: 2,
    setSelectedTvaRateDE: (tvaDE) => set({ selectedTvaRateDE: tvaDE }),

    nsBuy: 0,
    setNsBuy: (nsBuy) => set({ nsBuy }),

    nsSell: 0,
    setNsSell: (nsSell) => set({ nsSell }),

    nsBlocked: 0,
    setNsBlocked: (nsBlocked) => set({ nsBlocked }),

    resetAmountsForDE: () => set({
        quantityDE: 0,
        unitPriceBuy: 0,
        unitPriceSell: 0,
        htBuy: 0,
        htSell: 0,
        tvaBuy: 0,
        tvaSell: 0,
        ttcBuy: 0,
        ttcSell: 0,
        selectedTvaRateDE: 2,
        nsBuy: 0,
        nsSell: 0,
        nsBlocked: 0,
    }),
}));