import { useNavigate } from "react-router-dom";
import dataMenu from "../../data/dataMenu";
import { useRightContext } from "../../context/RightContext";
import { useRightTimeContext } from "../../context/RightTimeContext";
import { useRightInvoiceContext } from "../../context/RightInvoiceContext";
import { useRightStateContext } from "../../context/RightStateContext";
import {
  getRightsInvoices,
  getRightsState,
  getRightsTime,
} from "../../utils/user";

/**
 * @page
 * @name NavBar
 * @description
 * La Navbar est la barre de navigation latérale de l'application. Elle permet de naviguer entre les différentes pages de l'application.
 * @author Iris Succi
 * @date 2023
 */
const Navbar = () => {
  //Hook
  const navigate = useNavigate();

  //Context
  const { buroFact, buroTime, chrono, easyFile, etats, cost } =
    useRightContext();
  const {
    setEditLimit,
    setVisuLimit,
    setTimeEntry,
    setDeleteTime,
    setValidatedTime,
  } = useRightTimeContext();
  const {
    setAcompteRight,
    setAvoirRight,
    setBordereauxRight,
    setProvisionRight,
    setDeboursExtRight,
    setDeboursIntRight,
    setEncaissementRight,
    setFactureRight,
    setHonorairesRight,
    setRelanceRight,
  } = useRightInvoiceContext();
  const {
    setEncaissementStateRight,
    setFacturationStateRight,
    setProductionStateRight,
  } = useRightStateContext();

  // Récupère les droits de l'utilisateur
  const rights: { [key: string]: number } = {
    BuroFact: buroFact,
    BuroTime: buroTime,
    "Chrono des documents": chrono,
    EasyFile: easyFile,
    Etats: etats,
    "Notes de frais": cost,
  };

  /**
   * @function
   * @name handleCardClick
   * @description
   * La fonction handleCardClick permet de récupérer les droits de l'utilisateur en fonction de la page sur laquelle il se trouve.
   * @author Iris Succi
   * @date 2023
   */

  const handleCardClick = async (item: any) => {
    if (item.right === "BuroTime") {
      try {
        const response = await getRightsTime();
        localStorage.setItem("rightsTime", JSON.stringify(response.data));
        setEditLimit(
          response.data["Edition limitée aux temps de l'acteur en cours"]
        );
        setVisuLimit(
          response.data["Visualisation limitée aux temps de l'acteur en cours"]
        );
        setTimeEntry(response.data["Saisie temps"]);
        setDeleteTime(response.data["Suppression des temps interdite"]);
        setValidatedTime(response.data["Validation temps"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "BuroFact") {
      try {
        const response = await getRightsInvoices();
        localStorage.setItem("rightsInvoice", JSON.stringify(response.data));
        setAcompteRight(response.data["Acomptes Clients"]);
        setAvoirRight(response.data["Avoirs sur factures"]);
        setBordereauxRight(response.data["Bordereaux"]);
        setProvisionRight(response.data["Demandes de provision"]);
        setDeboursExtRight(response.data["Débours externes"]);
        setDeboursIntRight(response.data["Débours internes"]);
        setEncaissementRight(response.data["Encaissements"]);
        setFactureRight(response.data["Factures"]);
        setHonorairesRight(response.data["Honoraires"]);
        setRelanceRight(response.data["Relances"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "Etats") {
      const response = await getRightsState();
      localStorage.setItem("rightsState", JSON.stringify(response.data));
      setEncaissementStateRight(response.data["Etats des encaissements"]);
      setFacturationStateRight(response.data["Etats de facturation"]);
      setProductionStateRight(response.data["Etats de production"]);
      console.log(response.data);
    }
    navigate(item.path);
  };

  return (
    <nav className="bg-bc-lightGrey w-12 flex flex-col justify-center items-center mt-5 rounded-r-md shadow-md">
      <ul className="flex flex-col">
        {dataMenu.map(
          (item) =>
            rights[item.right] === 1 && (
              <button
                key={item.id}
                onClick={() => handleCardClick(item)}
                className="flex flex-col justify-center items-center w-12 h-12 hover:bg-bc-grey rounded-r-md"
              >
                <img src={item.logo} alt={item.title} className="w-8 h-8" />
              </button>
            )
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
