import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { CurrentLangContextProvider } from "./context/LangContext.tsx";
import { UserProvider } from "./context/UserContext.tsx";
import { RightProvider } from "./context/RightContext.tsx";
import { RightTimeProvider } from "./context/RightTimeContext.tsx";
import { RightInvoiceProvider } from "./context/RightInvoiceContext.tsx";
import { RightStateProvider } from "./context/RightStateContext.tsx";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <RightProvider>
          <RightTimeProvider>
            <RightInvoiceProvider>
              <RightStateProvider>
                <CurrentLangContextProvider>
                  <App />
                </CurrentLangContextProvider>
              </RightStateProvider>
            </RightInvoiceProvider>
          </RightTimeProvider>
        </RightProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
