import { createContext, useContext, useEffect, useState } from "react";
import { UserContextValue } from "../interface/context";
import { whoAmI } from "../utils/user";
import Cookies from "js-cookie";

const UserContext = createContext<UserContextValue | null>(null);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [id, setId] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [session, setSession] = useState<boolean>(false);

  useEffect(() => {
    const sessionCookie = Cookies.get("sessionId");

    if (sessionCookie) {
      whoAmI()
        .then((res) => {
          setId(res.data[0].identifiant);
          setFirstname(res.data[0].firstname);
          setLastname(res.data[0].lastname);
          setAlias(res.data[0].alias);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        id,
        setId,
        firstname,
        setFirstname,
        lastname,
        setLastname,
        alias,
        setAlias,
        session,
        setSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
