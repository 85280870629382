import calendar from '../assets/icons/navbar/planning.png';
import Missing from "../../src/assets/CE-QUE-VOUS-AVEZ-MANQUE.png"
import solde from "../../src/assets/SOLDE-DU.png"
import date from "../../src/assets/DATE-DU-JOUR.png"

const alertMenu = [{
    id : 1 ,
    logo : Missing, 
    title : "Ce que vous avez manqué" ,
    path : '/alerts',
},{
    id : 2,
    logo : date, 
    title : "Vos rendez-vous du jour" ,
    path : '/appointments',
},{
    id : 3,
    logo : calendar, 
    title : "Votre planning du jour" ,
    path : '/planing',
},{
    id : 3,
    logo : solde, 
    title : "Liste des « dus » clients" ,
    path : '/clients',
}]

export default alertMenu;