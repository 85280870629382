import { create } from 'zustand';
type ClientFileData1 = {
    "Nom / Dénomination sociale": string,
      "PM Enseigne": string,
      "Nom Dirigeant": string,
      "Fonction dirigeant (Libellé)": string,
      "Adresse principale (Rue)": string,
      "Adresse principale (CP)": string,
      "Adresse principale (Ville)": string,
      "Adresse principale (Pays)": string,
      "Téléphone standard": string,
      "Appellation courrier": string,
      "Téléphone direct": string,
      "Téléphone Portable": string,
      "Téléphone personnel": string,
      Télécopie: string,
      Email: string,
      DOS_CODE: number,
      PDOS_ID: number,
};

type ClientFileData2 = {
     /* commercialRegister: commercialRegister, */
      "PM SIRET Numéro": string,
      "PM Revenus catégoriels": string,
      "PM Régime Fiscal": string,
      "Reprise Clôture": string,
      "PM Forme juridique (Libellé)": string,
      "PM Code APE": string,
      "PM RCS Numéro": string,
      "PM Activité": string,
      "PM Capital en Euros": number,
      "PM Nombre de titres": number,
      "PM Mois de fin d'exercice social (Libellé)": string,
      "PM Nbre Salariés": number,
      "Régime TVA (Libellé)": string,
      "PM Nbre de factures Clients annuel": number,
      "PM CA HT annuel": number,
      "PM Nbre de Factures Fournisseurs Annuel": number,
      "PM Lieu d'inscription RCS": string,
}

type ClientFile = {
    filesModel: any;
    setFilesModel: (filesModel: any) => void;
    
    selectedFile: any;
    setSelectedFile: (selectedFile: any) => void;

    name: string;
    setName: (name: string) => void;

    clientSign: string;
    setClientSign: (clientSign: string) => void;

    directing: string;
    setDirecting: (directing: string) => void;

    titlePerson: any;
    setTitlePerson: (titlePerson: any) => void;

    selectedTitlePerson: string;
    setSelectedTitlePerson: (selectedTitlePerson: string) => void;

    role: string;
    setRole: (role: string) => void;

    adress: string;
    setAdress: (adress: string) => void;

    cp: string;
    setCp: (cp: string) => void;

    city: string;
    setCity: (city: string) => void;

    country: string;
    setCountry: (country: string) => void;

    standarPhone: string;
    setStandarPhone: (standarPhone: string) => void;

    directPhone: string;
    setDirectPhone: (directPhone: string) => void;

    mobilePhone: string;
    setMobilePhone: (mobilePhone: string) => void;

    persoPhone: string;
    setPersoPhone: (persoPhone: string) => void;

    fax: string;
    setFax: (fax: string) => void;
    
    email: string;
    setEmail: (email: string) => void;

    /* Page 2 */
    legalForm: any;
    setLegalForm: (legalForm: any) => void;

    selectedLegalForm: string;
    setSelectedLegalForm: (selectedLegalForm: string) => void;

    commercialRegister: string;
    setCommercialRegister: (commercialRegister: string) => void;

    APECode: string;
    setAPECode: (APECode: string) => void;

    RcsNumber: string;
    setRcsNumber: (RcsNumber: string) => void;

    siretNumber: string;
    setSiretNumber: (siretNumber: string) => void;

    categoricalRevenue: string;
    setCategoricalRevenue: (categoricalRevenue: string) => void;

    taxSystem: string;
    setTaxSystem: (taxSystem: string) => void;

    coreBusiness: string;
    setCoreBusiness: (coreBusiness: string) => void;

    capital: number;
    setCapital: (capital: number) => void;

    numberOfTitle: number;
    setNumberOfTitle: (numberOfTitle: number) => void;

    closingMonth: string;
    setClosingMonth: (closingMonth: string) => void;

    closing: string;
    setClosing: (closing: string) => void;

    numberOfEmployees: number;
    setNumberOfEmployees: (numberOfEmployees: number) => void;

    TvaRegime: string;
    setTvaRegime: (TvaRegime: string) => void;

    numberOfAnnuelInvoice: number;
    setNumberOfAnnuelInvoice: (numberOfAnnuelInvoice: number) => void;

    caHtAnnuel: number;
    setCaHtAnnuel: (caHtAnnuel: number) => void;

    numberOfSupplier: number;
    setNumberOfSupplier: (numberOfSupplier: number) => void;

    fileNumber: number;
    setFileNumber: (fileNumber: number) => void;

    dataFirstPage: ClientFileData1;
    setDataFirstPage: (dataFirstPage: ClientFileData1) => void;

    dataSecondPage: ClientFileData2;
    setDataSecondPage: (dataSecondPage: ClientFileData2) => void;

    ficheClient: any;
    setFicheClient: (ficheClient: any) => void;

    updateData: boolean;
    setUpdateData: (updateData: boolean) => void;
}

export const useClientFileStore = create<ClientFile>((set) => ({
    filesModel: [],
    setFilesModel: (filesModel) => set({ filesModel }),

    selectedFile: '',
    setSelectedFile: (selectedFile) => set({ selectedFile }),

    name: '',
    setName: (name) => set({ name }),

    clientSign: '',
    setClientSign: (clientSign) => set({ clientSign }),

    directing: '',
    setDirecting: (directing) => set({ directing }),

    titlePerson: {},
    setTitlePerson: (titlePerson) => set({ titlePerson }),

    selectedTitlePerson: '',
    setSelectedTitlePerson: (selectedTitlePerson) => set({ selectedTitlePerson }),

    role: '',
    setRole: (role) => set({ role }),

    adress: '',
    setAdress: (adress) => set({ adress }),

    cp: '',
    setCp: (cp) => set({ cp }),

    city: '',
    setCity: (city) => set({ city }),

    country: '',
    setCountry: (country) => set({ country }),

    standarPhone: '',
    setStandarPhone: (standarPhone) => set({ standarPhone }),

    directPhone: '',
    setDirectPhone: (directPhone) => set({ directPhone }),

    mobilePhone: '',
    setMobilePhone: (mobilePhone) => set({ mobilePhone }),

    persoPhone: '',
    setPersoPhone: (persoPhone) => set({ persoPhone }),

    fax: '',
    setFax: (fax) => set({ fax }),

    email: '',
    setEmail: (email) => set({ email }),

    /* Page 2 */

    legalForm: [],
    setLegalForm: (legalForm) => set({ legalForm }),

    selectedLegalForm: '',
    setSelectedLegalForm: (selectedLegalForm) => set({ selectedLegalForm }),

    commercialRegister: '',
    setCommercialRegister: (commercialRegister) => set({ commercialRegister }),

    APECode: '',
    setAPECode: (APECode) => set({ APECode }),

    RcsNumber: '',
    setRcsNumber: (RcsNumber) => set({ RcsNumber }),

    siretNumber: '',
    setSiretNumber: (siretNumber) => set({ siretNumber }),

    categoricalRevenue: '',
    setCategoricalRevenue: (categoricalRevenue) => set({ categoricalRevenue }),

    taxSystem: '',
    setTaxSystem: (taxSystem) => set({ taxSystem }),

    coreBusiness: '',
    setCoreBusiness: (coreBusiness) => set({ coreBusiness }),

    capital: 0,
    setCapital: (capital) => set({ capital }),

    numberOfTitle: 0,
    setNumberOfTitle: (numberOfTitle) => set({ numberOfTitle }),

    closingMonth: '',
    setClosingMonth: (closingMonth) => set({ closingMonth }),

    closing: '',
    setClosing: (closing) => set({ closing }),

    numberOfEmployees: 0,
    setNumberOfEmployees: (numberOfEmployees) => set({ numberOfEmployees }),

    TvaRegime: '',
    setTvaRegime: (TvaRegime) => set({ TvaRegime }),

    numberOfAnnuelInvoice: 0,
    setNumberOfAnnuelInvoice: (numberOfAnnuelInvoice) => set({ numberOfAnnuelInvoice }),

    caHtAnnuel: 0,
    setCaHtAnnuel: (caHtAnnuel) => set({ caHtAnnuel }),

    numberOfSupplier: 0,
    setNumberOfSupplier: (numberOfSupplier) => set({ numberOfSupplier }),

    fileNumber: 0,
    setFileNumber: (fileNumber) => set({ fileNumber }),

    dataFirstPage: {
      "Nom / Dénomination sociale": "",
      "PM Enseigne": "",
      "Nom Dirigeant": "",
      "Fonction dirigeant (Libellé)": "", 
      "Adresse principale (Rue)": "",
      "Adresse principale (CP)": "",
      "Adresse principale (Ville)": "",
      "Adresse principale (Pays)": "",
      "Téléphone standard": "",
      "Appellation courrier": "",
      "Téléphone direct": "",
      "Téléphone Portable": "",
      "Téléphone personnel": "",
      Télécopie: "",
      Email: "",
      DOS_CODE: 0,
      PDOS_ID: 0,
    },
    setDataFirstPage: (dataFirstPage) => set({ dataFirstPage }),

    dataSecondPage: {
      /* commercialRegister: commercialRegister, */
      "PM SIRET Numéro": "",
      "PM Revenus catégoriels": "",
      "PM Régime Fiscal": "",
      "Reprise Clôture": "",
      "PM Forme juridique (Libellé)": "",
      "PM Code APE": "",
      "PM RCS Numéro": "",
      "PM Activité": "",
      "PM Capital en Euros": 0,
      "PM Nombre de titres": 0,
      "PM Mois de fin d'exercice social (Libellé)": "",
      "PM Nbre Salariés": 0,
      "Régime TVA (Libellé)": "",
      "PM Nbre de factures Clients annuel": 0,
      "PM CA HT annuel": 0,
      "PM Nbre de Factures Fournisseurs Annuel": 0,
      "PM Lieu d'inscription RCS": "",
    },
    setDataSecondPage: (dataSecondPage) => set({ dataSecondPage }),

    ficheClient: {},
    setFicheClient: (ficheClient) => set({ ficheClient }),

    updateData: false,
    setUpdateData: (updateData) => set({ updateData }),

}));