import { create } from 'zustand';

type TabsFSStore = {
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;

    productionTypes : string[];
    setProductionTypes: (productionTypes: string[]) => void;
  
    productionTypeSelected: string;
    setProductionTypeSelected: (productionTypeSelected: string) => void;

    billingTypes : string[];
    setBillingTypes: (billingTypes: string[]) => void;

    billingTypeSelected: string;
    setBillingTypeSelected: (billingTypeSelected: string) => void;

    cashInFlowTypes : string[];
    setCashInFlowTypes: (cashInFlowTypes: string[]) => void;

    cashInFlowTypeSelected: string;
    setCashInFlowTypeSelected: (billingTypeSelected: string) => void;

    workloadTypes : string[];
    setWorkloadTypes: (workloadTypes: string[]) => void;

    workloadTypeSelected: string;
    setWorkloadTypeSelected: (workloadTypeSelected: string) => void;

    documentUrl: string;
    setDocumentUrl: (documentUrl: string) => void;

    count: number;
    setCount: (count: number) => void;
    increment: () => void;
};

export const useTabsFSStore = create<TabsFSStore>((set) => ({
    choiceTab: 'Production',
    setChoiceTab: (choiceTab) => set({ choiceTab }),

    productionTypes: [
        "Productivité",
        "Budgets comparés",
        /* "Productivité : détails d'un client",
        "Productivité : liste des clients",
        "Suivi d'activite par collaborateur",
        "Feuille des temps collab/client",
        "Comparatif activité des collabs",
        */
    ],
    setProductionTypes: (productionTypes: string[]) => set({ productionTypes }),

    productionTypeSelected: "Productivité",
    setProductionTypeSelected: (productionTypeSelected: string) =>
        set({ productionTypeSelected }),

    billingTypes: [
        /* "Journal de facturation client", */
        "Répartition du chiffre d’affaire", 
        "Statistiques par activité", 
        /* "Répartion du chiffre d’affaire par dossier" */
    ],
    setBillingTypes: (billingTypes: string[]) => set({ billingTypes }),

    billingTypeSelected: "Répartition du chiffre d’affaire",
    setBillingTypeSelected: (billingTypeSelected: string) =>
        set({ billingTypeSelected }),

    cashInFlowTypes: [
        "Journal des encaissements"
    ],
    setCashInFlowTypes: (cashInFlowTypes: string[]) => set({ cashInFlowTypes }),
    
    cashInFlowTypeSelected: "Journal des encaissements",
    setCashInFlowTypeSelected: (cashInFlowTypeSelected: string) =>
        set({ cashInFlowTypeSelected }),

    workloadTypes: [
        "Plan de charge par collab",
        "Plan de charge par client",
        "Plan de charge synthétique",
    ],
    setWorkloadTypes: (workloadTypes: string[]) => set({ workloadTypes }),

    workloadTypeSelected: "Plan de charge par collab",
    setWorkloadTypeSelected: (workloadTypeSelected: string) =>
        set({ workloadTypeSelected }),

    documentUrl: "",
    setDocumentUrl: (documentUrl: string) => set({ documentUrl }),

    count: 0,
    setCount: (count: number) => set({ count }),
    increment: () => set(state => ({ count: state.count + 1 })),
}));

