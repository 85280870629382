import alertMenu from "../../data/alertMenu";

/**
 * @component
 * @name AlertBar
 * @description
 * A component that renders a floating alert bar at the bottom of the screen.
 * The alert bar displays important notifications or messages to the user.
 *
 * @param {object[]} alertMenu - The list of alerts to display.
 * @author Iris Succi
 * @date 2023
 */
const AlertBar = () => {
  return (
    <div className="fixed 2xl:bottom-10 w-screen h-28 flex justify-center items-center ">
      <div className=" w-11/12 flex justify-between items-center ">
        {alertMenu.map((item) => (
          <button
            className="flex border-[1px] bg-bc-orange w-full h-24 rounded-sm mr-4 justify-between items-center shadow-md"
            key={item.logo}
          >
            <div className="w-20 h-24 flex justify-center items-center">
              <div className="bg-white rounded-full border-[1px] border-bc-rose h-14 w-14 flex justify-center items-center ">
                <img src={item.logo} alt={item.title} className="w-8 h-8" />
              </div>
            </div>
            <div className="flex w-full justify-start items-center">
              <p className="text-white font-bold ml-2 text-sm 2xl:text-xl">
                {item.title}
              </p>
            </div>

            <div className="relative flex justify-center items-center">
              <div className="w-4 h-4 rounded-full bg-slate-200 absolute right-5 z-10"></div>
              <div className="w-6 h-6 rounded-full bg-bc-rose absolute right-1 z-0"></div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AlertBar;
