import axios from "axios";
import { axiosWithToken } from "../axiosService";
/**
 * @date 2024
 * @author Succi Iris
 * @description Call API to get costs
 */
export const getCosts = async () => {
    try {
        const response = await axiosWithToken().get(`api/cost`);
        if (response.status === 204) {
            throw new Error("Aucune donnée trouvée pour cette recherche");
        }
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getFolders = () => {
    try{
        const response = axiosWithToken().get(`api/cost/folders`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getClientsByDosRef = (DOS_REF: number) => {
    try{
        const response = axiosWithToken().get(`api/cost/customers?DOS_REF=${DOS_REF}`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getMissionsByDosRef = (DOS_REF: number) => {
    try{
        const response = axiosWithToken().get(`api/cost/missions?DOS_REF=${DOS_REF}`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getOriginsByMisRef = (MIS_REF: number, UTI_CODE: string, FIC_REF: number ) => {
    try{
        const response = axiosWithToken().get(`api/cost/origins?MIS_REF=${MIS_REF}&UTI_CODE=${UTI_CODE}&FIC_REF=${FIC_REF}`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const postCost = (data: any) => {
    try{
        const response = axiosWithToken().post(`api/cost`, data)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getCost = (id: number) => {
    try {
        const response = axiosWithToken().get(`api/cost/${id}`);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const deleteCost = (ids : number[]) => {
    try {
        const response = axiosWithToken().delete(`api/cost?ANA_REFs=${ids}`)
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const updateCost = (id: number, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/cost/${id}`, data);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
} 

export const validateCost = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/cost/validate`, data);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
};