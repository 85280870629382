import { useState } from "react";
import { useCreateInvoiceStore } from "../store/Invoice/createInvoice";

// Types
type DataItem = {
  ANA_REF: number;
};

/**
 * @hook
 * @name useSelectAll
 * @author Iris Succi
 * @date 2023
 * A custom React hook for managing the selection of items in a list or table.
 *
 * This hook provides functionality for a "select all" checkbox, allowing the user to select or deselect
 * all items in a dataset at once. It also manages individual item selections and keeps track of all selected item IDs.
 *
 * The hook is designed to work with a dataset where each item has a unique identifier (ANA_REF in this case).
 *
 * @param {DataItem[]} data - The array of data items, each item is expected to have an 'ANA_REF' property.
 *
 * @returns {Object} An object containing:
 *  - selectAll: A boolean state indicating if the "select all" option is active.
 *  - selectedIdsInvoices: An array of currently selected item IDs.
 *  - handleSelectAllChange: A function to toggle the "select all" state and update selected items accordingly.
 *  - handleCheckboxChange: A function to handle individual item selection changes.
 *
 * Usage:
 * const { selectAll, selectedIdsInvoices, handleSelectAllChange, handleCheckboxChange } = useSelectAll(data);
 *
 * Attach `handleSelectAllChange` to the "select all" checkbox's onChange handler, and `handleCheckboxChange` to each individual item's checkbox.
 */

export const useSelectAll = (data: DataItem[]) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { selectedIdsInvoices, setSelectedIdsInvoices } =
    useCreateInvoiceStore();

  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);

    if (!selectAll) {
      setSelectedIdsInvoices(
        data
          .map((item) => item.ANA_REF)
          .filter((item): item is number => typeof item === "number")
      );
    } else {
      setSelectedIdsInvoices([]);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
      setSelectAll(false);
    }
  };

  return {
    selectAll,
    selectedIdsInvoices,
    handleSelectAllChange,
    handleCheckboxChange,
  };
};
