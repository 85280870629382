import { create } from "zustand";

interface MyState {
  suppliers: any[];
  setSuppliers: (suppliers: any[]) => void;

  selectedSupplier: number;
  setSelectedSupplier: (supplier: number) => void;

  customers: any[];
  setCustomers: (customers: any[]) => void;

  selectedCustomer: number;
  setSelectedCustomer: (customer: number) => void;

  missions: any[];
  setMissions: (missions: any[]) => void;

  selectedMissionDE: number;
  setSelectedMissionDE: (missionDE: number) => void;

  origins: any[];
  setOrigins: (origins: any[]) => void;

  selectedOriginDE: string;
  setSelectedOriginDE: (origin: string) => void;

  libelles: any[];
  setLibelles: (libelles: any[]) => void;

  selectedLibelleDE: string;
  setSelectedLibelleDE: (libelle: string) => void;

  actors: any[];
  setActors: (actors: any[]) => void;

  selectedActorDE: string;
  setSelectedActorDE: (actor: string) => void;

  commentDE: string;
  setCommentDE: (commentDE: string) => void;

  subscriptions: any[];
  setSubscriptions: (subscriptions: any[]) => void;

  selectedSubscriptionDE: number;
  setSelectedSubscriptionDE: (subscription: number) => void;

  prices: any[];
  setPrices: (prices: any[]) => void;

  PFIC_ID: number;
  setPFIC_ID: (PFIC_ID: number) => void;

  FIC_CLE: number;
  setFIC_CLE: (FIC_CLE: number) => void;

  piece: string;
  setPiece: (piece: string) => void;

  libelleCpt: string;
  setLibelleCpt: (libelleCpt: string) => void;

  resetDeStore: () => void;
}

export const useDEStore = create<MyState>((set) => ({
  suppliers: [],
  setSuppliers: (suppliers) => set({ suppliers }),

  selectedSupplier: 0,
  setSelectedSupplier: (selectedSupplier) => set({ selectedSupplier }),

  customers: [],
  setCustomers: (customers) => set({ customers }),

  selectedCustomer: 0,
  setSelectedCustomer: (selectedCustomer) => set({ selectedCustomer }),

  missions: [],
  setMissions: (missions) => set({ missions }),

  selectedMissionDE: 0,
  setSelectedMissionDE: (selectedMissionDE) => set({ selectedMissionDE }),

  origins: [],
  setOrigins: (origins) => set({ origins }),

  selectedOriginDE: "",
  setSelectedOriginDE: (origin) => set({ selectedOriginDE: origin }),

  libelles: [],
  setLibelles: (libelles) => set({ libelles }),

  selectedLibelleDE: "",
  setSelectedLibelleDE: (libelle) => set({ selectedLibelleDE: libelle }),

  actors: [],
  setActors: (actors) => set({ actors }),

  selectedActorDE: "",
  setSelectedActorDE: (actor) => set({ selectedActorDE: actor }),

  commentDE: "",
  setCommentDE: (commentDE) => set({ commentDE: commentDE }),

  subscriptions: [],
  setSubscriptions: (subscriptions) => set({ subscriptions }),

  selectedSubscriptionDE: 0,
  setSelectedSubscriptionDE: (selectedSubscriptionDE) =>
    set({ selectedSubscriptionDE }),

  prices: [],
  setPrices: (prices) => set({ prices }),

  PFIC_ID: 0,
  setPFIC_ID: (PFIC_ID) => set({ PFIC_ID }),

  FIC_CLE: 0,
  setFIC_CLE: (FIC_CLE) => set({ FIC_CLE }),

  piece: "",
  setPiece: (piece) => set({ piece }),

  libelleCpt: "",
  setLibelleCpt: (libelleCpt) => set({ libelleCpt }),

  resetDeStore: () =>
    set({
      suppliers: [],
      selectedSupplier: 0,
      customers: [],
      selectedCustomer: 0,
      missions: [],
      selectedMissionDE: 0,
      origins: [],
      selectedOriginDE: "",
      libelles: [],
      selectedLibelleDE: "",
      actors: [],
      selectedActorDE: "",
      commentDE: "",
      subscriptions: [],
      selectedSubscriptionDE: 0,
      prices: [],
      PFIC_ID: 0,
      FIC_CLE: 0,
      piece: "",
      libelleCpt: "",
    }),
}));
