import { create } from "zustand";

type MyState = {
    selectedItems: string[];
    setSelectedItems: (items: string[]) => void;

    currentItems: any[];
    setCurrentItems: (items: any[]) => void;

    documentUrl: any;
    setDocumentUrl: (documentUrl: any) => void;

    levelRelaunchs: any[];
    setLevelRelaunchs: (levelRelaunchs: any) => void;

    selectedRelaunch: number;
    setSelectedRelaunch: (relaunch: number) => void;
}   

export const useInvoiceStore = create<MyState>((set) => ({
    selectedItems: [ "Dossier", "Mission"," Origine"," Libellé", "Acteur", "Date facturation", "N Facture", "HT", "TTC"] ,
    setSelectedItems: (items) => set({ selectedItems: items }),

    currentItems: [],
    setCurrentItems: (items) => set({ currentItems: items }),

    documentUrl: "",
    setDocumentUrl: (documentUrl) => set({ documentUrl: documentUrl }),

    levelRelaunchs: [],
    setLevelRelaunchs: (levelRelaunchs) => set({ levelRelaunchs: levelRelaunchs }),

    selectedRelaunch: 0,
    setSelectedRelaunch: (relaunch) => set({ selectedRelaunch: relaunch }),
}));