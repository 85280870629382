import { toast } from "react-hot-toast"

export const errorConnection = () => {
    toast("Une erreure est survenue. Veuillez réessayer plus tard.", {
        icon: "🚫",
        duration: 2000,
    })
}

export const nodatamatches = () => {
    toast("Aucune donnée ne correspond à votre recherche.", {
        icon: "🔍",
        duration: 3000,
    })
}

export const errorFetch = () => {
    toast("Une erreure est survenue. Veuillez réessayer plus tard.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorSendTwoCommentsOnSameDay = () => {
    toast("Vous avez déjà ajouté un commentaire pour cette date.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const validateBeforeAddNewLine = () => {
    toast("Veuillez valider votre temps avant d'en ajouter un nouveau.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorSendNewTime = () => {
    toast("Une erreure est survenue. Veuillez réessayer ultérieurement.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorDeleteTimeIfIsValidate = () => {
    toast("Vous ne pouvez pas supprimer un temps validé.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const successUpdateComment = () => {
    toast("Commentaire(s) mis à jour.", {
        icon: "✅",
        duration: 3000,
    })
}

export const errorDeleteValidatedTime = () => {
    toast("Au moins un des temps est validé. Impossible de supprimer.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorUpdateComment = () => {
    toast("Vous ne pouvez pas modifier un commentaire d'un temps validé.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorTooMuchTime = () => {
    toast("Vous ne pouvez pas ajouter plus de 24h de temps dans une journée.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorSameData = () => {
    toast("Vous avez déjà édité des temps avec cette configuration. Renseignez le dans la ligne correspondante", {
        icon: "🚫",
        duration: 5000,
    })
}

export const errorRedirectConnection = () => {
    toast("Vous avez été déconnecté, merci de rentrer votre identifiant et votre mot de passe.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorEntry = () => {
    toast("Votre saisie est incorrect. Merci de verifiez que tous les champs soient correctement renseignés.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const successAcompte = () => {
    toast("Acompte créé avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const successUpdateData = () => {
    toast("Données mises à jour avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const successProforma = () => {
    toast("Proforma créée avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const successFee = () => {
    toast("Element facturable créée avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const errorSelectFee = () => {
    toast("Veuillez sélectionner un élément facturable.", {
        icon: "🚫",
        duration: 3000,
    })  
}

export const successCredit = () => {
    toast("Avoir créé avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const errorSelectTime = () => {
    toast("Veuillez sélectionner un temps.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const successCashInFlow = () => {
    toast("Encaissement créé avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const successValidateAndPoint = () => {
    toast("Facture validée et pointée avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const successSendMail = () => {
    toast("Mail envoyé avec succès.", {
        icon: "✅",
        duration: 3000,
    })
}

export const errorRigth = () => {
    toast("Vous n'avez pas les droits pour effectuer cette action.", {
        icon: "🚫",
        duration: 3000,
    })
}

export const errorConfig = () => {
    toast("Une erreur est survenue lors de la configuration.", {
        icon: "🚫",
        duration: 3000,
    })
}