import { create } from "zustand";

export type DataAcompteState = {
  DOS_REF: number | null,
  MIS_REF: number | null,
  AOR_REF: number | null,
  ANA_EMTHT: number | null, 
  ANA_EMTTVA: number | null,
  ANA_EMTTTC: number | null,
  FIC_REF: number | null,
  ABA_REF: any | null,
  ANA_MODE_REGLEMENT: number | null,
  TVA_CLE: number | null,
  ANA_DATEREALISATION: Date | null,
  ATR_PIECE: string | null,
  ATR_BANQUE_EMISSION: string | null,
  ATR_DEBITEUR: string | null,
  ANA_EMTNS: number | null,
}

interface MyState {
    clientsFile: any[],
    setClientsFile: (clients: any[]) => void,
    
    missions: any[],
    setMissions: (missions: any[]) => void,

    clients: any[],
    setClients: (clients: any[]) => void,
    
    selectedFile: number,
    setSelectedFile: (file: number) => void,
    
    selectedMission: number,
    setSelectedMission: (mission: number) => void,

    selectedClient: number,
    setSelectedClient: (client: number) => void,

    startDate: Date,
    setStartDate: (date: Date) => void,

    ht: any,
    setHt: (ht: any) => void,
    
    tva: any,
    setTva: (tva: any) => void,
  
    tvaRate: any[],
    setTvaRate: (tvaRate: any[]) => void,
  
    ttc: any,
    setTtc: (ttc: any) => void,

    ns: number,
    setNs: (ns: number) => void,
  
    selectedTvaRate: number,
    setSelectedTvaRate: (tva: number) => void,

    banqueEncaissement: any[],
    setBanqueEncaissement: (banqueEncaissement: any[]) => void,

    selectedBanqueEncaissement: number,
    setSelectedBanqueEncaissement: (banqueEncaissement: number) => void,
  
    origins: any[],
    setOrigins: (origins: any[]) => void,

    selectedOrigin: number,
    setSelectedOrigin: (origin: number) => void,

    paymentMethod: any[],
    setPaymentMethod: (paymentMethod: any[]) => void,

    selectedPaymentMethod: number,
    setSelectedPaymentMethod: (paymentMethod: number) => void,

    emissionBanque: string,
    setEmissionBanque: (emissionBanque: string) => void,

    libellecpt: string,
    setLibellecpt: (libellecpt: string) => void,

    piece: string,
    setPiece: (piece: string) => void,

    dataAcompte: DataAcompteState,
    setDataAcompte: (property: keyof DataAcompteState, value: any) => void;

    // For update 
    modeAcompte : string,
    setModeAcompte: (modeAcompte: string) => void,

    prepaymentData: DataAcompteState,
    setPrepaymentData: (data: DataAcompteState | ((prevState: DataAcompteState) => DataAcompteState)) => void;
  }
  
  export const createAcompteStore = create<MyState>((set) => ({
    clientsFile: [],
    setClientsFile: (clients) => set({ clientsFile: clients }),

    missions: [],
    setMissions: (missions) => set({ missions: missions }),

    clients: [],
    setClients: (clients) => set({ clients: clients }),

    selectedFile: 0,
    setSelectedFile: (file) => set({ selectedFile: file }),

    selectedMission: 0,
    setSelectedMission: (mission) => set({ selectedMission: mission }),

    selectedClient: 0,
    setSelectedClient: (client) => set({ selectedClient: client }),

    banqueEncaissement: [],
    setBanqueEncaissement: (banqueEncaissement) => set({ banqueEncaissement: banqueEncaissement }),

    selectedBanqueEncaissement: 0,
    setSelectedBanqueEncaissement: (banqueEncaissement) => set({ selectedBanqueEncaissement: banqueEncaissement }),

    startDate: new Date(),
    setStartDate: (date) => set({ startDate: date }),

    ht: 0,
    setHt: (ht) => set({ ht: ht }),

    tva: 0,
    setTva: (tva) => set({ tva: tva }),

    tvaRate: [],
    setTvaRate: (tvaRate) => set({ tvaRate: tvaRate }),

    selectedTvaRate: 2,
    setSelectedTvaRate: (tva: number) => set({ selectedTvaRate: tva }),

    ttc: 0,
    setTtc: (ttc) => set({ ttc: ttc }),

    ns: 0,
    setNs: (ns) => set({ ns: ns }),

    origins: [],
    setOrigins: (origins) => set({ origins: origins }),

    selectedOrigin: 0,
    setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),

    paymentMethod: [],
    setPaymentMethod: (paymentMethod) => set({ paymentMethod: paymentMethod }),

    selectedPaymentMethod: 0,
    setSelectedPaymentMethod: (paymentMethod) => set({ selectedPaymentMethod: paymentMethod }),

    emissionBanque: "",
    setEmissionBanque: (emissionBanque) => set({ emissionBanque: emissionBanque }),

    libellecpt: "",
    setLibellecpt: (libellecpt) => set({ libellecpt: libellecpt }),

    piece: "",
    setPiece: (piece) => set({ piece: piece }),

    dataAcompte: {
      DOS_REF: 0,
      MIS_REF: 0,
      AOR_REF: 0,
      ANA_EMTHT: 0, 
      ANA_EMTTVA: 0,
      ABA_REF: 0,
      ANA_MODE_REGLEMENT: 0,
      ANA_EMTTTC: 0,
      FIC_REF: 0,
      ANA_DATEREALISATION: new Date(),
      TVA_CLE: 2,
      ATR_PIECE: "",
      ATR_BANQUE_EMISSION: "",
      ATR_DEBITEUR: "",
      ANA_EMTNS: 0,
    },
    setDataAcompte: (property, value) => set((state) => ({
      dataAcompte: {
        ...state.dataAcompte,
        [property]: value,
      },
    })),

    modeAcompte: "create",
    setModeAcompte: (modeAcompte) => set({ modeAcompte: modeAcompte }),

    prepaymentData:  {
      DOS_REF: 0,
      MIS_REF: 0,
      AOR_REF: 0,
      ANA_EMTHT: 0, 
      ANA_EMTTVA: 0,
      ABA_REF: 0,
      ANA_MODE_REGLEMENT: 0,
      ANA_EMTTTC: 0,
      FIC_REF: 0,
      ANA_DATEREALISATION: new Date(),
      TVA_CLE: 2,
      ATR_PIECE: "",
      ATR_BANQUE_EMISSION: "",
      ATR_DEBITEUR: "",
      ANA_EMTNS: 0,
    },
    setPrepaymentData: (data) => set((currentState) => {
      if (typeof data === "function") {
        const newData = data(currentState.prepaymentData);
        return { prepaymentData: newData };
      } else {
        return { prepaymentData: data };
      }
    }),
    
  }));