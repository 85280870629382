import { createContext, useContext, useState } from "react";
import { RightTimeContextValue } from "../interface/context";

const initialData = JSON.parse(localStorage.getItem("rightsTime") || "{}");

const RightTimeContext = createContext<RightTimeContextValue | null>(null);

export const RightTimeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [editLimit, setEditLimit] = useState<number>(
    initialData["Edition limitée aux temps de l'acteur en cours"] || 0
  );
  const [visuLimit, setVisuLimit] = useState<number>(
    initialData["Visualisation limitée aux temps de l'acteur en cours"] || 0
  );
  const [timeEntry, setTimeEntry] = useState<number>(
    initialData["Saisie temps"] || 0
  );
  const [deleteTime, setDeleteTime] = useState<number>(
    initialData["Suppression des temps interdite"] || 0
  );
  const [validatedTime, setValidatedTime] = useState<number>(
    initialData["Validation temps"] || 0
  );

  return (
    <RightTimeContext.Provider
      value={{
        editLimit,
        setEditLimit,
        visuLimit,
        setVisuLimit,
        timeEntry,
        setTimeEntry,
        deleteTime,
        setDeleteTime,
        validatedTime,
        setValidatedTime,
      }}
    >
      {children}
    </RightTimeContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useRightTimeContext = () => {
  const context = useContext(RightTimeContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
