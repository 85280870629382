import { create } from 'zustand';

interface MyState {
    missions: any[],
    setMissions: (missions: any[]) => void,

    selectedMission: number,
    setSelectedMission: (mission: number) => void,
    
    origins: any[],
    setOrigins: (origins: any[]) => void,

    selectedOrigin: string,
    setSelectedOrigin: (origin: string) => void,

    libelles: any[],
    setLibelles: (libelles: any[]) => void,

    selectedLibelle: string,
    setSelectedLibelle: (libelle: string) => void,

    subscriptions: any[],
    setSubscriptions: (subscriptions: any[]) => void,

    selectedSubscription: number,
    setSelectedSubscription: (subscription: number) => void,

    comment: string,
    setComment: (comment: string) => void,

    actors: any[],
    setActors: (actors: any[]) => void,

    selectedActor: string,
    setSelectedActor: (actor: string) => void,

    provisionRequest: any,
    setProvisionRequest: (provisionRequest: any) => void,

    modeFee: string,
    setModeFee: (modeFee: string) => void,

    sendFee: boolean;
    setSendFee: (send: boolean) => void;

    prices: any[];
    setPrices: (prices: any[]) => void;

    resetFee : () => void,
}

export const useCreateFee = create<MyState>((set) => ({
    missions: [],
    setMissions: (missions) => set({ missions }),

    selectedMission: 0,
    setSelectedMission: (mission) => set({ selectedMission: mission }),

    origins: [],
    setOrigins: (origins) => set({ origins }),

    selectedOrigin: "",
    setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),

    libelles: [],
    setLibelles: (libelles) => set({ libelles }),

    selectedLibelle: "",
    setSelectedLibelle: (libelle) => set({ selectedLibelle: libelle }),

    subscriptions: [],
    setSubscriptions: (subscriptions) => set({ subscriptions }),

    selectedSubscription: 0,
    setSelectedSubscription: (subscription) => set({ selectedSubscription: subscription }),

    comment: "",
    setComment: (comment) => set({ comment: comment }),

    actors: [],
    setActors: (actors) => set({ actors }),

    selectedActor: "",
    setSelectedActor: (actor) => set({ selectedActor: actor }),

    provisionRequest: false,
    setProvisionRequest: (provisionRequest) => set({ provisionRequest }),

    modeFee: "",
    setModeFee: (modeFee) => set({ modeFee }),

    prices: [],
    setPrices: (prices) => set({ prices }),
    
    sendFee: false,
    setSendFee: (send) => set({ sendFee: send }),
    
    resetFee: () => set({
        missions: [],
        selectedMission: 0,
        origins: [],
        selectedOrigin: "",
        libelles: [],
        selectedLibelle: "",
        subscriptions: [],
        selectedSubscription: 0,
        comment: "",
        actors: [],
        selectedActor: "",
        provisionRequest: false,
    }),
}));