import { useState } from "react";
import { ModalHook } from "../interface/hooks";

/**
 * @hook
 * @name useModal
 * @author Iris Succi
 * @date 2023
 * A custom React hook for managing modal dialogues.
 *
 * This hook provides functionality to open and close a modal, as well as to set its content.
 * It manages the state of the modal's content and its open/close status.
 *
 * Returns an object containing the current modal content, the modal's open status, and functions to open and close the modal.
 *
 * @returns {ModalHook} An object with the following properties:
 *  - modalContent: The current content of the modal, stored as JSX.Element or null.
 *  - isModalOpen: A boolean indicating whether the modal is currently open.
 *  - openModal: A function that accepts a JSX.Element to set as the modal's content and opens the modal.
 *  - closeModal: A function to close the modal and clear its content.
 *
 * Usage:
 * const { modalContent, isModalOpen, openModal, closeModal } = useModal();
 *
 * To open a modal, call `openModal` with the desired JSX content. To close the modal, call `closeModal`.
 */

const useModal = (): ModalHook => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (content: JSX.Element): void => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  return { modalContent, isModalOpen, openModal, closeModal };
};

export default useModal;
