import {create} from 'zustand';

export type DataCashInFlowState = {
  DOS_REF: number | null,
  FIC_REF: number | null,
  ABA_REF: any | null,
  ANA_MODE_REGLEMENT: number | null,
  ATR_EMTTTC: number | null,
  ATR_DATE_ENCAISSEMENT: Date | null,
  ATR_PIECE: string | null,
  ATR_BANQUE_EMISSION: string | null,
  LIBELLE_CPT: string | null,
  ATR_REF?: number | null,
}

interface MyState {
  clientsFile: any[];
  setClientsFile: (clientsFile: any[]) => void;

  selectedFile: number;
  setSelectedFile: (file: number) => void;

  modeCashInFlow: string;
  setModeCashInFlow: (modeCashInFlow: string) => void;

  clients: any[];
  setClients: (clients: any[]) => void;

  selectedClient: number;
  setSelectedClient: (client: number) => void;

  startDate: Date,
  setStartDate: (date: Date) => void,

  dataCashInFlow: DataCashInFlowState,
  setDataCashInFlow: (property: keyof DataCashInFlowState, value: any) => void;

  banqueEncaissement: any[];
  setBanqueEncaissement: (banqueEncaissement: any[]) => void;

  selectedBanqueEncaissement: number;
  setSelectedBanqueEncaissement: (banqueEncaissement: number) => void;

  paymentMethod: any[],
  setPaymentMethod: (paymentMethod: any[]) => void,

  selectedPaymentMethod: number,
  setSelectedPaymentMethod: (paymentMethod: number) => void,

  montant: number,
  setMontant: (montant: number) => void,

  emissionBanque: string,
  setEmissionBanque: (emissionBanque: string) => void,

  piece: string,
  setPiece: (piece: string) => void,

  libelleCpt: string,
  setLibelleCpt: (libelleCpt: string) => void,

  cashInFlowData: DataCashInFlowState,
  setCashInFlowData: (data: DataCashInFlowState | ((prevState: DataCashInFlowState) => DataCashInFlowState)) => void,
  
  invoicesToAllocate: any[],
  setInvoicesToAllocate: (invoicesToAllocate: any[]) => void,

  selectedInvoicesToAllocate: any[],
  setSelectedInvoicesToAllocate: (selectedInvoicesToAllocate: any[]) => void,

  montantRestant: number,
  setMontantRestant: (montantRestant: number) => void,

  invoiceAlreadyDue: any[],
  setInvoiceAlreadyDue: (invoiceAlreadyDue: any[]) => void,

  invoicesAllocated: any[],
  setInvoicesAllocated: (invoicesAllocated: any[]) => void,
}
  
export const useCreateCashInFlowStore = create<MyState>((set) => ({
  clientsFile: [],
  setClientsFile: (clientsFile) => set({ clientsFile }),

  selectedFile: 0,
  setSelectedFile: (file) => set({ selectedFile: file }),

  modeCashInFlow: 'create',
  setModeCashInFlow: (modeCashInFlow) => set({ modeCashInFlow }),

  clients: [],
  setClients: (clients) => set({ clients }),

  selectedClient: 0,
  setSelectedClient: (client) => set({ selectedClient: client }),

  startDate: new Date(),
  setStartDate: (date) => set({ startDate: date }),

  dataCashInFlow: {
    DOS_REF: 0,
    FIC_REF: 0,
    ABA_REF: 0,
    ANA_MODE_REGLEMENT: 0,
    ATR_EMTTTC: 0,
    ATR_DATE_ENCAISSEMENT: new Date(),
    ATR_PIECE: "",
    ATR_BANQUE_EMISSION: "",
    LIBELLE_CPT: "",
    ATR_REF: 0,
  },
  setDataCashInFlow: (property, value) => set((state) => ({
    dataCashInFlow: {
      ...state.dataCashInFlow,
      [property]: value,
    },
  })),

  banqueEncaissement: [],
  setBanqueEncaissement: (banqueEncaissement) => set({ banqueEncaissement: banqueEncaissement }),

  selectedBanqueEncaissement: 0,
  setSelectedBanqueEncaissement: (banqueEncaissement) => set({ selectedBanqueEncaissement: banqueEncaissement }),

  paymentMethod: [],
  setPaymentMethod: (paymentMethod) => set({ paymentMethod: paymentMethod }),

  selectedPaymentMethod: 0,
  setSelectedPaymentMethod: (paymentMethod) => set({ selectedPaymentMethod: paymentMethod }),

  montant: 0,
  setMontant: (montant) => set({ montant: montant }),

  emissionBanque: '',
  setEmissionBanque: (emissionBanque) => set({ emissionBanque: emissionBanque }),

  piece: '',
  setPiece: (piece) => set({ piece: piece }),

  libelleCpt: '',
  setLibelleCpt: (libelleCpt) => set({ libelleCpt: libelleCpt }),

  cashInFlowData: {
    DOS_REF: 0,
    FIC_REF: 0,
    ABA_REF: 0,
    ANA_MODE_REGLEMENT: 0,
    ATR_EMTTTC: 0,
    ATR_DATE_ENCAISSEMENT: new Date(),
    ATR_PIECE: "",
    ATR_BANQUE_EMISSION: "",
    LIBELLE_CPT: "",
    ATR_REF: 0,
  },
  setCashInFlowData:(data) => set((currentState) => {
    if (typeof data === "function") {
      const newData = data(currentState.cashInFlowData);
      return { cashInFlowData: newData };
    } else {
      return { cashInFlowData: data };
    }
  }),

  invoicesToAllocate: [],
  setInvoicesToAllocate: (invoicesToAllocate) => set({ invoicesToAllocate: invoicesToAllocate }),

  selectedInvoicesToAllocate: [],
  setSelectedInvoicesToAllocate: (selectedInvoicesToAllocate) => set({ selectedInvoicesToAllocate: selectedInvoicesToAllocate }),

  montantRestant: 0,
  setMontantRestant: (montantRestant) => set({ montantRestant: montantRestant }),

  invoiceAlreadyDue: [],
  setInvoiceAlreadyDue: (invoiceAlreadyDue) => set({ invoiceAlreadyDue: invoiceAlreadyDue }),

  invoicesAllocated: [],
  setInvoicesAllocated: (invoicesAllocated) => set({ invoicesAllocated: invoicesAllocated }),
}));
