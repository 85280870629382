import { useState } from "react";

interface TooltipState {
  content: string;
  position: { x: number; y: number };
}
/**
 * @hook
 * @name useTooltip
 * @author Iris Succi
 * @date 2023
 * A custom React hook for managing tooltip state and interactions.
 *
 * This hook provides a mechanism to display a tooltip with dynamic content at specific positions based on mouse events.
 * It tracks the content and position of the tooltip, updating these based on mouse enter and leave events.
 *
 * @returns {Array} An array containing:
 *  - TooltipState: An object with 'content' (string) and 'position' ({x: number, y: number}).
 *  - handleMouseEnter: A function that takes a string and a mouse event, sets the tooltip content and position.
 *  - handleMouseLeave: A function that resets the tooltip content and position.
 *
 * TooltipState type:
 * @typedef {Object} TooltipState
 * @property {string} content - The content to be displayed in the tooltip.
 * @property {{x: number, y: number}} position - The position of the tooltip, based on mouse coordinates.
 *
 * Usage:
 * const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();
 *
 * Attach `handleMouseEnter` to the onMouseEnter event of the target element, and `handleMouseLeave` to its onMouseLeave event.
 * The tooltipState object can then be used to render and position a tooltip component.
 */

const useTooltip = (): [
  TooltipState,
  (content: string, event: React.MouseEvent) => void,
  () => void
] => {
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (content: string, event: React.MouseEvent) => {
    setTooltipContent(content);
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setTooltipPosition({ x: 0, y: 0 });
  };

  return [
    { content: tooltipContent, position: tooltipPosition },
    handleMouseEnter,
    handleMouseLeave,
  ];
};

export default useTooltip;
