import { useTranslation } from "react-i18next";
import { useAddRowEditStore } from "../../../store/TimeManagement/addRowEdit";
import { getMonthName, getWeekNumber } from "../../../utils/functionService";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
/**
 * @component
 * @name AddLine
 * @author Succi Iris
 * @date 2023
 * @description
 * Le composant `AddLine` permet d'ajouter une ligne de temps.
 */
const AddLine = () => {
  //Translate
  const { t } = useTranslation();

  //Store
  const { addLine } = useAddRowEditStore();
  const { startDate } = useDateStore();

  return (
    <div className="flex mb-2">
      <button
        onClick={addLine}
        className="w-44 h-8 bg-bc-orange border-[1px] flex justify-center items-center px-4 mr-2 rounded text-white transition-colors duration-200  hover:bg-bc-lightOrange"
      >
        {t("Ajouter un temps")}
      </button>
      <div className="flex justify-center items-center ml-5">
        <p className=" text-slate-500 font-semibold text-lg">
          Semaine : {getWeekNumber(startDate)}
        </p>
        <p className="pl-5 text-slate-500 font-semibold text-lg">
          Mois : {getMonthName(startDate)}
        </p>
      </div>
    </div>
  );
};

export default AddLine;
