import { create } from 'zustand';

interface ClientFileState {
  clientsFile: any[];
  setClientsFile: (clientsFile: any) => void;

  selectedClientFile: any;
  setSelectedClientFile: (clientFile: any) => void;

  binders: any[];
  setBinders: (binders: any) => void;

  selectedBinder: any;
  setSelectedBinder: (binder: any) => void;

  collaborators: any[];
  setCollaborators: (collaborators: any) => void;

  selectedCollaborator: any;
  setSelectedCollaborator: (collaborator: any) => void;

  groupes: any[];
  setGroupes: (groupes: any) => void;

  selectedGroupe: any;
  setSelectedGroupe: (groupe: any) => void;
  
  years: any[];
  setYears: (years: any[]) => void;

  selectedYear: number;
  setSelectedYear: (year: number) => void;
}

// Création du store Zustand.
export const useClientFileStore = create<ClientFileState>((set) => ({
  clientsFile: [],
  setClientsFile: (clientsFile) => set({ clientsFile }),

  selectedClientFile: {},
  setSelectedClientFile: (clientFile) => set({ selectedClientFile: clientFile }),

  binders: [],
  setBinders: (binders) => set({ binders }),

  selectedBinder: {},
  setSelectedBinder: (binder) => set({ selectedBinder: binder }),

  collaborators: [],
  setCollaborators: (collaborators) => set({ collaborators }),

  selectedCollaborator: {},
  setSelectedCollaborator: (collaborator) => set({ selectedCollaborator: collaborator }),

  groupes: [],
  setGroupes: (groupes) => set({ groupes }),

  selectedGroupe: {},
  setSelectedGroupe: (groupe) => set({ selectedGroupe: groupe }),

  years: [2020, 2021, 2022, 2023, 2024],
  setYears: (years) => set({ years }),

  selectedYear: 0,
  setSelectedYear: (year) => set({ selectedYear: year }),
}));        