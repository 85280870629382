import { useCostStore } from "../../store/Cost/Cost";
import { useCreateFeesOrInvoices } from "../../store/Invoice/buttonsStore";
import { useLoadingStore } from "../../store/utils/loading";
import { deleteCost, validateCost } from "../../utils/Cost/Cost";

/**
 * @Component
 * @name ButtonsCost
 * @description
 * ButtonsCost est un composant qui permet d'afficher les boutons pour la gestion des frais. Ajouter, modifier, valider et supprimer.
 * @author Iris Succi
 * @date 2023
 */
const ButtonsCost = () => {
  const { setIsLoading } = useLoadingStore();
  const {
    selectedIdsCosts,
    setSelectedIdsCosts,
    setDisplayCreateCost,
    setModeCost,
    setSendCost,
    selectedCostsForValidate,
    setSelectedCostsForValidate,
  } = useCostStore();
  const { setCreateCost } = useCreateFeesOrInvoices();

  const handleDeleteCost = () => {
    deleteCost(selectedIdsCosts)
      .then(() => {
        setModeCost("create");
        setSelectedIdsCosts((_prev) => []);
        setSendCost((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const handleValidateCost = () => {
    setIsLoading(true);
    validateCost(selectedCostsForValidate)
      .then(() => {
        setIsLoading(false);
        setSelectedIdsCosts((_prev) => []);
        setSelectedCostsForValidate((_prev) => []);
        setSendCost((prev) => !prev);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="flex justify-center items-center">
      <button
        className="px-2 my-2 w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-5"
        onClick={() => {
          setDisplayCreateCost(true);
          setCreateCost(true);
          setModeCost("create");
        }}
      >
        Ajouter un frais
      </button>
      <button
        className={
          selectedIdsCosts.length === 1
            ? "w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer mr-5"
            : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-5"
        }
        onClick={() => {
          setCreateCost(true);
          setDisplayCreateCost(true);
          setModeCost("edit");
        }}
      >
        Modifier
      </button>
      <button
        className={
          selectedIdsCosts.length > 0
            ? "w-40 h-8 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md cursor-pointer mr-5"
            : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none mr-5"
        }
        onClick={() => {
          handleValidateCost();
        }}
      >
        Valider
      </button>
      <button
        className={
          selectedIdsCosts.length > 0
            ? "w-40 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500  hover:bg-rose-500  text-white shadow-md cursor-pointer"
            : "w-40 h-8 border-[1px] rounded-md border-btn-grey bg-btn-grey text-bc-lightGrey shadow-md pointer-events-none"
        }
        onClick={() => handleDeleteCost()}
      >
        Supprimer
      </button>
    </div>
  );
};

export default ButtonsCost;
