import { create } from "zustand"    

type createFeesOrInvoices = {
    createFees : boolean;
    setCreateFees : (createFees: boolean) => void;
    createInvoices : boolean;
    setCreateInvoices : (createInvoices: boolean) => void;
    createCredit : boolean;
    setCreateCredit : (createCredit: boolean) => void;
    createProvision : boolean;
    setCreateProvision : (createProvision: boolean) => void;
    createProformat : boolean;
    setCreateProformat : (createProformat: boolean) => void;
    createAcompte : boolean;
    setCreateAcompte : (createAcompte: boolean) => void;
    createCashInFlow : boolean;
    setCreateCashInFlow : (createCashInFlow: boolean) => void;
    visuPdf : boolean;
    setVisuPdf : (visuPdf: boolean) => void;
    poitOutAcompte : boolean;
    setPointOutAcompte : (poitOutAcompte: boolean) => void;
    createUnpaid: boolean;
    setCreateUnpaid: (createUnpaid: boolean) => void;
    createCost: boolean;
    setCreateCost: (createCost: boolean) => void;
    createSlip: boolean;
    setCreateSlip: (createSlip: boolean) => void;
    billableItem: string;
    setbillableItem: (billableItem: string) => void;
};

export const useCreateFeesOrInvoices = create<createFeesOrInvoices>((set) => ({
    createFees: false,
    setCreateFees: (createFees) => set({ createFees }),
    createInvoices: false,
    setCreateInvoices: (createInvoices) => set({ createInvoices }),
    createCredit: false,
    setCreateCredit: (createCredit) => set({ createCredit }),
    createProvision: false,
    setCreateProvision: (createProvision) => set({ createProvision}),
    createProformat: false,
    setCreateProformat: (createProformat) => set({ createProformat }),
    createAcompte: false,
    setCreateAcompte: (createAcompte) => set({ createAcompte }),
    createCashInFlow: false,
    setCreateCashInFlow: (createCashInFlow) => set({ createCashInFlow }),
    visuPdf: false,
    setVisuPdf: (visuPdf) => set({ visuPdf }),
    poitOutAcompte: false,
    setPointOutAcompte: (poitOutAcompte) => set({ poitOutAcompte }),
    createUnpaid: false,
    setCreateUnpaid: (createUnpaid) => set({ createUnpaid }),
    createCost: false,
    setCreateCost: (createCost) => set({ createCost }),
    createSlip: false,
    setCreateSlip: (createSlip) => set({ createSlip }),
    billableItem: "",
    setbillableItem: (billableItem) => set({ billableItem }),
}));