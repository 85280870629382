import { useEffect } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsCategories } from "../../../../utils/participants";
import ParticipantsTree from "./ParticipantsTree";

/**
 * @component
 * @name ExplorerParticipantsCategories
 * @description A component that displays the participants categories for a given dossier.
 * @author Iris Succi
 * @date 2023
 */
const ExplorerParticipantsCategories: React.FC<any> = ({
  closeModal,
  openModal,
}) => {
  const {
    PDOS_ID,
    participantsCategories,
    setParticipantsCategories,
    clientFileName,
  } = useExplorer();

  useEffect(() => {
    getParticipantsCategories(PDOS_ID)
      .then((res) => setParticipantsCategories(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex flex-col w-[98%] h-full overflow-y-scroll border-[1px] rounded-md mt-2 pt-2 bg-bc-lightGrey  items-center">
      {clientFileName !== "" ? clientFileName : null}
      <hr className="my-2 w-6/12 m-auto" />
      {participantsCategories?.map((category) => (
        <ParticipantsTree
          category={category}
          closeModal={closeModal}
          openModal={openModal}
        />
      ))}
    </div>
  );
};

export default ExplorerParticipantsCategories;
