import Explorer from "../components/Crm/treeFolder/clientfiles/Explorer";
import ExplorerParticipantsCategories from "../components/Crm/treeFolder/interlocutors/ExplorerParticipantsCategories";
import Tabs from "../components/Crm/buttons/Tabs";
import ClientFile from "../components/Crm/modales/ClientFile";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import useModal from "../hooks/useModal";
import { useExplorer } from "../store/Crm/Explorer";
import { useUtilsCrmStore } from "../store/Crm/utilsCrm";
import Analytic from "../components/Crm/modales/Analytic";
import { useEffect } from "react";
import { useSearchBarStore } from "../store/utils/searchBar";
import DocumentsList from "../components/Crm/modales/DocumentsList";
import Mission from "../components/Crm/modales/Mission";

/**
 * @page
 * @name Crm
 * @description
 * CRM page with the explorer, the tabs, the modal and the client file
 * @author Iris Succi
 * @date 2023
 */

const Crm = () => {
  //Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Store
  const { setSearch } = useSearchBarStore();

  // State
  const { ficRef, setFicRef, PDOS_ID, setPDOS_ID } = useExplorer();
  const { choiceTab, setChoiceTab } = useUtilsCrmStore();

  const location = window.location.pathname;

  useEffect(() => {
    setSearch("");
    setPDOS_ID(0);
    setChoiceTab("");
    setFicRef(0);
  }, [location]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      {isModalOpen ? modalContent : null}
      <Header closeModal={closeModal} openModal={openModal} />
      <div className="flex w-screen">
        <div className="h-auto">
          <Navbar />
        </div>
        <div className="flex w-full ml-10 h-[87vh] justify-start items-center">
          <div className="w-1/5 h-[98%] mt-8">
            {/* Explorer choice with choiceTab  */}
            {choiceTab === "Create" ||
            choiceTab === "Fiche client" ||
            choiceTab === "Analytique" ||
            choiceTab === "Documents" ||
            choiceTab === "Missions" ||
            choiceTab === "" ? (
              <Explorer />
            ) : null}
            {choiceTab === "Participants" && PDOS_ID !== 0 ? (
              <ExplorerParticipantsCategories />
            ) : null}
          </div>

          <div className="w-4/5 h-[95%]">
            {/* Modal choice with choiceTab  */}
            {ficRef === 0 && choiceTab !== "Create" ? (
              <p className="text-center mt-4">
                Vous devez selectionner un dossier client
              </p>
            ) : (
              <Tabs />
            )}
            {choiceTab === "Create" ? (
              <ClientFile />
            ) : choiceTab === "Fiche client" && ficRef !== 0 ? (
              <ClientFile />
            ) : choiceTab === "Analytique" && ficRef !== 0 ? (
              <Analytic />
            ) : choiceTab === "Documents" && ficRef !== 0 ? (
              <DocumentsList />
            ) : choiceTab === "Missions" && ficRef !== 0 ? (
              <Mission />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Crm;
