import { Toaster } from "react-hot-toast";
import Header from "../components/Layout/Header";
import useModal from "../hooks/useModal";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Tabs from "../components/FinancialStatement/Tabs";
import { useTabsFSStore } from "../store/FinancialStatement/tabsStore";
import Production from "../components/FinancialStatement/modal/Production";
import Billing from "../components/FinancialStatement/modal/Billing";
import Workload from "../components/FinancialStatement/modal/Workload";
import CashInFLow from "../components/FinancialStatement/modal/CashInFlow";
import PdfViewer from "../components/FinancialStatement/PdfViewer";
import { useEffect } from "react";
import { useDateExerciceStore } from "../store/FinancialStatement/dateExerciceStore";
import { useClientFileStore } from "../store/FinancialStatement/inputStore";

/**
 * @page
 * @name FinancialStatement
 * @description
 * FinancialStatement page with the tabs, the modal and the pdf viewer
 * @author Iris Succi
 * @date 2023
 */

const FinancialStatement = () => {
  // Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  //Store
  const {
    productionTypeSelected,
    choiceTab,
    setDocumentUrl,
    setCount,
    billingTypeSelected,
    workloadTypeSelected,
    cashInFlowTypeSelected,
  } = useTabsFSStore();
  const { setDu, setAu } = useDateExerciceStore();
  const { setSelectedBinder, setSelectedClientFile } = useClientFileStore();

  useEffect(() => {
    setDocumentUrl("");
    setAu("", "", 0, 0);
    setDu("", "", 0, 0);
    setCount(0);
    setSelectedBinder([]);
    setSelectedClientFile(0);
  }, [
    choiceTab,
    productionTypeSelected,
    billingTypeSelected,
    cashInFlowTypeSelected,
    workloadTypeSelected,
  ]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <Toaster position="top-center" reverseOrder />
      <Header openModal={openModal} closeModal={closeModal} />
      <div className="w-full flex h-full">
        <div className="h-auto">
          <Navbar />
        </div>
        <div className="w-[540px] h-full ml-4 mr-4">
          <Tabs />
          {choiceTab === "Production" ? (
            <Production />
          ) : choiceTab === "Facturation" ? (
            <Billing />
          ) : choiceTab === "Encaissement" ? (
            <CashInFLow />
          ) : choiceTab === "Plan de charge" ? (
            <Workload />
          ) : null}
        </div>
        <div className="w-full h-full mt-1 mr-4">
          <PdfViewer />
        </div>
        {/* MODAL */}
        {isModalOpen ? modalContent : null}
      </div>
      <Footer />
    </div>
  );
};

export default FinancialStatement;
