import { create } from 'zustand';
interface FinancialExercise {
  AEX_REF: number;
  AEX_INTITULE: string;
  AEX_ANNEE: number;
  AEX_MOIS: string;
  AEX_LIB_MOIS_LONG: string;
}

interface DateExerciceState {
    du: {
      exercice: string;
      mois: string;
      aex_ref: number;
      aex_annee: number;
    };
    au: {
      exercice: string;
      mois: string;
      aex_ref: number;
      aex_annee: number;
    };
    setDu: (exercice: string, mois: string, aex_ref: number, aex_annee: number) => void;
    setAu: (exercice: string, mois: string, aex_ref: number, aex_annee: number) => void;

    financialExercises: FinancialExercise[];
    setFinancialExercises: (exercises: FinancialExercise[]) => void;
  }
  
  // Création du store Zustand.
  export const useDateExerciceStore = create<DateExerciceState>((set) => ({
    du: { exercice: '', mois: '', aex_ref: 0, aex_annee: 0 },
    au: { exercice: '', mois: '' , aex_ref: 0, aex_annee: 0 },
    setDu: (exercice, mois, aex_ref, aex_annee ) => set({ du: { exercice, mois, aex_ref, aex_annee } }),
    setAu: (exercice, mois, aex_ref, aex_annee ) => set({ au: { exercice, mois, aex_ref ,aex_annee} }),
    financialExercises: [],
    setFinancialExercises: (exercises) => set({ financialExercises: exercises }),  
  }));