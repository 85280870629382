import { create } from "zustand";
import {
  SelectOptionDossier,
  SelectOptionsClasseur,
  SelectOptionsModel,
} from "../../interface/selects";
import { allUsers } from "../../interface/data/user";

interface MyState {
    missions: any[];
    setMissions: (missions: any[]) => void;

    classeurs: SelectOptionsClasseur[];
    setClasseurs: (classeurs: SelectOptionsClasseur[]) => void;
  
    models: SelectOptionsModel[];
    setModels: (models: SelectOptionsModel[]) => void;

    fileOptions: SelectOptionDossier[];
    setFileOptions: (fileOptions: SelectOptionDossier[]) => void;
  
    varFile: any[];
    setVarFile: (varFile: any[]) => void;
  
    varFileLib: any[];
    setVarFileLib: (varFileLib: any[]) => void;
  
    startDateFilter: any;
    setStartDateFilter: (date: any) => void;
  
    endDateFilter: any;
    setEndDateFilter: (date: any) => void;
  
    selectedClasseur: number;
    setSelectedClasseur: (classeur: number) => void;
  
    selectedMisRef: number;
    setSelectedMisRef: (misRef: number) => void;
  
    selectedVarFileLib: any;
    setSelectedVarFileLib: (varFileLib: any) => void;
  
    users: allUsers[];
    setUsers: (users: allUsers[]) => void;
  
    selectedUsersActeurs: string[];
    setSelectedUsersActeurs: (usersActeurs: string[]) => void;
  
    selectedAllUsers: string[];
    setSelectedAllUsers: (allUsers: string[]) => void;
  
    selectedOperator: string;
    setSelectedOperator: (operator: string) => void;
  
    selectedTime: any;
    setSelectedTime: (time: any) => void;
  
    selectedStateTime: string;
    setSelectedStateTime: (stateTime: string) => void;
  
    selectedStateMission: string;
    setSelectedStateMission: (stateMission: string) => void;
  
    selectedFileOption: SelectOptionDossier | null;
    setSelectedFileOption: (fileOption: SelectOptionDossier | null) => void;
  
    selectedOriginObject: any;
    setSelectedOriginObject: (originObject: any) => void;

    selectedLibelleObject: any;
    setSelectedLibelleObject: (originObject: any) => void;

    selectedFile: number;
    setSelectedFile: (file: number) => void;

    selectedMission: string;
    setSelectedMission: (mission: string) => void;

    selectedOrigin: string;
    setSelectedOrigin: (origin: string) => void;

    selectedLibelle: string;
    setSelectedLibelle: (libelle: string) => void;

    selectedModel: string;
    setSelectedModel: (model: string) => void;

    selectedVarFile: number;
    setSelectedVarFile: (varFile: number) => void;

    origins: SelectOptionDossier[];
    setOrigins: (origins: SelectOptionDossier[]) => void;

    libelles: SelectOptionDossier[];
    setLibelles: (libelles: SelectOptionDossier[]) => void;

    choice : string;
    setChoice: (choice: string) => void;
  }

export const filterStore = create<MyState>((set) => ({
  startDateFilter: (() => {
    const storedValue = localStorage.getItem("startDateFilter");
    const defaultDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 8);
    return storedValue 
      ? new Date(JSON.parse(storedValue)) 
      : defaultDate;
  })(),
  setStartDateFilter: (date) => set({ startDateFilter: date }),

  endDateFilter: (() => {
    const storedValue = localStorage.getItem("endDateFilter");
    return storedValue ? new Date(JSON.parse(storedValue)) : new Date();
  })(),
  setEndDateFilter: (date) => set({ endDateFilter: date }),

  selectedFile: (() => {
    const storedValue = localStorage.getItem("selectedFile");
    return storedValue ? JSON.parse(storedValue)?.value : 0;
  })(),
  setSelectedFile: (file: number) => set({ selectedFile: file }),

  selectedMission: (() => {
    const storedValue = localStorage.getItem("selectedMission");
    return storedValue ? JSON.parse(storedValue) : "";
  })(),
  setSelectedMission: (mission) => set({ selectedMission: mission }),

  selectedOrigin: (() => {
    const storedValue = localStorage.getItem("selectedOrigin");
    return storedValue ? storedValue : "";
  })(),
  setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),

  selectedLibelle: (() => {
    const storedValue = localStorage.getItem("selectedLibelle");
    return storedValue ? storedValue : "";
  })(),
  setSelectedLibelle: (libelle) => set({ selectedLibelle: libelle }),

  selectedModel: (() => {
    const storedValue = localStorage.getItem("selectedModel");
    return storedValue ? storedValue : "";
  })(),
  setSelectedModel: (model) => set({ selectedModel: model }),

  selectedVarFile: (() => {
    const storedValue = localStorage.getItem("selectedVarFile");
    return storedValue ? parseInt(storedValue, 10) : 0;
  })(),
  setSelectedVarFile: (varFile) => set({ selectedVarFile: varFile }),

  selectedClasseur: (() => {
    const storedValue = localStorage.getItem("selectedClasseur");
    return storedValue !== null ? Number(storedValue) : 0;
  })(),
  setSelectedClasseur: (classeur) => set({ selectedClasseur: classeur }),

  classeurs: [],
  setClasseurs: (classeurs) => set({ classeurs }),

  models: [],
  setModels: (models) => set({ models }),

  varFile: [],
  setVarFile: (varFile) => set({ varFile }),

  varFileLib: [],
  setVarFileLib: (varFileLib) => set({ varFileLib }),

  selectedMisRef: 0,
  setSelectedMisRef: (misRef) => set({ selectedMisRef: misRef }),

  selectedVarFileLib: null,
  setSelectedVarFileLib: (varFileLib) =>
    set({ selectedVarFileLib: varFileLib }),

  users: [],
  setUsers: (users) => set({ users }),

  selectedUsersActeurs: [],
  setSelectedUsersActeurs: (usersActeurs) =>
    set({ selectedUsersActeurs: usersActeurs }),

  selectedAllUsers: [],
  setSelectedAllUsers: (allUsers) => set({ selectedAllUsers: allUsers }),

  selectedOperator: "",
  setSelectedOperator: (operator) => set({ selectedOperator: operator }),

  selectedTime: 0,
  setSelectedTime: (time) => set({ selectedTime: time }),

  selectedStateTime: "toutes",
  setSelectedStateTime: (stateTime) => set({ selectedStateTime: stateTime }),

  selectedStateMission: "tout",
  setSelectedStateMission: (stateMission) =>
    set({ selectedStateMission: stateMission }),

  selectedFileOption: null,
  setSelectedFileOption: (fileOption) =>
    set({ selectedFileOption: fileOption }),

  selectedOriginObject: null,
  setSelectedOriginObject: (originObject) =>
    set({ selectedOriginObject: originObject }),

  missions: [],
  setMissions: (missions) => set({ missions }),

  fileOptions: [],
  setFileOptions: (fileOptions) => set({ fileOptions }),

  origins: [],
    setOrigins: (origins) => set({ origins }),

  libelles: [],
  setLibelles: (libelles) => set({ libelles }),

  choice: "",
  setChoice: (choice) => set({ choice }),

  selectedLibelleObject: null,
  setSelectedLibelleObject: (libelleObject) =>
    set({ selectedLibelleObject: libelleObject }),

}));
