export const itemsListTime = "Etat, Dossier, Opér., Act., Date, Heure, Tx pond., Origine, Libellé, Commentaire, Mission, Libellé Mission, Hon. val, Hon. acquis, NF"

export const columnMappings: any = {
    Commentaire: "AA_ANA_COMPLEMENTLIB",
    Date: "AA_ANA_DATEREALISATION",
    Libellé: "AA_ANA_LIBELLELIB",
    Heure: "AA_ANA_TEMPS",
    "Opér.": "AA_OPERATEUR",
    "Act.": "AA_REDACTEUR",
    Origine: "AO_AOR_LIBELLE",
    Dossier: "TD_FIC_TITRE_CLIENT",
    "Libellé Mission": "VM_MIS_LIBELLE",
    Mission: "VM_MIS_CODE",
    Etat: [
      "AA_ANA_RGBCOLOR",
      "AA_ACT_ID",
      "AA_ANA_REFPERE",
      "AOR_OPTION_FACTURABLE",
      "ANA_EMTHT",
    ],
    "Tx pond.": "AA_ALI_PERCENT_TAUX",
    "Hon. val": "AA_ANA_EMTHT",
    NF: "AO_AOR_OPTION_FACTURABLE",
    "Hon. acquis": "AA_ANA_EMONTANT_FACT",
  };

  export const orderedColumns = [
    "Etat",
    "Opér.",
    "Act.",
    "Date",
    "Dossier",
    "Mission",
    "Libellé",
    "Libellé Mission",
    "Origine",
    "Heure",
    "Tx pond.",
    "Hon. val",
    "Hon. acquis",
    "Commentaire",
  ];

  type ColumnWidths = {
    [key: string]: number;
};

  export const columnWidths: ColumnWidths  = {
    "Etat": 40,  
    "Dossier": 120,
    "Act.": 40,
    "Opér.": 40,
    "Date": 90,
    "Mission": 60,
    "Libellé": 100,
    "Libellé Mission": 112,
    "Origine": 60,
    "Heure": 40,
    "Tx pond.": 40,
    "Hon. val": 50,
    "Hon. acquis": 50,
    "Commentaire": 0,
};
