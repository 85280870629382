import { create } from "zustand";

type State = {
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;

    displayCreateCost: boolean;
    setDisplayCreateCost: (displayCreateCost: boolean) => void;

    costs: any[];
    setCosts: (costs: any[]) => void;

    user: string;
    setUser: (user: string) => void;

    costsFilter: any[];
    setCostsFilter: (costsFilter: any[]) => void;

    animationClass: string;
    setAnimationClass: (animationClass: string) => void;

    selectedIdsCosts: any[];
    setSelectedIdsCosts: (update: any[] | ((prev: any[]) => any[])) => void;

    modeCost: string;
    setModeCost: (modeCost: string) => void;

    sendCost: boolean;
    setSendCost:  (sendCost: boolean | ((prev: boolean) => boolean)) => void;

    selectedCostsForValidate: any[];
    setSelectedCostsForValidate: (update: any[] | ((prev: any[]) => any[])) => void;
};

export const useCostStore = create<State>((set, get) => ({
    choiceTab: "cost",
    setChoiceTab: (choiceTab) => set({ choiceTab }),

    displayCreateCost: false,
    setDisplayCreateCost: (displayCreateCost) => set({ displayCreateCost }),

    costs: [],
    setCosts: (costs) => set({ costs }),

    user: "",
    setUser: (user) => set({ user }),

    costsFilter: [],
    setCostsFilter: (costsFilter) => set({ costsFilter }),

    animationClass: "",
    setAnimationClass: (animationClass) => set({ animationClass }),

    selectedIdsCosts: [],
    setSelectedIdsCosts: (update: any[] | ((prev: any[]) => any[])) =>
      typeof update === 'function' ? set((state) => ({ selectedIdsCosts: update(state.selectedIdsCosts) }))
                                   : set({ selectedIdsCosts: update }),

    modeCost: "create",
    setModeCost: (modeCost) => set({ modeCost }),

    sendCost: false,
    setSendCost: (value) => {
        if (typeof value === "function") {
            const currentSendData = get().sendCost;
            set({ sendCost: value(currentSendData) });
        } else {
            set({ sendCost: value });
        }
    },

    selectedCostsForValidate: [],
    setSelectedCostsForValidate: (update: any[] | ((prev: any[]) => any[])) =>
      typeof update === 'function' ? set((state) => ({ selectedCostsForValidate: update(state.selectedCostsForValidate) }))
                                   : set({ selectedCostsForValidate: update }),

}));