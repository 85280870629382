import { SelectOptionsMission } from "../../interface/selects";
import { create } from 'zustand';

type DataProvisionalState = {
    ANA_CODELIB: string | null,
    ANA_DATEREALISATION: Date | null,
    ANA_EMTHT: number | null,
    ANA_EMTTVA:  number | null,
    ANA_EMTNS: number | null,
    ANA_EMTTTC: number | null,
    ANA_NOTES: string | null,
    ANA_REDACTEUR: string | null,
    AOR_REF: string | null,
    DOS_REF: number | null,
    MIS_REF: number | null,
    TVA_CLE: number | null,
    FIC_REF: number | null,
    mask: string | null,
}

interface MyState {
    clientsFile: any[],
    setClientsFile: (clients: any[]) => void,
    
    missions: SelectOptionsMission[],
    setMissions: (missions: SelectOptionsMission[]) => void,

    clients: any[],
    setClients: (clients: any[]) => void,
    
    selectedFile: number,
    setSelectedFile: (file: number) => void,
    
    selectedMission: number,
    setSelectedMission: (mission: number) => void,

    selectedClient: number,
    setSelectedClient: (client: number) => void,

    startDate: Date,
    setStartDate: (date: Date) => void,

    ht: any,
    setHt: (ht: any) => void,
    
    tva: any,
    setTva: (tva: any) => void,
  
    tvaRate: any[],
    setTvaRate: (tvaRate: any[]) => void,
  
    ttc: number,
    setTtc: (ttc: number) => void,

    ns: number,
    setNs: (ns: number) => void,
  
    selectedTvaRate: number,
    setSelectedTvaRate: (tva: number) => void,
  
    origins: any[],
    setOrigins: (origins: any[]) => void,

    selectedOrigin: string,
    setSelectedOrigin: (origin: string) => void,

    interlocuteurs: any[],
    setInterlocuteurs: (interlocuteurs: any[]) => void,

    selectedInterlocuteur: number,
    setSelectedInterlocuteur: (interlocuteur: number) => void,

    dataProvisional: DataProvisionalState,
    setDataProvisional: (property: keyof DataProvisionalState, value: any) => void;

    comment: string,
    setComment: (comment: string) => void;

    responsables: any[],
    setResponsables: (responsables: any[]) => void;

    selectedResponsable: number,
    setSelectedResponsable: (responsable: number) => void;

    libelles: any[],
    setLibelles: (libelles: any[]) => void;

    selectedLibelle: string,
    setSelectedLibelle: (libelle: string) => void;

    masks: any[],
    setMasks: (masks: any[]) => void;

    selectedMask: string,
    setSelectedMask: (mask: string) => void;
}
  
  export const createProvisionStore = create<MyState>((set) => ({
    clientsFile: [],
    setClientsFile: (clients) => set({ clientsFile: clients }),

    missions: [],
    setMissions: (missions) => set({ missions: missions }),

    clients: [],
    setClients: (clients) => set({ clients: clients }),

    selectedFile: 0,
    setSelectedFile: (file) => set({ selectedFile: file }),

    selectedMission: 0,
    setSelectedMission: (mission) => set({ selectedMission: mission }),

    selectedClient: 0,
    setSelectedClient: (client) => set({ selectedClient: client }),

    interlocuteurs: [],
    setInterlocuteurs: (interlocuteurs) => set({ interlocuteurs: interlocuteurs }),

    selectedInterlocuteur: 0,
    setSelectedInterlocuteur: (interlocuteur) => set({ selectedInterlocuteur: interlocuteur }),

    startDate: new Date(),
    setStartDate: (date) => set({ startDate: date }),

    ht: 0,
    setHt: (ht) => set({ ht: ht }),

    tva: 0,
    setTva: (tva) => set({ tva: tva }),

    tvaRate: [],
    setTvaRate: (tvaRate) => set({ tvaRate: tvaRate }),

    selectedTvaRate: 2,
    setSelectedTvaRate: (tva: number) => set({ selectedTvaRate: tva }),

    ttc: 0,
    setTtc: (ttc) => set({ ttc: ttc }),

    ns: 0,
    setNs: (ns) => set({ ns: ns }),

    origins: [],
    setOrigins: (origins) => set({ origins: origins }),

    selectedOrigin: "",
    setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),

    dataProvisional: {
        ANA_CODELIB: null,
        ANA_DATEREALISATION: null,
        ANA_EMTHT: null,
        ANA_EMTTVA: null,
        ANA_EMTNS: 0,
        ANA_EMTTTC: null,
        ANA_NOTES: null,
        ANA_REDACTEUR: null,
        AOR_REF: null,
        DOS_REF: null,
        MIS_REF: null,
        TVA_CLE: 2,
        FIC_REF: null,
        mask: null,
    },
    setDataProvisional: (property, value) => set((state) => ({ dataProvisional: { ...state.dataProvisional, [property]: value } })),
  
    comment: "",
    setComment: (comment) => set({ comment: comment }),

    responsables: [],
    setResponsables: (responsables) => set({ responsables: responsables }),

    selectedResponsable: 0,
    setSelectedResponsable: (responsable) => set({ selectedResponsable: responsable }),

    libelles: [],
    setLibelles: (libelles) => set({ libelles: libelles }),

    selectedLibelle: "",
    setSelectedLibelle: (libelle) => set({ selectedLibelle: libelle }),

    masks: [],
    setMasks: (masks) => set({ masks: masks }),

    selectedMask: '',
    setSelectedMask: (mask) => set({ selectedMask: mask }),
}));