import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get state
 */

export const getFinancialExercices = () => {
    try {
        const response = axiosWithToken().get('api/state/financialexercices');
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
};

export const getPdfProductivity = (startDate: string, endDate: string) => {
    try {
        const response = axiosWithToken().get(`api/state/productivity?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getStateSalesBreakdown = (startDate: string, endDate: string) => {
    try {
        const response = axiosWithToken().get(`api/state/salesbreakdown?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getStateComparedBudgets = (exercice: number) => {
    try {
        const response = axiosWithToken().get(`api/state/comparedbudgets?AEX_REF=${exercice}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getStateStatisticByActivity = (year: any, month: number) => { 
    try {
        const response = axiosWithToken().get(`api/state/statisticbyactivity?ANA_ANNEEREALISATION=${year}&ANA_MOISREALISATION=${month}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getStateInFlowsJournalByPdos = (PDOS_ID: any, startDate: any, endDate: any) => {
    try {
        const response = axiosWithToken().get(`api/state/inflowsjournal?PDOS_ID=${PDOS_ID}&startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getStateInFlowsJournalByDosRef = (DOS_REF: any, startDate: any, endDate: any) => {
    try {
        const response = axiosWithToken().get(`api/state/inflowsjournal?DOS_REF=${DOS_REF}&startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}