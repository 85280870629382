import { create } from "zustand";

type PointAcompteType = {
    prepayment: any;
    setPrepayment: (prepayment: any) => void;

    invoicesCheckables: any[];
    setInvoicesCheckables: (invoicesCheckables: any[]) => void;

    selectedIdsInvoicesForPoint: any[];
    setSelectedIdsInvoicesForPoint: (update: any[] | ((prev: any[]) => any[])) => void;

    montantRestant: number,
    setMontantRestant: (montantRestant: number) => void,

    selectedInvoicesToAllocate: any[],
    setSelectedInvoicesToAllocate: (selectedInvoicesToAllocate: any[]) => void,
}

export const usePointAcompteStore = create<PointAcompteType>((set) => ({
    prepayment: {},
    setPrepayment: (prepayment) => set({ prepayment }),

    invoicesCheckables: [],
    setInvoicesCheckables: (invoicesCheckables) => set({ invoicesCheckables }),

    selectedIdsInvoicesForPoint: [],
    setSelectedIdsInvoicesForPoint: (update: any[] | ((prev: any[]) => any[])) =>
    typeof update === 'function' ? set((state) => ({ selectedIdsInvoicesForPoint: update(state.selectedIdsInvoicesForPoint) }))
                                 : set({ selectedIdsInvoicesForPoint: update }),
    
    montantRestant: 0,
    setMontantRestant: (montantRestant) => set({ montantRestant }),

    selectedInvoicesToAllocate: [],
    setSelectedInvoicesToAllocate: (selectedInvoicesToAllocate) => set({ selectedInvoicesToAllocate }),
}));