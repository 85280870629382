import { create } from 'zustand';
import { dataDayOff, dataMonthnInterval, dataWeeknDay } from '../../interface/data/time';
import { weekTimGeneratePdf } from '../../utils/Pdfs/weekTimeGeneratePdf';

type DataStore = {
    timePotential: any[];
    setTimePotential: (time: any[]) => void;
    
    dataMonth: dataMonthnInterval[];
    setDataMonth: (data: dataMonthnInterval[]) => void;

    dataWeek: dataWeeknDay[];
    setDataWeek: (data: dataWeeknDay[]) => void;

    dataDaysOffWeek: dataDayOff[];
    setDataDaysOffWeek: (data: dataDayOff[]) => void;

    dataWeekSummary: any;
    setDataWeekSummary: (data: any) => void;

    updatedItems: any[];
    setUpdatedItems: (items: any[]) => void;
    updateItem: (itemIndex: number, newItem: any) => void;

    mondayOfCurrentWeek: Date;
    setMondayOfCurrentWeek: (date: Date) => void;

    weekData: any; // Remplacez 'any' par le type approprié si vous l'avez
    setWeekData: (data: any) => void;

    days7: any[]; // Remplacez 'any' par le type approprié si vous l'avez
    setDays7: (data: any[]) => void;

    generatePDF: () => void;
    initializeGeneratePDF: () => void;

}

export const useDataStore = create<DataStore>((set, get) => ({
    timePotential: [],
    setTimePotential: (time) => set({ timePotential: time }),

    dataMonth: [],
    setDataMonth: (data) => set({ dataMonth: data }),

    dataWeek: [],
    setDataWeek: (data) => set({ dataWeek: data }),

    dataDaysOffWeek: [],
    setDataDaysOffWeek: (data) => set({ dataDaysOffWeek: data }),

    dataWeekSummary: [],
    setDataWeekSummary: (data) => set({ dataWeekSummary: data }),

    updatedItems: [],
    setUpdatedItems: (items) => set({ updatedItems: items }),

    updateItem: (itemIndex, newItem) => {
        const oldItems = get().updatedItems;
        const existingIndex = oldItems.findIndex(
            (_item: any, index: any) => index === itemIndex
        );

        if (existingIndex > -1) {
            // Si l'élément existe déjà, mettre à jour
            const updated = [...oldItems];
            updated[existingIndex] = newItem;
            set({ updatedItems: updated });
        } else {
            // Si l'élément n'existe pas encore, ajouter
            set({ updatedItems: [...oldItems, newItem] });
        }
    },

    mondayOfCurrentWeek: new Date(), // valeur initiale
    setMondayOfCurrentWeek: (date) => set({ mondayOfCurrentWeek: date }),

    weekData: [], // valeur initiale
    setWeekData: (data) => set({ weekData: data }),

    days7: [], // valeur initiale
    setDays7: (data) => set({ days7: data }),
    
    generatePDF: () => {},
    initializeGeneratePDF: () => {
        set({
            generatePDF: () => {
                const current = get();
                weekTimGeneratePdf(current.days7, current.weekData);
            }
        });
    }

}));
