import { Toaster } from "react-hot-toast";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import useModal from "../hooks/useModal";
import { useUtilsAnalyseStore } from "../store/Analyse/utilsAnalyse";
import { useEffect } from "react";
import Select from "../components/Analyse/Select";
import {
  getChartsBillables,
  getChartsBillablesPercentage,
  getChartsCashInFlow,
  getChartsCashInFlowPercentage,
  getChartsProduction,
  getChartsProductionPercentage,
} from "../utils/Analyse/charts";
import Chart from "../components/Analyse/Chart";
import { useLoadingStore } from "../store/utils/loading";
import ChoiceChart from "../components/Analyse/ChoiceChart";
import PieCharts from "../components/Analyse/PieCharts";
import { useNavigate } from "react-router-dom";

/**
 * @page
 * @name Analyse
 * @description
 * Analyse est la page principale de l'application pour l'analyse des données. Elle permet de visualiser les données de production, d'encaissement et de facturation.
 * @author Iris Succi
 * @date 2023
 */
const Analyse = () => {
  // Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { choiceTab, setData, choiceTabEvoOrRev } = useUtilsAnalyseStore();
  const { setIsLoading } = useLoadingStore();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (choiceTab === "Production" && choiceTabEvoOrRev === "Evolution") {
      getChartsProduction()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } else if (
      choiceTab === "Encaissement" &&
      choiceTabEvoOrRev === "Evolution"
    ) {
      getChartsCashInFlow()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } else if (
      choiceTab === "Facturation" &&
      choiceTabEvoOrRev === "Evolution"
    ) {
      getChartsBillables()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (
      choiceTab === "Production" &&
      choiceTabEvoOrRev === "Repartition"
    ) {
      getChartsProductionPercentage()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (
      choiceTab === "Encaissement" &&
      choiceTabEvoOrRev === "Repartition"
    ) {
      getChartsCashInFlowPercentage()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (
      choiceTab === "Facturation" &&
      choiceTabEvoOrRev === "Repartition"
    ) {
      getChartsBillablesPercentage()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
      navigate("/home");
    }
  }, [choiceTab, choiceTabEvoOrRev]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <Toaster position="top-center" reverseOrder />
      <Header openModal={openModal} closeModal={closeModal} />
      <div className="w-full flex h-full">
        <div className="h-auto">
          <Navbar />
        </div>
        <div className="w-full h-5/6 ml-4 mr-4 flex flex-col justify-center items-center">
          <Select />
          <ChoiceChart />
          {choiceTabEvoOrRev === "Evolution" ? <Chart /> : <PieCharts />}
        </div>
      </div>
      {/* MODAL */}
      {isModalOpen ? modalContent : null}
      <Footer />
    </div>
  );
};

export default Analyse;
