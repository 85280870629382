import { createContext, useContext, useState } from "react";
import { RightStateContextValue } from "../interface/context";

const initialData = JSON.parse(localStorage.getItem("rightsState") || "{}");

const RightStateContext = createContext<RightStateContextValue | null>(null);

export const RightStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [facturationStateRight, setFacturationStateRight] = useState<number>(
      initialData["Etats de facturation"] || 0
  );
  const [productionStateRight, setProductionStateRight] = useState<number>(
      initialData["Etats de production"] || 0
  );
  const [encaissementStateRight, setEncaissementStateRight] = useState<number>(
      initialData["Etats des encaissements"] || 0
  );
  return (
    <RightStateContext.Provider
      value={{
        facturationStateRight,
        setFacturationStateRight,
        productionStateRight,
        setProductionStateRight,
        encaissementStateRight,
        setEncaissementStateRight,
      }}
    >
      {children}
    </RightStateContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useRightStateContext = () => {
  const context = useContext(RightStateContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
