import download from "../../../assets/icons/download.svg";
import useOutsideClick from "../../../hooks/useClickOutside";
import { useState } from "react";
import { invoiceZip } from "../../../utils/Invoice/invoicing";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { useLoadingStore } from "../../../store/utils/loading";
import { errorSendNewTime } from "../../../utils/toaster";
import { useTabsStore } from "../../../store/Invoice/tabsStore";

/**
 * @component
 * @name Export
 * @description
 * A component that renders a button for exporting documents.
 *
 * @param {object} download - The URL of the image for the download icon.
 */
const Export = () => {
  //State
  const [isOpenExport, setIsOpenExport] = useState<boolean>(false);

  //Hooks
  const { ref, isVisible, setIsVisible } = useOutsideClick(false, () =>
    setIsOpenExport(false)
  );

  //Store
  const { choiceTab } = useTabsStore();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();
  const { setIsLoading } = useLoadingStore();
  const toggleMenu = () => {
    setIsVisible(!isVisible);
    setIsOpenExport(!isVisible);
  };

  /**
   * @function
   * @name handlePdf
   * @description A function that handles the export of invoices in pdf with zip format.
   * @author Succi Iris
   * @date 01/02/2024
   */
  const handlePdf = () => {
    setIsOpenExport(false);
    if(choiceTab === "Facture") {
      invoiceZip(String(selectedIdsInvoices))
      .then((response) => {
        setIsLoading(false);
        setSelectedIdsInvoices((_prev) => []);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "documents.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        setIsLoading(false);
        errorSendNewTime();
        console.log(err)});
      }
      else return
    }

  return (
    <div className="relative" ref={ref}>
      <button
        className="w-12 h-8 bg-bc-orange border-[1px] flex justify-center items-center mr-8 px-4 rounded text-white hover:bg-bc-lightOrange"
        onClick={toggleMenu}
      >
        <img src={download} alt="icon download" className="h-4 w-4" />
      </button>
      {isOpenExport && (
        <div className="absolute top-8 w-44 right-8 h-auto border-[1px] bg-slate-200 rounded-md z-50 ">
          <div className="flex flex-col justify-start items-start ">
            <button
              className="p-2 text-sm border-b-[1px] w-full hover:bg-slate-50 rounded-t-md"
              onClick={() => {
                setIsOpenExport(false);
                handlePdf()
              }}
            >
              PDF
            </button>
            {/* <button
              onClick={() => {
                setIsOpenExport(false);
              }}
              className="p-2 text-sm w-full hover:bg-slate-50 rounded-b-md"
            >
              Excel
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Export;
