import { useTranslation } from "react-i18next";
import { useViewStore } from "../../../store/TimeManagement/viewStore";
import { useDateStore } from "../../../store/TimeManagement/dateStore";

/**
 * @component
 * @name EditButton
 * @author Succi Iris
 * @date 2023
 * @description
 * The `EditButton` component toggles the edit mode and displays the "Edition" time.
 */
const EditButton = () => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { setView, setEditMode } = useViewStore();
  const { setStartDate } = useDateStore();

  return (
    <button
      className="w-20 lg:h-9 h-6 border-[1px] border-bc-orange rounded flex justify-center items-center mr-2 text-bc-orange px-2 shadow-md"
      onClick={() => {
        setStartDate(new Date());
        setEditMode((prev: any) => !prev);
        setView("edit");
      }}
    >
      {t("Edition")}
    </button>
  );
};

export default EditButton;
