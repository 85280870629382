import { useEffect } from "react";
import Cookies from "js-cookie";
import { getAlias, login } from "../utils/user";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../assets/BUROCLIC.png";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../context/UserContext";
import { Toaster } from "react-hot-toast";
import { errorConnection } from "../utils/toaster";
import { useRightContext } from "../context/RightContext";
import buroClicLogo from "../assets/BUROCLIC.ONLINE.log.svg";
import { useLoginStore } from "../store/utils/login";

/**
 * @page
 * @name Connection
 * @description
 * Connection est la page principale de l'application pour la connexion. Elle permet de se connecter à l'application Buroclic.
 * @author Iris Succi
 * @date 2023
 */

const Connection = () => {
  // Context
  const { setId, setFirstname, setLastname, setAlias, setSession } =
    useUserContext();
  const {
    setBuroFact,
    setBuroTime,
    setEasyFile,
    setEtats,
    setChrono,
    setAnalyse,
    setCost,
  } = useRightContext();

  // Store
  const {
    identifiant,
    setIdentifiant,
    password,
    setPassword,
    aliasList,
    setAliasList,
  } = useLoginStore();

  // Translation
  const { t } = useTranslation();

  //Hook
  const navigate = useNavigate();
  const { alias } = useParams();

  useEffect(() => {
    getAlias()
      .then((res) => {
        setAliasList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const checkAliasInUrl = (aliasList: any) => {
    const currentUrl = window.location.href;
    const aliasInUrl = currentUrl.split("/")[4];
    return aliasList.includes(aliasInUrl);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!checkAliasInUrl(aliasList)) {
        return errorConnection();
      } else {
        const response = await login(identifiant, password, alias);
        if (response?.status === 200) {
          const session = Cookies.get("sessionId");
          if (typeof session === "string") {
            setId(response.data.identifiant);
            setAlias(response.data.alias);
            setFirstname(response.data.firstname);
            setLastname(response.data.lastname);
            setBuroFact(response.data.BuroFact);
            setBuroTime(response.data.BuroTime);
            setEasyFile(response.data.EasyFile);
            setEtats(response.data.Etats);
            setAnalyse(response.data.Graphiques);
            setChrono(response.data["Chrono des documents"]);
            setCost(response.data["Notes de frais"]);
            setSession(true);
            localStorage.setItem("firstRight", JSON.stringify(response.data));
            localStorage.setItem("alias", JSON.stringify(response.data.alias));
            if (session && localStorage.getItem("firstRight") !== "")
              navigate("/home");
          }
        }
      }
    } catch (error) {
      console.log(error);
      errorConnection();
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${buroClicLogo})` }}
      className="w-screen h-screen bg-cover bg-no-repeat bg-center flex justify-start items-center"
    >
      <Toaster position="top-center" reverseOrder />
      <div className="bg-white rounded-md flex flex-col justify-center items-center shadow-xl w-3/12 h-1/2 ml-10">
        <img src={logo} alt="logo entreprise" className="w-52 2xl:py-5  py-2" />
        <div className="flex flex-col justify-center items-center w-full">
          <p className="text-center font-extralight py-4">Pré-production</p>
          <form onSubmit={handleSubmit} className="w-9/12 m-auto h-full">
            <div className="flex flex-col justify-start items-start w-full m-auto my-5 text-bc-darkGrey ">
              <label htmlFor="email">{t("Identifiant")}:</label>
              <input
                type="text"
                id="indentifiant"
                value={identifiant}
                onChange={(e) => setIdentifiant(e.target.value)}
                className="w-full border-b-[1px] border-bc-gray mt-2"
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full m-auto 2xl:my-10 my-4 text-bc-darkGrey ">
              <label htmlFor="password">{t("Mot de passe")} :</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border-b-[1px] border-bc-gray mt-2"
              />
            </div>
            <div className="flex justify-center items-center 2xl:py-5">
              <button
                type="submit"
                className="w-auto px-4 py-1 bg-bc-green rounded-md text-white shadow-md lg:text-lg "
              >
                {t("Se connecter")}
              </button>
            </div>
            <p className="italic font-extralight text-xs py-5 text-center">
              Powered by Buroclic
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Connection;
