import { useTranslation } from "react-i18next";
import { usePrintStore } from "../../store/TimeManagement/printStore";
import ReactToPrint from "react-to-print";

/**
 * @component
 * @name PrintButton
 * @author Iris Succi
 * @date 2023
 * @description
 * A React component that renders a print button. When clicked, it triggers
 * the printing of specific content in a React application.
 *
 * The component uses ReactToPrint for managing print functionality.
 * It also integrates localization by using the `useTranslation` hook for internationalization.
 */

const PrintButton = ({ tableRefForWeek }: any) => {
  //Translate
  const { t } = useTranslation();

  //Store
  const { printContent } = usePrintStore();

  const handlePrint = () => {
    if (printContent) {
      printContent();
    }
  };
  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button
            className="w-auto lg:h-9 h-6 bg-bc-orange rounded hidden lg:flex justify-center items-center mr-2 text-white px-2 shadow-md hover:bg-bc-lightOrange transition-colors duration-200"
            onClick={handlePrint}
          >
            {t("Imprimer")}
          </button>
        )}
        content={() => tableRefForWeek.current}
      />
    </>
  );
};

export default PrintButton;
