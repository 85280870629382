import { useEffect, useRef } from "react";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import chevron from "../../../../assets/icons/chevron-right.svg";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { useCreateCreditStore } from "../../../../store/Invoice/createCredit";
import { getCreditOrigins } from "../../../../utils/Invoice/origin";
import { getContacts } from "../../../../utils/customerFile";
import {
  getInvoices,
  getProformaMasks,
} from "../../../../utils/Invoice/invoicing";
import { useLoadingStore } from "../../../../store/utils/loading";
import {
  getCreditById,
  postCredit,
  updateCredit,
} from "../../../../utils/Invoice/credits";
import {
  errorSendNewTime,
  successCredit,
  successUpdateData,
} from "../../../../utils/toaster";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";

/**
 * @component
 * @name CreateCredit
 * @author Succi Iris
 * @date 2023
 * @description A React component that displays a form to create or modify a credit note. This component integrates several custom hooks to manage state and interact with the backend, in particular to retrieve and send information relating to assets, contacts, origins and labels. The form allows you to select different options and enter information to create or update a credit note. *
 *
 * @prop {boolean} displayDiv - Indique si le formulaire de création d'avoir doit être affiché.
 * @prop {Function} setDisplayDiv - Fonction pour modifier l'état d'affichage du formulaire.
 * @prop {Function} setAnimationClass - Fonction pour définir la classe d'animation pour l'affichage du formulaire.
 * @prop {string[]} selectedIdsInvoices - Un tableau des identifiants des factures sélectionnées pour l'avoir.
 * @prop {Function} setSelectedIdsInvoices - Fonction pour définir les identifiants des factures sélectionnées.
 * @prop {string} modeCredit - Indique le mode du formulaire, soit "create" pour la création, soit "edit" pour la modification d'un avoir existant.
 * @prop {Object} creditData - Les données de l'avoir en cours de modification, si le mode est "edit".
 * @prop {Function} setCreditData - Fonction pour définir les données de l'avoir en cours de modification.
 * @prop {Function} handleCreateCredit - Fonction pour gérer la création d'un nouvel avoir.
 * @prop {Function} handleUpdateCredit - Fonction pour gérer la mise à jour d'un avoir existant.
 * @prop {Function} reinitializeFields - Fonction pour réinitialiser les champs du formulaire.
 */
export const CreateCredit = () => {
  // Store
  const { createCredit, setCreateCredit } = useCreateFeesOrInvoices();
  const { setIsLoading } = useLoadingStore();
  const { setChoiceTab, setSendData } = useTabsStore();
  const {
    startDate,
    setStartDate,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    totalCredit,
    setTotalCredit,
    compteurs,
    setCompteurs,
    selectedCompteur,
    setSelectedCompteur,
    invoiceInformation,
    setInvoiceInformation,
    interlocutors,
    setInterlocutors,
    solde,
    setSolde,
    selectedInterlocutors,
    setSelectedInterlocutors,
    modeCredit,
    setModeCredit,
    creditData,
    setCreditData,
  } = useCreateCreditStore();
  const {
    animationClass,
    setAnimationClass,
    displayDiv,
    setDisplayDiv,
    selectedDosRef,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();

  /**
   * @function
   * @description This is for display or not the div for create credit
   */
  useEffect(() => {
    if (createCredit === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createCredit]);

  const initialValue = useRef(invoiceInformation?.ANA_EMTHT || 0);

  /**
   * @description Fetches and sets the invoice information based on the selected invoice ID.
   * @param {boolean} displayDiv - Whether or not the create encaissement form is displayed
   */
  useEffect(() => {
    getInvoices()
      .then((res) => {
        const filteredData = res.data.filter(
          (item: any) => item.ANA_REF === selectedIdsInvoices[0]
        );

        if (filteredData.length > 0) {
          setInvoiceInformation(filteredData[0]);
          setSolde(filteredData[0].ANA_EMTHT);
          initialValue.current = filteredData[0].ANA_EMTHT;
        }
      })
      .catch((err) => console.log(err));
  }, [displayDiv]);

  /**
   * @description Fetches and sets the origins, compteurs, and interlocutors data based on the selected dossier ref.
   * @param {boolean} displayDiv - Whether or not the create encaissement form is displayed
   */
  useEffect(() => {
    getCreditOrigins()
      .then((res) => {
        const formattedOrigins = res.data.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
          libelles: origin.Libelles.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
          })),
        }));
        setOrigins(formattedOrigins);
      })
      .catch((err) => {
        console.log(err);
      });

    getProformaMasks()
      .then((res) => {
        const formattedFiles = Object.entries(res.data).map(([key, value]) => ({
          value: key,
          label: value,
        }));
        setCompteurs(formattedFiles);
      })
      .catch((err) => console.log(err));

    getContacts(selectedDosRef)
      .then((res) => {
        const formattedFiles = res?.data.map((contact: any) => ({
          value: contact.DCLE,
          label: contact.name + " " + contact.firstname,
        }));
        setInterlocutors(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, [displayDiv]);

  /**
   * @function
   * @name handleOriginChange
   * @description
   * Handles the change event of the origin select field. Updates the state of the selected origin and libelles variables.
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event object
   */
  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    /*     setDataFee("AOR_REF", selectedOrigin); */
    const selectedOriginObj = origins.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }
  };

  useEffect(() => {
    const creditValue = totalCredit !== "" ? parseInt(totalCredit) : 0;
    setSolde(initialValue.current - creditValue);
  }, [totalCredit]);

  /**
   * @function
   * @name reinitializeFields
   * @description This is for reinitialize all fields
   */
  const reinitializeFields = () => {
    setSelectedOrigin("");
    setSelectedLibelle("");
    setSelectedCompteur("");
    setSelectedInterlocutors(0);
    setInterlocutors([]);
    setTotalCredit("");
    setStartDate(new Date());
    setSelectedIdsInvoices((_prev) => []);
    setSolde(0);
    initialValue.current = 0;
  };

  /**
   * @function
   * @name handleCreateCredit
   * @description This is for create credit
   */
  const handleCreateCredit = async () => {
    setIsLoading(true);

    const data = {
      DOS_REF: selectedDosRef,
      ANA_CODELIB: selectedLibelle,
      AOR_REF: selectedOrigin,
      MIS_REF: invoiceInformation.MIS_REF,
      ANA_REF: invoiceInformation.ANA_REF,
      ANA_EMTHT: totalCredit,
      FIC_REF_CLIENT: invoiceInformation.FIC_REF,
      mask: selectedCompteur,
      ANA_DATEREALISATION: startDate,
      FIC_REF_INTERLOCUTEUR: selectedInterlocutors,
    };

    try {
      setDisplayDiv(false);
      const response = await postCredit(data);
      console.log(response);
      reinitializeFields();
      setCreateCredit(false);
      successCredit();
      setIsLoading(false);
      setSendData((prev) => !prev);
      setSelectedIdsInvoices((_prev) => []);
    } catch {
      console.log("error");
      setSelectedIdsInvoices((_prev) => []);
      setIsLoading(false);
      errorSendNewTime();
      reinitializeFields();
    }
  };


  /*                         EDIT CREDIT                             */
  useEffect(() => {
    if (modeCredit === "edit") {
      try {
        getCreditById(selectedIdsInvoices[0])
          .then((res) => {
            const data = res.data[0];
            setCreditData(data);
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  }, [selectedIdsInvoices]);

  useEffect(() => {
    if (modeCredit === "edit") {
      try {
        const selectedCompteurObj = compteurs?.find(
          (compteur) => compteur.value === creditData.mask
        );
        setSelectedCompteur(selectedCompteurObj?.value || "");

        const selectedOriginObj = origins?.find(
          (origin) => origin.value === creditData.AOR_REF
        );
        setSelectedOrigin(selectedOriginObj?.value || "");
        setLibelles(selectedOriginObj.libelles);

        const selectedLibelleObj = selectedOriginObj?.libelles?.find(
          (libelle: any) => {
            return libelle.value === creditData.ANA_CODELIB;
          }
        );
        setSelectedLibelle(selectedLibelleObj?.value || "");

        const selectedInterlocutorsObj = interlocutors?.find(
          (interloc) => interloc.value === creditData.FIC_REF_INTERLOCUTEUR
        );
        setSelectedInterlocutors(selectedInterlocutorsObj?.value || null);

        setTotalCredit(creditData?.ANA_EMTHT);
        setStartDate(new Date(creditData?.ANA_DATEREALISATION));
      } catch (err) {
        console.log(err);
      }
    }
  }, [modeCredit, creditData]);

  /**
   * @function
   * @name handleUpdateCredit
   * @description This is for update credit
   */
  const handleUpdateCredit = async () => {
    const creditData = {
      DOS_REF: selectedDosRef,
      ANA_CODELIB: selectedLibelle,
      AOR_REF: selectedOrigin,
      MIS_REF: invoiceInformation.MIS_REF,
      ANA_REF: invoiceInformation.ANA_REF,
      ANA_EMTHT: totalCredit,
      FIC_REF_CLIENT: invoiceInformation.FIC_REF,
      mask: selectedCompteur,
      ANA_DATEREALISATION: startDate,
      FIC_REF_INTERLOCUTEUR: selectedInterlocutors,
    };
    try {
      const response = await updateCredit(selectedIdsInvoices[0], creditData);
      console.log(response);
      reinitializeFields();
      setSendData((prev) => !prev);
      setChoiceTab("Avoir");
      setModeCredit("");
      setCreateCredit(false);
      setSelectedIdsInvoices((_prev) => []);
      successUpdateData();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-center h-full w-full">
            <button
              className="h-full w-10 bg-gray-600 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => setCreateCredit(false)}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[80%] h-[90%] m-auto mr-10">
              <div className="flex justify-center items-center w-full">
                <p className="font-bold text-lg mb-5 mr-20">
                  {modeCredit === "create"
                    ? "Création d'un avoir"
                    : "Modifier un avoir"}
                </p>
              </div>

              <div className="grid grid-cols-4 gap-4 w-[90%] mb-5 bg-slate-50 rounded-md">
                <div className="flex justify-around items-center col-start-1 col-end-5">
                  <p className="m-2">
                    N° facture :{" "}
                    <span className="font-bold ml-2">
                      {invoiceInformation?.ANA_FACTURE}
                    </span>
                  </p>

                  <p className="m-2">
                    Dossier :
                    <span className="font-bold ml-2">
                      {invoiceInformation?.DOS_TITRE}
                    </span>
                  </p>
                </div>

                <p className="col-start-1 col-end-2 font-bold m-2">
                  {invoiceInformation?.ANA_EMTHT} €{" "}
                  <span className="font-normal">HT</span>
                </p>
                <p className="col-start-2 col-end-3 font-bold m-2">
                  {invoiceInformation?.ANA_EMTTVA} €{" "}
                  <span className="font-normal">TVA</span>
                </p>
                <p className="col-start-3 col-end-4 m-2">
                  <span className="font-normal">NS</span>
                </p>
                <p className="col-start-4 col-end-5 font-bold m-2">
                  {invoiceInformation?.ANA_EMTTTC} €{" "}
                  <span className="font-normal">TTC</span>
                </p>
              </div>
              <hr className="w-2/3 m-auto my-5" />
              <div className="flex flex-col justify-between items-start w-[90%] mt-5">
                <div className="grid grid-cols-7 w-full mb-4 gap-4">             
                  <p className="col-start-1 col-end-2 pt-1">Compteur avoir</p>
                  <select
                    name="Compteur"
                    id="Compteur"
                    className="w-full col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedCompteur}
                    onChange={(event) => {
                      setSelectedCompteur(event.target.value);
                    }}
                  >
                    <option value={""}></option>
                    {compteurs?.map((compteur, i) => (
                      <option key={i} value={compteur?.value}>
                        {compteur?.value}
                      </option>
                    ))}
                  </select>
              <p className="col-start-5 col-end-6">Date de l'avoir :</p>
                  <div className="col-start-6 col-end-8 z-30">
                    <Datepicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                  </div>
                  <p className="col-start-1 col-end-2 pt-1">Origine :</p>
                  <select
                    name="origin"
                    id="origin"
                    className="w-full col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={handleOriginChange}
                  >
                    <option value={""}></option>
                    {origins?.map((origin, i) => (
                      <option key={i} value={origin?.value}>
                        {origin?.label}
                      </option>
                    ))}
                  </select>
                  <p className="col-start-5 col-end-6 pt-1">Libelle :</p>
                  <select
                    name="Libelle"
                    id="Libelle"
                    className="w-full col-start-6 col-end-8 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedLibelle}
                    onChange={(event) => {
                      setSelectedLibelle(event.target.value);
                    }}
                  >
                    <option value={""}></option>
                    {libelles?.map((libelle, i) => (
                      <option key={i} value={libelle.value}>
                        {libelle.label}
                      </option>
                    ))}
                  </select>
                  <p className="col-start-1 col-end-2 pt-2">Interlocuteur :</p>
                  <select
                    name="Interlocuteur"
                    id="Interlocuteur"
                    className="w-full col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedInterlocutors}
                    onChange={(event) => {
                      setSelectedInterlocutors(parseInt(event.target.value));
                    }}
                  >
                    <option value=""></option>
                    {interlocutors?.map((interloc, i) => (
                      <option key={i} value={interloc.value}>
                        {interloc.label}
                      </option>
                    ))}
                  </select>
                  <p className="col-start-5 col-end-7 pt-2">
                    Montant H.T avoir :
                  </p>
                  <input
                    type="number"
                    name="montantHT"
                    id="montantHT"
                    value={totalCredit}
                    onChange={(event) => {
                      setTotalCredit(event.target.value);
                    }}
                    className="h-9 w-full col-start-7 col-end-8 border-[1px] rounded-md border-bc-grey px-1 m-1 "
                  />
                  <p className="col-start-6 col-end-7 font-normal">SOLDE :</p>{" "}
                  <p className="col-start-7 col-end-8 font-bold ml-2">
                    {solde} €
                  </p>
                </div>
                <div className="w-full flex justify-end items-end mt-5">
                  <button
                    className="w-40 h-8 border-[1px] rounded-md bg-rose-600 text-white hover:border-rose-500 hover:bg-rose-500 mr-2"
                    onClick={() => {
                      setSelectedIdsInvoices((_prev) => []);
                      setDisplayDiv(false);
                      reinitializeFields();
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    className="w-40 h-8 border-[1px] rounded-md bg-bc-green text-white hover:border-bc-lightGreen hover:bg-bc-lightGreen"
                    onClick={() => {
                      if (modeCredit === "create") handleCreateCredit();
                      else handleUpdateCredit();
                    }}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
