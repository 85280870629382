import { create } from 'zustand';
import { validateBeforeAddNewLine } from '../../utils/toaster';

type addRowEditStore = {
  addRow: boolean;
  setAddRow: (addRow: boolean) => void;
  newLines: any[];
  setNewLines: (newLines: any[]) => void;
  addLine: (newData: any) => void;
};

export const useAddRowEditStore = create<addRowEditStore>((set, get) => ({
  addRow: false,

  setAddRow: (addRow) => set({ addRow }),

  newLines: [],

  setNewLines: (newLines) => set({ newLines }),

  addLine: (newData) => {
    const { newLines } = get();
    if (newLines.length >= 1) {
      validateBeforeAddNewLine(); 
    } else {
      set((state) => ({ newLines: [...state.newLines, newData] }));
    }
  },
}));
