import { ColorTypes, PDFDocument, RGB } from 'pdf-lib';
import { columnMappings } from '../../data/dataSelectItemDisplayListTime';

export const listTimGeneratePdf = async (sortedSelectedItems: any, dataFilterList: any) => {

    try {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage([842, 595]);
    const marginRight = 10;
    let rowIndex = 0; // Index de la ligne actuelle
    const headerBackgroundColor: RGB = { 
        type: ColorTypes.RGB,
        red: 243/255,
        green: 183/255,
        blue: 45/255
    };

    const contentBackgroundColor: RGB = {
        type: ColorTypes.RGB,
        red: 0.8, 
        green: 0.8, 
        blue: 0.8 
    };

    const drawHeader = (page: any, xPosition: number, yPosition: number, items: any) => {
        items.forEach((item: any, index: number) => {
            // Dessiner le fond pour l'en-tête
            page.drawRectangle({
                x: (xPosition - marginRight) + (index * 90),
                y: yPosition - 5, // considérant que la taille de votre texte est 12
                width: 90, // la largeur de chaque colonne
                height: 24, 
                color: headerBackgroundColor
            });
        
            // Dessiner le texte de l'en-tête par-dessus
            page.drawText(item, {
                x: xPosition + (index * 90),
                y: yPosition,
                size: 12,
            });
        });
    }

    // Définir la position initiale pour le début du tableau
    let yPosition = 580; // position initiale en haut de la page
    const xPosition = 10; // marge gauche
    // const widthTotal = 842 - xPosition - marginRight;
    drawHeader(page, xPosition, yPosition, sortedSelectedItems);
    yPosition -= 20;   // 842 est la largeur totale de la page au format A4 en mode paysage

    /*  // Dessiner les lignes horizontales
    for (let i = 0; i <= dataFilterList.length; i++) {
        page.drawLine({
            start: { x: xPosition, y: (yPosition - 5) - (i * 20) },
            end: { x: xPosition + widthTotal, y: (yPosition - 5) - (i * 20) }, 
        });
    }
    */
 
    // Dessiner le contenu du tableau
    dataFilterList.forEach((item: any) => {
        // Si la position y est trop basse, ajoute une nouvelle page
        if (yPosition < 20) { // 20 est une valeur arbitraire pour la marge du bas
            page = pdfDoc.addPage([842, 595]); // Ajouter une nouvelle page
            yPosition = 580; // Réinitialiser la position y en haut de la nouvelle page
            drawHeader(page, xPosition, yPosition, sortedSelectedItems); // Dessine l'en-tête pour la nouvelle page
            yPosition -= 20;
        }

                // Si rowIndex est impair, dessine le fond avec contentBackgroundColor
        if (rowIndex % 2 !== 0) {
            sortedSelectedItems.forEach((_selectedItem: any, index: number) => {
                page.drawRectangle({
                    x: index * 90,
                    y: yPosition - 7, 
                    width: 90,
                    height: 20, 
                    color: contentBackgroundColor
                });
            });
        }

        rowIndex++;  

        sortedSelectedItems.forEach((selectedItem: any, index: number) => {
            const key = columnMappings[selectedItem];
            const value = item[key];
            
            // Si la valeur est une chaîne de caractères ou un nombre, affichez-la
            if (typeof value === 'string' || typeof value === 'number') {
                page.drawText(value.toString(), { // Convertir en chaîne si c'est un nombre
                    x: xPosition + (index * 90),
                    y: yPosition,
                    size: 8,
                });
            } else {
                console.error(`Erreur: valeur indéfinie ou non-string/non-number pour l'élément avec la clé '${key}':`, value);
            }
        });

        yPosition -= 20; // déplacer la position y vers le bas pour la prochaine ligne
    });

    // Sauvegarder le document PDF
    const pdfBytes = await pdfDoc.save();

    // Télécharger le PDF
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'liste_des_temps.pdf';
    link.click();
} catch (error) {
    console.error("Erreur lors de la génération du PDF:", error);
}
};