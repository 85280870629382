import axios from "axios";
import { axiosWithToken, axiosWithTokenForBlob } from "./axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post document
 */

export const documentsByPar = (par_ref: string) => {
    try{
        const response = axiosWithToken().get(`api/document/bypar?PAR_REF=${par_ref}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const downloadDocument = (doc_ref: number) => {
    try{
        const response = axiosWithTokenForBlob().get(`api/documents/${doc_ref}/download`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const uploadDocument = (file: File, par_ref: string) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("par_ref", par_ref);
        const response = axiosWithTokenForBlob().post(`api/document/upload/${par_ref}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}


export const deleteDocument = (doc_ref: number) => {
    try {
        const response = axiosWithToken().delete(`api/documents/${doc_ref}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}