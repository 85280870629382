import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete prepayments
 */

export const getPrepayments = () => {
    try {
        const response = axiosWithToken().get(`api/prepayments`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}  

export const getDomiciliations = () => {
    try {
        const response = axiosWithToken().get(`api/prepayments/domiciliation`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}  

export const getPaymentMethod = () => {
    try {
        const response = axiosWithToken().get(`api/prepayments/paymentmode`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}  

export const postAcompte = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/prepayments`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getCollectingBanks = () => {
    try {
        const response = axiosWithToken().get(`api/prepayments/collectingbank`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getPrepaymentById = (id: number) => {
    try {
        const response = axiosWithToken().get(`api/prepayments/${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const deletePrepayment = (id: number[]) => {
    try {
        const response = axiosWithToken().delete(`api/prepayments/?ANA_REF=${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const updatePrepayment = (id: number, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/prepayments/${id}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const  postCheckPrepayment = ( idPrepayment: number) => {
    try {
        const response = axiosWithToken().post(`api/prepayments/checkpayments/${idPrepayment}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || "Une erreur est survenue");
          }
          throw error;
    }
}