import { create } from 'zustand';

type DateStore = {
    startDate: Date;
    setStartDate: (startDate: Date) => void;
}

export const useDateStore = create<DateStore>((set) => ({
    startDate: new Date(),
    setStartDate: (startDate: Date) => set(() => ({ startDate })),
}));