/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

/**
 * @hook
 * @name useOutsideClick
 * @author Iris Succi
 * @date 2023
 * A custom React hook for handling clicks outside of a specified DOM element.
 *
 * This hook manages visibility state and provides a mechanism to trigger a callback function
 * when a click event occurs outside of the associated element. It's useful for implementing
 * behaviors like closing a modal or dropdown menu when the user clicks outside of it.
 *
 * @param {boolean} initialState - The initial visibility state of the component.
 * @param {Function} onOutsideClick - A callback function that is executed when a click occurs outside the specified element.
 *
 * @returns {Object} An object containing:
 *  - ref: A React ref object to be attached to the element you want to monitor for outside clicks.
 *  - isVisible: A boolean state indicating the visibility of the element.
 *  - setIsVisible: A function to set the visibility state.
 *
 * Usage:
 * const { ref, isVisible, setIsVisible } = useOutsideClick(false, handleOutsideClick);
 *
 * Where `handleOutsideClick` is a function to be called when a click occurs outside the element.
 * Attach `ref` to the element you want to monitor.
 */

const useOutsideClick = (initialState: boolean, onOutsideClick: () => void) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export default useOutsideClick;
