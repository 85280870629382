import {
  calculateDailyTotals,
  convertToHoursAndMinutes,
  deleteParentheses,
  delphi2html_color,
  generateWeekData,
  getMonday,
} from "../../../utils/TimeManager/timeUtils.js";
import { useTranslation } from "react-i18next";
import { useDateStore } from "../../../store/TimeManagement/dateStore.js";
import { useDataStore } from "../../../store/TimeManagement/dataStore.js";
import useTooltip from "../../../hooks/useTooltip.js";
import { useCurrentLangContext } from "../../../context/LangContext.js";
import { useEffect } from "react";
import { usePrintStore } from "../../../store/TimeManagement/printStore.js";
import { useViewStore } from "../../../store/TimeManagement/viewStore.js";
import { editStore } from "../../../store/TimeManagement/editStore.js";
import "../../../App.css";
/**
 * @component
 * @name WeekViews
 * @author Succi Iris
 * @date 2023
 * @description
 * Le composant `WeekViews` permet de gérer l'affichage des temps par semaine.
 */
const WeekViews = ({ tableRefForWeek }: any) => {
  //Translation
  const { t } = useTranslation();

  //Hook
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();
  const { lang } = useCurrentLangContext();

  //Store
  const { setPrintContent } = usePrintStore();
  const { startDate } = useDateStore();
  const { setView } = useViewStore();
  const { setTimeId } = editStore();
  const { dataWeek, dataDaysOffWeek, setWeekData, setDays7, timePotential } =
    useDataStore();

  const mondayOfCurrentWeek = getMonday(startDate);
  const { weekData, days7 } = generateWeekData(
    mondayOfCurrentWeek,
    dataWeek,
    dataDaysOffWeek,
    lang
  );

  useEffect(() => {
    setWeekData(weekData);
    setDays7(days7);
  }, [startDate]);

  const dailyTotals = calculateDailyTotals(weekData);
  const numberOfRows = dataWeek && dataWeek.length > 10 ? dataWeek.length : 10;
  const weeklyTimePotential = Object.values(timePotential);

  const printContent = () => {
    const printWindow = window.open("", "_blank");
    printWindow?.document.write(
      "<html><head><title>Imprimer</title></head><body>"
    );
    printWindow?.document.write(tableRefForWeek.current.outerHTML);
    printWindow?.document.write("</body></html>");
    printWindow?.document.close();
    printWindow?.print();
  };

  useEffect(() => {
    setPrintContent(printContent);
  }, [startDate]);

  return (
    <div
      className="grid grid-cols-7 w-[98%] 2xl:h-full h-[90%] overflow-y-scroll border-[1px] rounded-md mr-1"
      ref={tableRefForWeek}
    >
      {days7.map((day, colIndex) => (
        <div
          key={colIndex}
          className="sticky top-0 bg-[#cecece] 2xl:h-14 h-10 text-center flex justify-center items-center  border-[1px] z-30"
        >
          {day.dayOfWeek}
        </div>
      ))}
      {weekData.some((data: any) => data.length > 0) && (
        <>
          {dailyTotals.map((totalMinutes: any, colIndex: any) => (
            <div
              key={colIndex}
              className="flex flex-col bg-bc-lightGreen h-auto text-center border-[1px] text-xs pt-1 z-30"
            >
              <div>
                {!weekData[colIndex][0]?.JCP_LIBELLE && totalMinutes !== 0 ? (
                  <>
                    {t("Total")} : {Math.floor(totalMinutes / 60)}h
                    {String(totalMinutes % 60).padStart(2, "0")}min
                  </>
                ) : null}
              </div>
              <>
                {weeklyTimePotential[colIndex] !== 0 ? (
                  <> Temps potentiel : {weeklyTimePotential[colIndex]}:00min</>
                ) : null}
              </>
            </div>
          ))}
        </>
      )}

      {numberOfRows &&
        Array.from({ length: numberOfRows }).map((_, rowIndex) =>
          weekData.map((dayData: any, colIndex: any) => {
            const item = dayData[rowIndex];
            const hasJcpLibelle = item && item.JCP_LIBELLE;
            const itemClass = hasJcpLibelle
              ? "border-[1px] bg-gray-100 h-full relative z-10"
              : "border-[1px] h-28 relative z-10";

            return (
              <div
                key={colIndex}
                className={itemClass}
                style={{
                  backgroundColor: item
                    ? delphi2html_color(item?.AA_ANA_RGBCOLOR)
                    : "white",
                }}
                onMouseEnter={(e) =>
                  handleMouseEnter(item?.AA_ANA_COMPLEMENTLIB, e)
                }
                onMouseLeave={handleMouseLeave}
              >
                {item && (
                  <button
                    className={`flex flex-col items-center w-full h-full `}
                    onClick={() => {
                      setTimeId(item?.AA_REF);
                      setView("edit");
                    }}
                  >
                    <div
                      className={`flex flex-col items-center justify-center `}
                    >
                      <p className="font-bold text-xs text-center pt-2">
                        {item?.TD_FIC_TITRE_CLIENT}
                      </p>
                      <p className="font-thin text-xs pt-1">
                        {item?.AO_AOR_LIBELLE}
                      </p>
                      <p className="font-thin text-xs hidden xl:flex">
                        {item?.AA_ANA_LIBELLELIB}
                      </p>
                      <p className="font-thin text-xs ">
                        {item?.VM_MIS_LIBELLE
                          ? deleteParentheses(item.VM_MIS_LIBELLE)
                          : ""}
                      </p>

                      {hasJcpLibelle && (
                        <div className="flex flex-col justify-center items-center">
                          <p className="font-bold text-xs">
                            {item?.JCP_LIBELLE}
                          </p>
                          <div className="w-full flex justify-center items-end h-5 absolute bottom-0">
                            <p className="font-bold text-xs ">
                              <span className="font-bold pt-2 pr-2">
                                {item?.TIME_DIFF === "0.00"
                                  ? "Jour férié"
                                  : "Total : " +
                                    convertToHoursAndMinutes(item?.TIME_DIFF)}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      )}
                      {!hasJcpLibelle && (
                        <div className="w-full flex justify-center items-end h-5 absolute bottom-0">
                          <p className="font-thin text-sm ">
                            {t("Total")} :{" "}
                            <span className="font-bold pt-2 pr-2">
                              {convertToHoursAndMinutes(item?.AA_ANA_TEMPS)}
                            </span>{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </button>
                )}
              </div>
            );
          })
        )}
      {tooltipState.content && (
        <div
          className="tooltip bg-bc-darkGrey text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[400px] h-auto"
          style={{
            top: tooltipState.position.y,
            left: tooltipState.position.x,
          }}
        >
          {tooltipState.content}
        </div>
      )}
    </div>
  );
};

export default WeekViews;
