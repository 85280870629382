import { create } from 'zustand';

interface MyState {
    processus: any[];
    setProcessus: (processus: any[]) => void;

    selectedProcessus: any;
    setSelectedProcessus: (processus: any) => void;

    files: any[];
    setFiles: (files: any[]) => void;
    
    selectedFile: any;
    setSelectedFile: (file: any) => void;

    updateData: any;
    setUpdateData: (updateData: any) => void;
}

export const createBillableItemsStore = create<MyState>((set) => ({
    processus: [],
    setProcessus: (processus) => set({ processus }),

    selectedProcessus: 0,
    setSelectedProcessus: (selectedProcessus) => set({ selectedProcessus }),

    files: [],
    setFiles: (files) => set({ files }),

    selectedFile: 0,
    setSelectedFile: (selectedFile) => set({ selectedFile }),

    updateData: {},
    setUpdateData: (updateData) => set({ updateData }),
}));