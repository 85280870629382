/**
 * 
 * @param d * @function getWeekNumber
 * @author Succi Iris
 * @param {Date} d - The Date object for which the week number is to be calculated.
 * @returns {number} The week number of the year for the given date.
 * @description Calculates the ISO week number of the year for a given date.
 */
export const getWeekNumber = (d: Date): number => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  return weekNo;
}

/** 
 * @function getMonthName
 * @author Succi Iris
 * @param {Date} date - The Date object from which the month name is to be retrieved.
 * @returns {string} The name of the month in French corresponding to the given date.
 * @description Retrieves the French name of the month from a Date object.
 */ 
export const getMonthName = (date: Date) => {
  const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  return monthNames[date.getMonth()];
}

/**
 * @function convertToServerDateFormat
 * @author Succi Iris
 * @param {Object} exerciceMois - The date object containing the month and year to be formatted.
 * @returns {string} The date formatted in the "YYYY-MM" format.
 * @description Converts a human-readable date format to a server-compatible date format.
 */
export const convertToServerDateFormat = (exerciceMois: any) => {
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Extrait l'année et le mois
  const [mois, annee] = exerciceMois.mois.split(" ");
  const moisIndex = monthNames.indexOf(mois.toLowerCase());

  // Construit la date au format YYYY-MM
  const date = `${annee}-${String(moisIndex + 1).padStart(2, "0")}`;

  return date;
}

/**
 * @author Succi Iris
 * @function calculateTVA
 * @param {number} newHt - The base price (HT) on which the TVA is calculated.
 * @param {number} selectedTvaRate - The selection index for the TVA rate.
 * @returns {{newTva: number, newTtc: number}} An object containing the calculated TVA (`newTva`) and total cost including TVA (`newTtc`).
 * @description Calculates the TVA and total cost including TVA for a given base price and TVA rate selection.
 * @since 2023
 */
export const calculateTVA = (ht: number, selectedTvaRate: any, quantity: any) => {
  let tvaRate = 0;
  if (selectedTvaRate === 3) {
    tvaRate = 0;
  } else if (selectedTvaRate === 2) {
    tvaRate = 20;
  }

  const newTva = Number(ht) * (tvaRate / 100);
  const newTtc = Number(ht) + Number(newTva);
  const newUnitPrice = quantity > 0 ? ht / quantity : 0;

  return {
    newTva: !isNaN(newTva) ? Number(newTva.toFixed(2)) : 0,
    newTtc: !isNaN(newTtc) ? Number(newTtc) : 0,
    newUnitPrice: !isNaN(newUnitPrice) ? Number(newUnitPrice.toFixed(2)) : 0
  };
};

export const calculateTVAWithoutQuantity = (ht: number, selectedTvaRate: any) => {
  let tvaRate = 0;
  if (selectedTvaRate === 3) {
    tvaRate = 0;
  } else if (selectedTvaRate === 2) {
    tvaRate = 20;
  }

  const newTva = ht * (tvaRate / 100);
  const newTtc = ht + newTva;

  return {
    newTva: !isNaN(newTva) ? Number(newTva.toFixed(2)) : 0,
    newTtc: !isNaN(newTtc) ? Number(newTtc) : 0,
  };
};

/**
 * @author Succi Iris
 * @function calculateHTFromTTC
 * @param {number} ttc - The base price (HT) on which the TVA is calculated.
 * @param {number} selectedTvaRate - The selection index for the TVA rate.
 * @returns {{newTva: number, newTtc: number}} An object containing the calculated TVA (`newTva`) and total cost including TVA (`newTtc`).
 * @description Calculates the TVA and total cost including TVA for a given base price and TVA rate selection.
 * @since 2024
 */
export const calculateHtFromTtc = (ttc: number, selectedTvaRate: any, quantity: any) => {
  let tvaRate = 0;
  if (selectedTvaRate === 3) {
    tvaRate = 0;
  } else if (selectedTvaRate === 2) {
    tvaRate = 20;
  }
  
  const newHt = ttc / (1 + tvaRate / 100);
  const newTva = ttc - newHt;
  const newUnitPrice = Number(quantity) > 0 ? newHt / Number(quantity) : 0;

  return {
    newHt: !isNaN(newHt) ? Number(newHt.toFixed(2)) : 0,
    newTva: !isNaN(newTva) ? Number(newTva.toFixed(2)) : 0,
    newUnitPrice: !isNaN(newUnitPrice) ? Number(newUnitPrice.toFixed(2)) : 0
  };
};

export const calculateHtFromTtcWithoutQuantity = (ttc: number, selectedTvaRate: any) => {
  let tvaRate = 0;
  if (selectedTvaRate === 3) {
    tvaRate = 0;
  } else if (selectedTvaRate === 2) {
    tvaRate = 20;
  }

  const newHt = ttc / (1 + tvaRate / 100);
  const newTva = ttc - newHt;

  return {
    newHt: !isNaN(newHt) ? Number(newHt.toFixed(2)) : 0,
    newTva: !isNaN(newTva) ? Number(newTva.toFixed(2)) : 0,
  };
};

/**
 * Formats a number to a string with two decimal places, using a comma as the decimal separator.
 * Thousands are separated by spaces. If the input is not a number or is falsy, it returns '0,00'.
 * @author Succi Iris
 * @function formatNumber
 * @since 2024
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number as a string. If `num` is falsy, returns '0,00'.
 * Example: 123456.78 becomes '123 456,78', and 0 becomes '0,00'.
 */
export const formatNumber = (num: number) => {
  return num ? num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') : '0,00';
}