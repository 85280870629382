import ClientFileSelect from "../select/ClientFileSelect";
import UserSelect from "../select/UserSelect";
import GroupeSelect from "../select/UserSelect";
import DateExercice from "../select/ExerciceSelect";
import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";
import {
  getPdfProductivity,
  getStateComparedBudgets,
} from "../../../utils/FinancialStatement/state";
import { useDateExerciceStore } from "../../../store/FinancialStatement/dateExerciceStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { convertToServerDateFormat } from "../../../utils/functionService";
import { errorFetch } from "../../../utils/toaster";
import { useRightStateContext } from "../../../context/RightStateContext";

/**
 * @component
 * @name Production
 * @description
 * A component that generates a PDF report for various production-related activities.
 * The supported billing types are:
 *
 * - **Productivité:** Displays a summary of the company's productivity.
 * - **Budgets comparés:** Compares the company's actual budget against the planned budget.
 * - **Productivité : détails d'un client:** Provides detailed productivity information for a specific client.
 * - **Feuille des temps collab/client:** Generates a timesheet for a specific collaborator or client.
 * - **Comparatif activité des collabs:** Compares the activity of different collaborators.
 *
 * The component also allows users to filter the report by date range and
 * select the client dossier and classeur, collaborator, and group.
 * @author Iris Succi
 * @date 2023
 */
const Production = () => {
  const {
    productionTypes,
    productionTypeSelected,
    setProductionTypeSelected,
    setDocumentUrl,
    increment,
  } = useTabsFSStore();
  const { du, au } = useDateExerciceStore();
  const { setIsLoading } = useLoadingStore();

  //Context
  const { productionStateRight } = useRightStateContext();

  /**
   * Converts the `du` and `au` dates to server format.
   *
   * @param {Date} du - The starting date
   * @param {Date} au - The ending date
   * @returns {string} - The formatted ending date
   * @author Iris Succi
   * @date 2023
   */
  const startDate = convertToServerDateFormat(du) + "-01";
  const endDate = new Date(convertToServerDateFormat(au));
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);
  const formattedEndDate = `${endDate.getFullYear()}-${String(
    endDate.getMonth() + 1
  ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

  /**
   * Generates the PDF report for the selected production type.
   *
   * @param {object} du - The starting date
   * @param {object} startDate - The formatted starting date
   * @param {object} formattedEndDate - The formatted ending date
   * @param {string} productionTypeSelected - The selected production type
   * @author Iris Succi
   * @date 2023
   */
  const handlePdfProduction = () => {
    setIsLoading(true);
    increment();
    if (productionTypeSelected === "Productivité") {
      getPdfProductivity(startDate, formattedEndDate)
        .then((res) => {
          // Create a URL from the blob
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err)
        });
    }
    if (productionTypeSelected === "Budgets comparés") {
      getStateComparedBudgets(du.aex_ref)
        .then((res) => {
          // Create a URL from the blob
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }
  };

  return (
    <div className="w-[540px] h-[80%] border-[1px] ">
      {productionStateRight === 1 ? 
      <>
        <div className="w-11/12 m-auto flex flex-col justify-start items-start">
          <p className="mt-5 font-semibold">Type d'export :</p>
          <select
            name="type"
            id="type"
            className="border-[1px] h-9 rounded-sm mt-2 p-1 font-thin w-52"
            onChange={(e) => setProductionTypeSelected(e.target.value)}
            >
            {productionTypes.map((data) => (
              <option value={data}>{data}</option>
              ))}
          </select>

          <p className="mt-10 font-semibold">Du :</p>
          <div className="w-full flex justify-between items-center">
            <DateExercice type="du" />
          </div>
          <p className="mt-5 font-semibold">Au :</p>
          <div className="w-full flex justify-between items-center ">
            <DateExercice type="au" />
          </div>
          {productionTypeSelected === "Productivité : détails d'un client" ? (
            <>
              <p className="mt-10 font-semibold">Dossier :</p>
              <ClientFileSelect />
            </>
          ) : null}

          {productionTypeSelected === "Feuille des temps collab/client" ? (
            <>
              <p className="mt-10 font-semibold mb-2">Collaborateur :</p>
              <UserSelect />
            </>
          ) : null}

          {productionTypeSelected === "Comparatif activité des collabs" ? (
            <>
              <p className="mt-10 font-semibold mb-2">Groupe :</p>
              <GroupeSelect />
            </>
          ) : null}
        </div>
        <div className="w-full flex justify-center items-center mt-10">
          <button
            onClick={handlePdfProduction}
            className="w-40 h-8 border-[1px] rounded-sm border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
            >
            Générer le rapport
          </button>
        </div> 
      </>
      : <div className="w-11/12 m-auto">
          <p className="italic text-center mt-4">Vous n'avez pas les droits pour accéder à cette fonctionnalité</p>
        </div> 
      }
    </div>
  );
};

export default Production;
