import axios from "axios";
import { axiosWithToken } from "../axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get time/entry
 */

export const getMissions = (id: string, DOS_REF : number) => {
    try {
        const response = axiosWithToken().get(`api/time/entry/missions?identifiant=${id}&DOS_REF=${DOS_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getOrigins = (DOS_REF : number, MIS_REF : number) => {
    try {
        const response = axiosWithToken().get(`api/time/entry/origin?DOS_REF=${DOS_REF}&MIS_REF=${MIS_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getTauxHoraire = (DOS_REF: number , MIS_REF: number, ALI_CODE: string, UTI_CODE: string ) => {
    try {
        const response = axiosWithToken().get(`api/time/entry/tauxhoraire?DOS_REF=${DOS_REF}&MIS_REF=${MIS_REF}&ALI_CODE=${ALI_CODE}&UTI_CODE=${UTI_CODE}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}