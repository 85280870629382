import {create} from 'zustand';

type SearchBarStore = {
    search: string;
    setSearch: (search: string) => void;
}

export const useSearchBarStore = create<SearchBarStore>((set) => ({
    search: '',
    setSearch: (search: string) => set({search}),
}));
