import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";
/**
 * @component
 * @name YearSelect
 * @description
 * A component that renders a select dropdown for selecting a year.
 * The component retrieves the list of all available years from the API.
 * @author Iris Succi
 * @date 2023
 */
const YearSelect = () => {
  const { years, setSelectedYear } = useClientFileStore();
  return (
    <select
      className="w-1/2 h-9 border-[1px] rounded-sm"
      value={years}
      onChange={(e) => setSelectedYear(Number(e.target.value))}
    >
      <option value=""></option>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearSelect;
