import axios from "axios";
import { axiosWithToken } from "../axiosService";
import { errorTooMuchTime } from "../toaster";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / patch / delete time
 */

export const getByWeek = (identifiant: string, date: any) => {
    try {
        const response = axiosWithToken().get(`api/time/byweek?identifiant=${identifiant}&date=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getByDay = (identifiant: string, date: string) => {
    try {
        const response = axiosWithToken().get(`api/time/byday?identifiant=${identifiant}&date=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getByMonth = (identifiant: string, date: string) => {
    try {
        const response = axiosWithToken().get(`api/time/bymonth?identifiant=${identifiant}&date=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getByInterval = (identifiant: string | string[], startdate: string, enddate: string) => {
    try {
        const response = axiosWithToken().get(`api/time/forinterval?identifiant=${identifiant}&startDate=${startdate}&endDate=${enddate}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getDaysOffWeek = (identifiant: string, startdate: string,) => {
    try {
        const response = axiosWithToken().get(`api/time/daysoff/byweek?identifiant=${identifiant}&date=${startdate}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getWeekSummary = (identifiant: string, startdate: string) => {
    try {
        const response = axiosWithToken().get(`api/time/weeksummary?identifiant=${identifiant}&date=${startdate}`)
        console.log(response)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const postNewTime = (data: Array<{
    DOS_REF: number,
    MIS_REF: number | null,
    ANA_TEMPS: number,
    ANA_OPERATEUR: string,
    ANA_REDACTEUR: string,
    ANA_DATEREALISATION: string | null,
    AOR_REF: number,
    ANA_CODELIB: string,
    ANA_EPRIXUNITAIRE: number,
    ANA_COMPLEMENTLIB: string | null,
    ANA_NOTES: string | null
}>) => {
    try {
        const response = axiosWithToken().post(`api/time/`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
                throw new Error("Error 403");
            } else {
                throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
        } else {
            throw error;
        }
    }
}

export const modificationTime = (data: Array<{
    ANA_CODELIB: number,
    ANA_LIBELLELIB: string,
    ANA_PRIXUNITAIRE: number,
    AOR_LIBELLE: string,
    AOR_REF: number,
    DOS_REF: number,
    DOS_TITRE: string,
    MIS_LIBELLE: string,
    MIS_REF: number,
    Times: Array<{
        time: number| string,
        validated:boolean,
    }>
}>) => {
    try {
        return axiosWithToken().post(`api/time/weeksummary`, data)
    }
    catch (error) {
        console.log(error)
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
                errorTooMuchTime()
            } else {
                throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
        } else {
            throw error;
        }
    }
}


export const deleteTimeOneByOne = (data: Array<{ ANA_REF: number }>) => {
    try {
      const anaRefs = data.map(item => item.ANA_REF).join(',');
  
      const response = axiosWithToken().delete(`api/time/`, { params: { anarefs: anaRefs } });
  
      return response;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.statusText || "Une erreur est survenue");
      }
      throw error;
    }
  }
  

export const updateTimeOrComment = (data: Array<{
    ANA_REF: number,
    ANA_COMPLEMENTLIB: string | null,
}>) => {
    try {
        const response = axiosWithToken().patch(`api/time`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getCSV = async (data: any) => {
    try {
      const response = await axiosWithToken().post('api/time/csv/forinterval', data, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      document.body.appendChild(link);
      link.click();
    
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.statusText || 'Une erreur est survenue');
      }
      throw error;
    }
  };

export const getExcel = async (data: any) => {
    try {
      const response = await axiosWithToken().post('api/time/csv/forinterval', data, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click();
      console.log("renvoi d un xlsx")
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.statusText || 'Une erreur est survenue');
      }
      throw error;
    }
  };

export const getPdf = async (data: any) => {
    try {
      const response = await axiosWithToken().post('api/time/csv/forinterval', data, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
      console.log("renvoi d un pdf")
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.statusText || 'Une erreur est survenue');
      }
      throw error;
    }
  };
  

export const getTimePotentialByWeek = (identifiant: string, startdate: string) => {
    try {
        const response = axiosWithToken().get(`api/time/potential/byweek?identifiant=${identifiant}&date=${startdate}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
        }
        throw error;
    }  
}

export const postTimeValidate = (data: Array<{
    ANA_REF: number,
    DOS_REF: number,
    UTI_CODE: string,
}>) => {
    try {
        const response = axiosWithToken().post(`api/time/validate`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
        }
        throw error;
    }  
}