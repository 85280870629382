import { useUtilsAnalyseStore } from "../../store/Analyse/utilsAnalyse";

const Select = () => {
  const { setChoiceTab } = useUtilsAnalyseStore();

  return (
    <select className="w-72 p-1 h-10 border-[1px] rounded-sm mt-5"
     onChange={
      (e) => setChoiceTab(e.target.value)
    }>
      <option value="Production">
        Production par responsable dossiers
      </option>
      <option value="Encaissement">
        Encaissement par responsable dossiers
      </option>
      <option value="Facturation">
        Facturation par responsable dossiers
      </option>
    </select>
  );
};

export default Select;
