import { create } from 'zustand';

interface PrintStore {
  printContent: (() => void) | null;
  setPrintContent: (func: (() => void) | null) => void;
}

export const usePrintStore = create<PrintStore>((set) => ({
  printContent: null,
  setPrintContent: (func) => set({ printContent: func }),
}));
