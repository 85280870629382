import { useEffect, useState } from "react";
import { useCostStore } from "../../store/Cost/Cost";
import chevron from "../../assets/icons/chevron-right.svg";
import { useCreateFeesOrInvoices } from "../../store/Invoice/buttonsStore";
import Select from "react-select";
import { createUpdateCostStore } from "../../store/Cost/createUpdateCost";
import Datepicker from "../Utils/dateManagement/DatePicker";
import { calculateHtFromTtc, calculateTVA } from "../../utils/functionService";
import { getAllUsers } from "../../utils/user";
import { useUserContext } from "../../context/UserContext";
import {
  getClientsByDosRef,
  getCost,
  getFolders,
  getMissionsByDosRef,
  getOriginsByMisRef,
  postCost,
  updateCost,
} from "../../utils/Cost/Cost";
import { getTVA } from "../../utils/Invoice/fees";
import { errorConfig } from "../../utils/toaster";
/**
 * @component
 * @name CreateCost
 * @description
 * CreateCost est le composant qui permet de créer ET modifier un frais.
 * @author Iris Succi
 * @date 2023
 */
const CreateCost = () => {
  const {
    displayCreateCost,
    setDisplayCreateCost,
    animationClass,
    setAnimationClass,
    selectedIdsCosts,
    setSelectedIdsCosts,
    modeCost,
    setModeCost,
  } = useCostStore();
  const { createCost, setCreateCost } = useCreateFeesOrInvoices();
  const {
    startDate,
    setStartDate,
    clients,
    setClients,
    fileOptions,
    setFileOptions,
    selectedClient,
    setSelectedClient,
    missions,
    setMissions,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    actors,
    setActors,
    selectedActor,
    setSelectedActor,
    unitPrice,
    setUnitPrice,
    quantity,
    setQuantity,
    ht,
    setHt,
    tva,
    setTva,
    ttc,
    setTtc,
    comments,
    setComments,
    tvaRate,
    setTvaRate,
    selectedTvaRate,
    setSelectedTvaRate,
    setDataCost,
    dataCost,
    updateDataCost,
    setUpdateDataCost,
  } = createUpdateCostStore();
  const { setSendCost } = useCostStore();
  const { id } = useUserContext();
  const [block, setBlock] = useState(false);

  /**
   * @function
   * @description This is for display or not the div for create cost
   * @author Succi Iris
   * @date 11/03/2024
   */
  useEffect(() => {
    if (createCost === true) {
      setDisplayCreateCost(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayCreateCost(false);
      }, 300);
    }
  }, [createCost]);

  const reinitializeFields = () => {
    setSelectedActor(id);
    setSelectedFile(0);
    setOrigins([]);
    setMissions([]);
    setClients([]);
    setSelectedLibelle("");
    setSelectedMission(0);
    setSelectedOrigin(0);
    setSelectedTvaRate(3);
    setDataCost("ANA_NOTES", "");
    setUnitPrice(0);
    setQuantity(1);
    setHt(0);
    setTva(0);
    setTvaRate([]);
    setBlock(false);
    setTtc(0);
    setComments("");
    setDataCost("ANA_QUANTITE", 1);
    setDataCost("ANA_EPRIXUNITAIRE", 0);
    setDataCost("ANA_EMTHT", 0);
    setDataCost("ANA_EMTTVA", 0);
    setDataCost("ANA_EMTTTC", 0);
    setDataCost("ANA_NOTES", "");
    setDataCost("ANA_REDACTEUR", id);
    setDataCost("AOR_REF", 0);
    setDataCost("DOS_REF", 0);
    setDataCost("MIS_REF", 0);
    setDataCost("TVA_CLE", 3);
    setDataCost("FIC_REF", 0);
    setDataCost("ANA_CODELIB", "");
  };

  /**
   * @function
   * @description Call API pour récupérer les dossiers , les utilisateurs et la TVA lors de la création d'un frais
   * @author Succi Iris
   * @date 11/03/2024
   */
  useEffect(() => {
    if (createCost) {
      getFolders()
        .then((res) => {
          const formattedFiles = res.data.map((file: any) => ({
            value: file.DOS_REF,
            label: file.DOS_CODE + "-" + file.FIC_TITRE_CLIENT,
          }));
          setFileOptions(formattedFiles);

          const dosRef = JSON.parse(localStorage.getItem("dosRefCost") || "0");
          setSelectedFile(dosRef);
        })
        .catch((err) => console.log(err));
      getAllUsers()
        .then((res) => {
          setSelectedActor(id);
          setDataCost("ANA_REDACTEUR", id);
          const formattedFiles = res.data.map((actor: any) => ({
            value: actor.identifiant,
            label: actor.lastname + " " + actor.firstname,
          }));
          setActors(formattedFiles);
        })
        .catch((err) => console.log(err));
      getTVA()
        .then((res) => {
          const formattedFiles = res.data.map((tva: any) => ({
            value: tva.TVA_CLE,
            label: tva.TVA_VALEUR,
            DTVA: tva.TVA_DTVA,
          }));
          setTvaRate(formattedFiles);
        })
        .catch((err) => console.log(err));
    }
  }, [createCost]);

  /**
   * @function
   * @description Call API pour récupérer les clients et les missions lors de la sélection d'un dossier dans la création d'un frais
   * @author Succi Iris
   * @date 11/03/2024
   */
  useEffect(() => {
    if (selectedFile !== 0) {
      getClientsByDosRef(selectedFile)
        .then((res) => {
          const formattedClients = res.data.map((client: any) => ({
            value: client.FIC_REF,
            label: client.FIC_TITRE_ETAT,
            origin: client.FIC_OASPE_TP,
            tvaCle: client.TVA_CLE,
          }));
          setClients(formattedClients);
          if (selectedFile !== 0) {
            setSelectedClient(formattedClients[0]?.value || 0);
            setSelectedTvaRate(formattedClients[0]?.tvaCle || 0);
            setDataCost("FIC_REF", formattedClients[0]?.value || 0);
            setDataCost("TVA_CLE", formattedClients[0]?.tvaCle || 0);
          }
        })
        .catch((err) => console.log(err));

      getMissionsByDosRef(selectedFile)
        .then((res) => {
          const formattedMissions = res.data.map((mission: any) => {
            let label = mission.MIS_LIBELLE;
            // Retire tout ce qui est entre parenthèses, y compris les parenthèses elles-mêmes
            label = label.replace(/\s*\(.*?\)\s*/g, "");
            if (mission.MIS_REF !== null && mission.MIS_DEBUT) {
              label =
                new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
                " - " +
                label;
            }
            return {
              value: mission.MIS_REF,
              label: label,
            };
          });
          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));
    } else {
      reinitializeFields();
    }
  }, [selectedFile]);

  /**
   * @function
   * @description Call API forpour récupérer les origines lors de la sélection d'une mission dans la création d'un frais
   * @author Succi Iris
   * @date 11/03/2024
   */
  useEffect(() => {
    if (selectedMission !== 0) {
      getOriginsByMisRef(selectedMission, id, selectedClient)
        .then((res) => {
          const formattedOrigins = res.data.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_LIBELLE,
            AOR_OPTION_VALORISABLE: origin.AOR_OPTION_VALORISABLE,
            libelles: origin.Libelles.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              quantity: libelle.ALI_QUANTITE,
              note: libelle.ALI_NOTES,
              unitPrice: libelle.ALI_EPRIX_UNITAIRE,
              ALI_OPTION_SAISIE: libelle.ALI_OPTION_SAISIE,
            })),
          }));
          setOrigins(formattedOrigins);
        })
        .catch((err) => {
          console.log(err);
          errorConfig();
        });
    } else {
      setOrigins([]);
      setSelectedOrigin(0);
      setLibelles([]);
      setSelectedLibelle("");
    }
  }, [selectedMission]);

  // Reset les donnees quand la mission change
  useEffect(() => {
    setOrigins([]);
    setSelectedOrigin(0);
    setLibelles([]);
    setSelectedLibelle("");
  }, [selectedMission]);

  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    setDataCost("AOR_REF", selectedOrigin);

    const selectedOriginObj = origins?.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }

    if (selectedOriginObj?.AOR_OPTION_VALORISABLE === 0) {
      setUnitPrice(0);
      setQuantity(1);
      setHt(0);
      setTva(0);
      setTtc(0);
      setDataCost("ANA_QUANTITE", 1);
      setDataCost("ANA_EPRIXUNITAIRE", 0);
      setDataCost("ANA_EMTHT", 0);
      setDataCost("ANA_EMTTVA", 0);
      setDataCost("ANA_EMTTTC", 0);
      setBlock(true);
    }
  };

  const handleLibelleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLibelle = event.target.value;
    setSelectedLibelle(selectedLibelle);
    setDataCost("ANA_CODELIB", selectedLibelle);
    const selectedLibelleObj = libelles.find(
      (libelle) => libelle.value.toString() === selectedLibelle
    );
    if (selectedLibelleObj && modeCost === "create") {
      setUnitPrice(
        selectedLibelleObj.unitPrice !== null ? selectedLibelleObj.unitPrice : 0
      );
      setQuantity(
        selectedLibelleObj.quantity !== null ? selectedLibelleObj.quantity : 1
      );
      setComments(selectedLibelleObj.note);
      setDataCost("ANA_EPRIXUNITAIRE", selectedLibelleObj.unitPrice);
      setDataCost(
        "ANA_QUANTITE",
        selectedLibelleObj.quantity === null
          ? quantity
          : selectedLibelleObj.quantity
      );
      setDataCost("ANA_NOTES", selectedLibelleObj.note);
    }

    if (
      selectedLibelleObj.ALI_OPTION_SAISIE !== 1 ||
      selectedLibelleObj.ALI_OPTION_SAISIE !== 2 ||
      selectedLibelleObj !== 3
    ) {
      tvaRate?.map((tva) => {
        if (tva.DTVA === true) {
          setSelectedTvaRate(tva.value);
        }
      });
    } else {
      setSelectedTvaRate(3);
    }
  };

  // Post cost
  const handlePostCost = () => {
    // Sert a sauvegarder le DOS_REF dans le local storage
    localStorage.setItem("dosRefCost", JSON.stringify(selectedFile));

    setDataCost("ANA_DATEREALISATION", startDate);
    setDataCost("ANA_DATEECHEANCE", startDate);
    setDisplayCreateCost(false);
    setCreateCost(false);
    postCost(dataCost)
      .then(() => {
        setModeCost("create");
        setSelectedIdsCosts((_prev) => []);
        reinitializeFields();
        setSendCost((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  // Calcule HT, TVA et TTC lors de la saisie des champs
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "ht") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setTtc(0);
        setDataCost("ANA_EMTTVA", 0);
        setDataCost("ANA_EMTTTC", 0);
      } else {
        const { newTva, newTtc } = calculateTVA(
          fieldValue,
          selectedTvaRate,
          quantity
        );
        setTva(newTva.toFixed(2));
        setTtc(newTtc.toFixed(2));
        setDataCost("ANA_EMTTVA", newTva.toFixed(2));
        setDataCost("ANA_EMTTTC", newTtc.toFixed(2));
        setUnitPrice(fieldValue);
        setDataCost("ANA_EPRIXUNITAIRE", fieldValue);
      }
    } else if (name === "ttc") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setHt(0);
        setDataCost("ANA_EMTHT", 0);
        setDataCost("ANA_EMTTVA", 0);
        setTtc(0);
        setDataCost("ANA_EMTTTC", 0);
        setUnitPrice(0);
        setDataCost("ANA_EPRIXUNITAIRE", 0);
      } else {
        const { newTva, newHt, newUnitPrice } = calculateHtFromTtc(
          fieldValue,
          selectedTvaRate,
          quantity
        );
        setTva(newTva.toFixed(2));
        setHt(newHt.toFixed(2));
        setUnitPrice(newUnitPrice.toFixed(2));
        setDataCost("ANA_EMTTVA", newTva.toFixed(2));
        setDataCost("ANA_EMTHT", newHt.toFixed(2));
        setDataCost("ANA_EPRIXUNITAIRE", newUnitPrice.toFixed(2));
      }
    } else if (name === "txTva") {
      const fieldValue = parseFloat(value);
      setSelectedTvaRate(fieldValue);
      setDataCost("TVA_CLE", fieldValue);
    } else if (name === "unitPrice") {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue !== "" && quantity !== "") {
        const newTtc = quantity * fieldValue;
        setTtc(newTtc.toFixed(2));
        setHt(fieldValue);
        setDataCost("ANA_EMTTTC", newTtc.toFixed(2));
        setDataCost("ANA_EMTHT", fieldValue);
      }
    } else if (name === "tva") {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue !== "" && quantity !== "") {
        const newTtc = ht + fieldValue;
        setTtc(newTtc.toFixed(2));
        setDataCost("ANA_EMTTTC", newTtc.toFixed(2));
        setTva(fieldValue);
        setDataCost("ANA_EMTTVA", fieldValue);
      }
    }
  };

  // Calcul HT, TVA et TTC quand la quantité ou le prix unitaire change
  useEffect(() => {
    if (quantity > 0 && unitPrice > 0) {
      const newHt = quantity * unitPrice;
      const { newTva, newTtc } = calculateTVA(newHt, selectedTvaRate, quantity);
      setHt(newHt);
      setTva(newTva.toFixed(2));
      setTtc(newTtc);
      setDataCost("ANA_EMTTVA", newTva.toFixed(2));
      setDataCost("ANA_EMTTTC", newTtc);
      setDataCost("ANA_EMTHT", newHt);
    }
  }, [quantity, unitPrice, selectedTvaRate, selectedLibelle]);

  /********************************EDIT*************************************/
  /**
   * @function
   * @name initialiseData
   * @description Call API lorsque le mode est en mode edit pour récupérer les données du frais sélectionné pour la modification
   * @author Succi Iris
   * @date 13/03/2024
   */
  useEffect(() => {
    const initialiseData = async () => {
      if (modeCost === "edit") {
        try {
          const res = await getCost(selectedIdsCosts[0]);
          setUpdateDataCost(res.data);

          setQuantity(updateDataCost?.ANA_QUANTITE);
          setHt(updateDataCost?.ANA_EMTHT);
          setTva(updateDataCost?.ANA_EMTTVA);
          setComments(updateDataCost?.ANA_NOTES);
          setTtc(updateDataCost?.ANA_EMTTTC);
          setUnitPrice(updateDataCost?.ANA_EPRIXUNITAIRE);

          const selectedFileObj = fileOptions?.find(
            (file) => file?.value === updateDataCost?.DOS_REF
          );
          setSelectedFile(selectedFileObj?.value || 0);

          const selectedClientObj = clients?.find(
            (client) => client?.value === updateDataCost?.FIC_REF
          );
          setSelectedClient(selectedClientObj?.value || 0);

          const selectedTvaRateObj = tvaRate?.find(
            (tva) => tva?.value === updateDataCost?.TVA_CLE
          );
          setSelectedTvaRate(selectedTvaRateObj?.value || 2);
        } catch (err) {
          console.log(err);
        }
      }
    };
    initialiseData();
  }, [selectedIdsCosts, fileOptions]);

  useEffect(() => {
    if (modeCost === "edit") {
      if (selectedFile !== 0 && missions?.length !== 0) {
        const selectedMissionObj = missions?.find(
          (mission) => mission?.value === updateDataCost?.MIS_REF
        );
        setSelectedMission(selectedMissionObj?.value || 0);
      }
    }
  }, [missions]);

  useEffect(() => {
    if (modeCost === "edit") {
      const updateLibellesBasedOnOrigin = () => {
        const selectedOriginObj = origins?.find((origin) => {
          return origin?.value === updateDataCost?.AOR_REF;
        });

        if (selectedOriginObj) {
          setSelectedOrigin(selectedOriginObj.value);
          setLibelles(selectedOriginObj.libelles || []);
          const defaultLibelle = selectedOriginObj.libelles?.[0]?.value || "";
          setSelectedLibelle(defaultLibelle);
          setDataCost("ANA_CODELIB", defaultLibelle);
        }
      };

      updateLibellesBasedOnOrigin();
    }
  }, [origins]);

  // Update un frais séléctionné
  const handleUpdateCost = () => {
    const updateData = {
      ANA_CODELIB: selectedLibelle,
      ANA_DATEREALISATION: startDate,
      ANA_DATEECHEANCE: startDate,
      ANA_EPRIXUNITAIRE: unitPrice,
      ANA_QUANTITE: quantity,
      ANA_EMTHT: ht,
      ANA_EMTTVA: tva,
      ANA_EMTTTC: ttc,
      ANA_NOTES: comments === null ? "" : comments,
      ANA_REDACTEUR: id,
      AOR_REF: selectedOrigin,
      DOS_REF: selectedFile,
      MIS_REF: selectedMission,
      TVA_CLE: selectedTvaRate,
      FIC_REF: selectedClient,
    };
    updateCost(selectedIdsCosts[0], updateData)
      .then(() => {
        setModeCost("create");
        setSelectedIdsCosts((_prev) => []);
        setDisplayCreateCost(false);
        reinitializeFields();
        setSendCost((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {displayCreateCost ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full">
            <button
              className="h-full w-10 bg-gray-600 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsCosts((_prev) => []);
                setCreateCost(false);
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[80%] h-full m-auto">
              <p className="font-bold text-lg my-5 text-center w-full">
                {modeCost === "create"
                  ? "Création d'un frais"
                  : "Modification d'un frais"}
              </p>
              <div className="grid grid-cols-7 w-full gap-10 my-20 ">
                <div className="col-start-1 col-end-2 mt-1">Date :</div>
                <div className="flex flex-col col-start-2 col-end-4 ">
                  <Datepicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
                <div className="col-start-5 col-end-6 mt-1">Acteur :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="acteur"
                    id="acteur"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedActor}
                    onChange={(event) => {
                      setSelectedActor(event.target.value);
                      setDataCost("ANA_REDACTEUR", event.target.value);
                    }}
                  >
                    <option value=""></option>
                    {actors?.map((actor, i) => (
                      <option key={i} value={actor?.value}>
                        {actor?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-1 col-end-2 mt-1">Dossier :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  {modeCost === "edit" ? (
                    <Select
                      options={fileOptions}
                      isClearable
                      className="w-full h-9 z-40 "
                      isSearchable
                      value={
                        modeCost === "edit"
                          ? fileOptions.find(
                              (option) => option.value === selectedFile
                            )
                          : null
                      }
                      onChange={(selectedFile: any | null) => {
                        setSelectedFile(selectedFile?.value || 0);
                        setDataCost("DOS_REF", selectedFile?.value || 0);
                      }}
                    />
                  ) : (
                    <Select
                      options={fileOptions}
                      isClearable
                      value={fileOptions.find(
                        (option) => option.value === selectedFile
                      )}
                      className="w-full h-9 z-40 "
                      isSearchable
                      onChange={(selectedFile: any | null) => {
                        setSelectedFile(selectedFile?.value || 0);
                        setDataCost("DOS_REF", selectedFile?.value || 0);
                      }}
                    />
                  )}
                </div>

                <div className="col-start-5 col-end-6 mt-1">Client :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  {modeCost === "edit" ? (
                    <Select
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={clients}
                      onChange={(selectedClient: any | null) => {
                        setSelectedClient(selectedClient?.value || 0);
                        setDataCost("FIC_REF", selectedClient?.value || 0);
                      }}
                    />
                  ) : (
                    <Select
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={clients}
                      onChange={(selectedClient: any | null) => {
                        setSelectedClient(selectedClient?.value || 0);
                        setSelectedOrigin(selectedClient?.origin || 0);
                        setDataCost("FIC_REF", selectedClient?.value || 0);
                      }}
                    />
                  )}
                </div>
                <div className="col-start-1 col-end-2 mt-1">Mission :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="mission"
                    id="mission"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20"
                    value={selectedMission}
                    onChange={(event) => {
                      const selectedValue = Number(event.target.value);
                      setSelectedMission(selectedValue);
                      setDataCost("MIS_REF", selectedValue);
                    }}
                  >
                    <option value=""></option>
                    {missions?.map((mission, i) => (
                      <option key={i} value={mission?.value}>
                        {mission?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-1 col-end-2 mt-1">Origine :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="origin"
                    id="origin"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={handleOriginChange}
                  >
                    <option value=""></option>
                    {origins?.map((origin, i) => (
                      <option key={i} value={origin?.value}>
                        {origin?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">Libellé :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="libelle"
                    id="libelle"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedLibelle}
                    onChange={handleLibelleChange}
                  >
                    <option value=""></option>
                    {libelles?.map((libelle, i) => (
                      <option key={i} value={libelle?.value}>
                        {libelle?.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-start-1 col-end-2 mt-1">Commentaire:</div>
                <div className="flex flex-col col-start-2 col-end-6">
                  <textarea
                    name="commentaire"
                    id="commentaire"
                    className="w-full border-[1px] rounded border-gray-300 h-20 z-20 overflow-y-scroll p-1"
                    value={comments}
                    onChange={(event) => {
                      setComments(event.target.value);
                      setDataCost("ANA_NOTES", event.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              <div className="grid grid-cols-12 gap-1 w-full mb-20">
                <div className="col-start-1 col-end-2 mt-1 text-sm ">H.T :</div>
                <input
                  type="number"
                  name="ht"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  value={ht}
                  disabled={block}
                  onChange={(e) => {
                    setHt(parseInt(e.target.value));
                    setDataCost("ANA_EMTHT", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-3 col-end-4 mt-1 text-sm">
                  TX TVA :
                </div>
                <select
                  name="txTva"
                  id="txTva"
                  className="w-full text-sm border-[1px] rounded border-gray-300 h-8 z-20 p-1"
                  value={selectedTvaRate}
                  onChange={(e) => {
                    setSelectedTvaRate(Number(e.target.value));
                    setDataCost("TVA_CLE", Number(e.target.value));
                  }}
                >
                  <option value={""}></option>
                  {tvaRate?.map((tvaRate, i) => (
                    <option key={i} value={tvaRate?.value}>
                      {tvaRate?.label} %
                    </option>
                  ))}
                </select>
                <div className="col-start-5 text-sm col-end-6 mt-1 ml-3">
                  TVA :
                </div>
                <input
                  type="number"
                  name="tva"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  value={tva}
                  disabled={block}
                  onChange={(e) => {
                    setTva(parseInt(e.target.value));
                    setDataCost("ANA_EMTTVA", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-7 col-end-8 text-sm mt-1 ml-4">
                  Qté :
                </div>
                <input
                  type="number"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  value={quantity}
                  disabled={block}
                  onChange={(e) => {
                    setQuantity(parseInt(e.target.value));
                    setDataCost("ANA_QUANTITE", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-9 col-end-10 text-sm mt-1 ml-4">
                  Px Unit:
                </div>
                <input
                  type="number"
                  name="unitPrice"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  value={unitPrice}
                  disabled={block}
                  onChange={(e) => {
                    setUnitPrice(parseInt(e.target.value));
                    setDataCost("ANA_EPRIXUNITAIRE", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-11 col-end-12 text-sm mt-1 ml-3">
                  T.T.C :
                </div>
                <input
                  type="number"
                  name="ttc"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  value={ttc}
                  disabled={block}
                  onChange={(e) => {
                    setTtc(parseInt(e.target.value));
                    setDataCost("ANA_EMTTTC", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />

                <button
                  className="w-full col-start-9 col-end-11 mt-4 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                  style={{
                    animation: animationClass,
                  }}
                  onClick={() => {
                    setCreateCost(false);
                    setDisplayCreateCost(false);
                    setModeCost("create");
                    setSelectedIdsCosts((_prev) => []);
                    localStorage.removeItem("dosRefCost");
                    reinitializeFields();
                  }}
                >
                  Annuler{" "}
                </button>
                <button
                  className="w-full col-start-11 col-end-13 mt-4 h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                  onClick={() =>
                    modeCost === "create"
                      ? handlePostCost()
                      : handleUpdateCost()
                  }
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateCost;
