import React, { useEffect, useRef, useState } from "react";

type Bubble = {
  x: number;
  y: number;
  size: number;
  popped: boolean;
  dx: number; // speed x
  dy: number; // speed y
};

const BubbleGame: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [bubbles, setBubbles] = useState<Bubble[]>([]);
  const [score, setScore] = useState<number>(0);
  const [timer, setTimer] = useState<number>(0);
  const [start, setStart] = useState<boolean>(false);

  const bubblesRef = useRef(bubbles); // nouvelle référence pour les bulles
  const scoreRef = useRef(score); // nouvelle référence pour le score
  const animationFrameIdRef = useRef<number | null>(null);

  const createBubbles = (count: number) => {
    const newBubbles = [];
    for (let i = 0; i < count; i++) {
      newBubbles.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 100,
        popped: false,
        dx: (Math.random() - 0.5) * 2, // vitesse aléatoire entre -1 et 1
        dy: (Math.random() - 0.5) * 2,
      });
    }
    setBubbles(newBubbles);
    bubblesRef.current = newBubbles;
  };

  const drawBubbles = (context: CanvasRenderingContext2D) => {
    context.clearRect(0, 0, window.innerWidth, window.innerHeight);
    context.strokeStyle = "orange";
    bubblesRef.current.forEach((bubble) => {
      if (!bubble.popped) {
        bubble.x += bubble.dx;
        bubble.y += bubble.dy;

        // inverser la direction si la bulle atteint le bord du canevas
        if (
          bubble.x + bubble.size > window.innerWidth ||
          bubble.x - bubble.size < 0
        ) {
          bubble.dx = -bubble.dx;
        }
        if (
          bubble.y + bubble.size > window.innerHeight ||
          bubble.y - bubble.size < 0
        ) {
          bubble.dy = -bubble.dy;
        }

        context.beginPath();
        context.arc(bubble.x, bubble.y, bubble.size, 0, Math.PI * 2, false);
        context.stroke();
      }
    });

      context.font = "100px Arial";
      context.fillStyle = "orange";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(
        "Page 404",
        window.innerWidth / 2,
        window.innerHeight / 2
      );
    
  };

  const checkBubblePop = (event: MouseEvent) => {
    let poppedBubble = false;

    if (!start) {
      setStart(true);
    }

    const newBubbles = bubbles.map((bubble) => {
      const distance = Math.hypot(
        bubble.x - event.clientX,
        bubble.y - event.clientY
      );
      if (distance - bubble.size < 1) {
        poppedBubble = true;
        return { ...bubble, popped: true };
      }
      return bubble;
    });

    setBubbles(newBubbles);
    bubblesRef.current = newBubbles; // mise à jour de la référence pour les bulles

    if (poppedBubble) {
      // seulement si une bulle a été éclatée
      setScore(score + 1);
      scoreRef.current = score + 1; // mise à jour de la référence pour le score
    }
  };

  useEffect(() => {
    let interval: any;

    if (start) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer >= 59) {
            // check for the game over condition
            clearInterval(interval); // stop the interval when game is over
            return 60;
          }
          return prevTimer + 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [start]);

  useEffect(() => {
    if (bubbles.length === 0) {
      createBubbles(100);
    }
  }, [bubbles.length]);

  useEffect(() => {
    bubblesRef.current = bubbles;

    const canvas = canvasRef.current!;
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const animate = () => {
      drawBubbles(context);
      animationFrameIdRef.current = requestAnimationFrame(animate);
    };
    animate();

    canvas.addEventListener("click", checkBubblePop);

    return () => {
      if (animationFrameIdRef.current !== null) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
      canvas.removeEventListener("click", checkBubblePop);
    };
  }, [bubbles, timer]);

  return (
    <div className="overflow-hidden">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default BubbleGame;
