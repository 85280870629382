import {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import i18n from "../translate/i18n";

const CurrentLangContext = createContext({
  lang: "FR",
  toggleLang: (_: string) => {},
});

export default CurrentLangContext;

interface CurrentLangContextProviderProps {
  children: ReactNode;
}

export function CurrentLangContextProvider({
  children,
}: CurrentLangContextProviderProps) {
  const [lang, setLang] = useState("FR");

  const toggleLang = (changeLang: string) => {
    setLang(changeLang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <CurrentLangContext.Provider value={{ lang, toggleLang }}>
      {children}
    </CurrentLangContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentLangContext = () => useContext(CurrentLangContext);
