import { useTabsFSStore } from "../../store/FinancialStatement/tabsStore";

/**
 * @component
 * @name Tabs
 * @description
 * A component that renders a set of tabs for selecting a report type.
 * @author Iris Succi
 * @date 2023
 */
const Tabs = () => {
  const { choiceTab, setChoiceTab } = useTabsFSStore();

  return (
    <div className="flex justify-start items-start border-[1px] rounded-t-md w-[390px] mt-5">
      <button
        className={
          choiceTab !== "Production"
            ? "border-r-[1px] rounded-tl-md px-4 py-1 w-[120px]"
            : "border-r-[1px] rounded-tl-md px-4 py-1 w-[120px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Production")}
      >
        Production
      </button>
      <button
        className={
          choiceTab !== "Facturation"
            ? "border-r-[1px] px-4 py-1 w-[120px]"
            : "border-r-[1px] px-4 py-1 w-[120px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Facturation")}
      >
        Facturation
      </button>
      <button
        className={
          choiceTab !== "Encaissement"
            ? "px-4 py-1 w-[150px] border-r-[1px]"
            : "px-4 py-1 w-[150px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Encaissement")}
      >
        Encaissement
      </button>
      {/* <button
        className={
          choiceTab !== "Plan de charge"
            ? "py-1 px-4 w-[150px]"
            : "py-1 px-5 w-[150px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Plan de charge")}
      >
        Plan de charge
      </button> */}
    </div>
  );
};

export default Tabs;
