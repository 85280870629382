import { useEffect } from "react";
import { useCreateCashInFlowStore } from "../../../../store/Invoice/createCashInFlowStore";
import { getInvoicesCheckablesForPrepayment } from "../../../../utils/customerFile";
import { dateFormattedFr } from "../../../../utils/TimeManager/timeUtils";
import { getInvoicesToAllocate } from "../../../../utils/Invoice/cashInFlow";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import { useLoadingStore } from "../../../../store/utils/loading";
/**
 * PointCashInFlow Component.
 *
 * This component displays a list of invoices that can be selected for cash inflow processing.
 * Users can select invoices, and the amount to be allocated to each invoice is calculated and displayed.
 *
 * @component
 *
 * @prop {Array} invoicesToAllocate - An array of objects representing the invoices available for cash inflow.
 * @prop {Function} setInvoicesToAllocate - Function to update the invoices available for cash inflow.
 * @prop {string} selectedFile - Identifier of the selected file, used to load corresponding invoices.
 * @prop {Array} selectedInvoicesToAllocate - An array containing the invoices selected for allocation.
 * @prop {number} montantRestant - The remaining amount to be allocated across the selected invoices.
 * @prop {Function} setMontantRestant - Function to update the remaining amount to be allocated.
 * @author Iris Succi
 * @date 2024
 * The component also includes the `handleInvoiceSelection` function to handle the logic of invoice selection and allocation calculation,
 * and the `isInvoiceSelected` function to check if an invoice is selected.
 */
const PointCashInFlow = () => {
  const {
    invoicesToAllocate,
    setInvoicesToAllocate,
    selectedFile,
    selectedInvoicesToAllocate,
    setSelectedInvoicesToAllocate,
    montantRestant,
    setMontantRestant,
    cashInFlowData,
    invoiceAlreadyDue,
    invoicesAllocated,
    setInvoicesAllocated,
    modeCashInFlow,
  } = useCreateCashInFlowStore();
  const { sendData } = useTabsStore();
  const { isLoading } = useLoadingStore();

  useEffect(() => {
    if (modeCashInFlow === "edit" && cashInFlowData?.ATR_REF) {
      getInvoicesToAllocate(cashInFlowData?.ATR_REF)
        .then((res: any) => {
          console.log(res.data)
          setInvoicesAllocated(res.data);
        })
        .catch((err: any) => console.log(err));
      }
    }, [sendData, selectedFile]);

  useEffect(() => {
    if (selectedFile) {
      getInvoicesCheckablesForPrepayment(selectedFile)
        .then((res: any) => {
          const fetchedInvoices = res.data;
          console.log(fetchedInvoices);
          
          let filteredInvoices = fetchedInvoices; // Initialisation avec toutes les factures récupérées
  
          // Exclut les factures déjà allouées si invoicesAllocated contient des éléments
          if (invoicesAllocated.length > 0) {
            filteredInvoices = fetchedInvoices.filter((invoice: any) =>
              !invoicesAllocated.find((allocatedInvoice: any) => allocatedInvoice.ANA_FACTURE === invoice.ANA_FACTURE)
            );
          }
  
          setInvoicesToAllocate(filteredInvoices);
  
          // Pré-coche les factures basées sur le tableau 'Pointé'
          const preCheckedInvoices = filteredInvoices.filter((invoice: any) =>
            invoiceAlreadyDue?.includes(invoice.ANA_FACTURE)
          );
          setSelectedInvoicesToAllocate(preCheckedInvoices);
        })
        .catch((err: any) => console.log(err));
    }
  }, [selectedFile, sendData, invoicesAllocated]); // Assurez-vous d'ajouter invoicesAllocated comme dépendance si son état peut changer
  

  /**
   * Handles the selection and deselection of invoices for cash inflow processing.
   *
   * This function calculates the amount to be settled for a selected invoice and updates the state of selected invoices.
   * If an invoice is already selected, it is deselected, and its settled amount is readjusted.
   * If an invoice is not yet selected, it is added to the list of selected invoices with the calculated amount.
   *
   * @param {Object} invoice - The invoice object to be selected or deselected.
   * @param {number} invoice.INITIAL - The initial amount of the invoice.
   * @param {number} invoice.Réglé - The amount already settled for the invoice.
   * @param {string} invoice.ANA_FACTURE - The unique identifier of the invoice.
   * @author Iris Succi
   * @date 2024
   * Updates 'selectedInvoicesToAllocate' with selected invoices and their updated settled amounts.
   * Also adjusts 'montantRestant' based on the selections and deselections of invoices.
   */
  const handleInvoiceSelection = (invoice: any) => {
    const montantARegler = Math.min(
      invoice.INITIAL - invoice.Réglé,
      montantRestant
    );

    const updatedInvoices = selectedInvoicesToAllocate.slice();
    const foundIndex = updatedInvoices.findIndex(
      (inv) => inv.ANA_FACTURE === invoice.ANA_FACTURE
    );

    if (foundIndex !== -1) {
      // la logique de désélection
      const montantDejaRegle = updatedInvoices[foundIndex].Réglé;
      updatedInvoices.splice(foundIndex, 1);
      setMontantRestant(montantRestant + montantDejaRegle);
    } else {
      // Si la facture n'est pas sélectionnée, l'ajoute avec le montant réglé mis à jour
      updatedInvoices.push({
        ...invoice,
        Réglé: invoice.Réglé + montantARegler,
        Solde: invoice.INITIAL - (invoice.Réglé + montantARegler),
        Saisie: montantARegler,
      });
      setMontantRestant(montantRestant - montantARegler);
    }

    setSelectedInvoicesToAllocate(updatedInvoices);
  };

  const isInvoiceSelected = (invoiceId: any) => {
    return selectedInvoicesToAllocate.some(
      (invoice) => invoice.ANA_FACTURE === invoiceId
    );
  };

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full  overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : (
        <div className="w-full border-[1px] rounded-sm 2xl:h-64 h-56 bg-white overflow-y-scroll">
          <table className="w-full bg-white">
            <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0 text-sm h-10">
              <tr className="w-[2%] border-r-[1px] flex justify-start items-center">
                {" "}
              </tr>
              <tr className="w-[10%] border-r-[1px] p-2">N</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Date</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Initial</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Réglé</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Solde</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Saisie</tr>
              <tr className="w-[10%] border-r-[1px] p-2">P</tr>
              <tr className="w-[18%] border-r-[1px] p-2">Titre dossier</tr>
              <tr className="w-[10%] border-r-[1px] p-2">Libellé</tr>
            </thead>
            <tbody className="flex flex-col w-full text-xs ">
              {modeCashInFlow === "edit"
                ? invoicesAllocated?.map((invoice: any) => {
                    return (
                      <tr className={`w-full border-b-[1px] flex`}>
                        <td className="w-[2%] border-r-[1px] flex justify-start items-start pt-2 ">
                          {/* <img src={check} alt="valide" /> */}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.ANA_FACTURE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {dateFormattedFr(invoice?.ANA_DATEREALISATION)}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.INITIAL}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.Réglé}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.ANA_ESOLDE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.Saisie}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.P}
                        </td>
                        <td className="w-[18%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.DOS_TITRE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2 text-gray-400 italic">
                          {invoice?.ANA_LIBELLELIB}
                        </td>
                      </tr>
                    );
                  })
                : null}
              {invoicesToAllocate?.map((invoice: any) => {
                const selectedInvoice = selectedInvoicesToAllocate.find(
                  (inv) => inv.ANA_FACTURE === invoice.ANA_FACTURE
                );

                return (
                  <tr className={`w-full border-b-[1px] flex`}>
                    <td className="w-[2%] border-r-[1px] flex justify-start items-start pt-2">
                      <input
                        type="checkbox"
                        onChange={() => handleInvoiceSelection(invoice)}
                        checked={isInvoiceSelected(invoice.ANA_FACTURE)}
                      />
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ANA_FACTURE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {dateFormattedFr(invoice?.ANA_DATEREALISATION)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.INITIAL}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {selectedInvoice ? selectedInvoice?.Réglé : invoice?.Réglé}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {selectedInvoice
                        ? selectedInvoice?.Solde
                        : invoice?.ANA_ESOLDE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {selectedInvoice ? selectedInvoice?.Saisie : invoice?.Saisie}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">{invoice?.P}</td>
                    <td className="w-[18%] border-r-[1px] p-2">
                      {invoice?.DOS_TITRE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ANA_LIBELLELIB}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </>
      
  );
};

export default PointCashInFlow;
