import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatepickerProps {
  startDate?: Date | null;
  setStartDate?: (date: Date | null) => void;
}

/**
 * @component
 * @name DatepickerForFilter
 * @author Iris Succi
 * @date 2024
 * @description
 * `Datepicker` is a React Functional Component that provides a date selection interface using a calendar-style date picker.
 * It allows users to select a date, and it can either use provided props for the selected date and the setter function
 * or fall back to using values from a global date store.
 *  This is for the filter modal only.
 */
const DatepickerForFilter: React.FC<DatepickerProps> = ({ startDate, setStartDate }) => {
    const changeDate = (date: Date | null) => {
        if (setStartDate) {
          if (date !== null) {
            setStartDate(date);
          }
        }
      };
    
      return (
        <div className="flex flex-col justify-start items-start mb-2 z-50">
          <DatePicker
            selected={startDate}
            onChange={(date: Date | null) => {changeDate(date)}}
            className="border-[1px] rounded border-gray-300 h-9 p-1 w-32 sm:w-40 z-50 font-thin"
            dateFormat={"dd/MM/yyyy"}
          />
        </div>
      );
};

export default DatepickerForFilter;
