import { createContext, useContext, useState } from "react";
import { RightContextValue } from "../interface/context";

const initialData = JSON.parse(localStorage.getItem("firstRight") || "{}");

const RightContext = createContext<RightContextValue | null>(null);

export const RightProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [buroFact, setBuroFact] = useState<number>(initialData.BuroFact || 0);
  const [buroTime, setBuroTime] = useState<number>(initialData.BuroTime || 0);
  const [chrono, setChrono] = useState<number>(
    initialData["Chrono des documents"] || 0
  );
  const [easyFile, setEasyFile] = useState<number>(initialData.EasyFile || 0);
  const [etats, setEtats] = useState<number>(initialData.Etats || 0);
  const [analyse, setAnalyse] = useState<number>(initialData.Graphiques || 0);
  const [cost, setCost] = useState<number>(initialData["Notes de frais"] || 0);

  return (
    <RightContext.Provider
      value={{
        buroFact,
        setBuroFact,
        buroTime,
        setBuroTime,
        chrono,
        setChrono,
        easyFile,
        setEasyFile,
        etats,
        setEtats,
        analyse,
        setAnalyse,
        cost,
        setCost,
      }}
    >
      {children}
    </RightContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useRightContext = () => {
  const context = useContext(RightContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
