import { create } from 'zustand';

interface MyState {
  unitPrice: any,
  setUnitPrice: (price: any) => void,

  quantity: any,
  setQuantity: (quantity: any) => void,

  ht: any,
  setHt: (ht: any) => void,
  
  tva: any,
  setTva: (tva: any) => void,

  tvaRate: any[],
  setTvaRate: (tvaRate: any[]) => void,

  ttc: any,
  setTtc: (ttc: any) => void,

  selectedTvaRate: number,
  setSelectedTvaRate: (tva: number) => void,

  resetAmounts : () => void,
}

export const amountsStore = create<MyState>((set) => ({
    unitPrice: null,
    setUnitPrice: (price) => set({ unitPrice: price }),
    
    quantity: null,
    setQuantity: (quantity) => set({ quantity }),
    
    ht: 0,
    setHt: (ht) => set({ ht }),
    
    tva: 0,
    setTva: (tva) => set({ tva }),
    
    tvaRate: [],
    setTvaRate: (tvaRate) => set({ tvaRate }),
    
    ttc: 0,
    setTtc: (ttc) => set({ ttc }),
    
    selectedTvaRate: 2,
    setSelectedTvaRate: (tva) => set({ selectedTvaRate: tva }),

    resetAmounts: () => set({
        unitPrice: 0,
        quantity: 0,
        ht: 0,
        tva: 0,
        ttc: 0,
        selectedTvaRate: 2,
    }),
}));