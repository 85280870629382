import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";

/**
 * @component
 * @name Tabs
 * @description Tabs est un composant qui permet d'afficher les onglets pour la navigation d'un dossier client. Fiche client, Participants, Documents, Missions, Avoirs, Abonnement.
 * @author Iris Succi
 * @date 2023
 */
const Tabs = () => {
  const { choiceTab, setChoiceTab } = useUtilsCrmStore();

  return (
    <div className="flex justify-start items-start border-[1px] rounded-t-md 2xl:w-[800px] w-[600px] mt-2">
      <button
        className={
          choiceTab !== "Fiche client"
            ? "border-r-[1px] rounded-tl-md px-4 py-1 w-[200px]"
            : "border-r-[1px] rounded-tl-md px-4 py-1 w-[200px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Fiche client")}
      >
        Fiche client
      </button>
      <button
        className={
          choiceTab !== "Participants"
            ? "border-r-[1px] px-4 py-1 w-[200px]"
            : "border-r-[1px] px-4 py-1 w-[200px] bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Participants")}
      >
        Intervenant
      </button>
      <button
        className={
          choiceTab !== "Documents"
            ? "px-4 py-1 w-[200px] border-r-[1px]"
            : "px-4 py-1 w-[200px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Documents")}
      >
        Documents
      </button>
      {/*<button
        className={
          choiceTab !== "Agenda"
            ? "py-1 px-4 w-[250px] border-r-[1px]"
            : "py-1 px-5 bg-[#cecece] w-[250px] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Agenda")}
      >
        Agenda
      </button> */}
      <button
        className={
          choiceTab !== "Missions"
            ? "py-1 px-4 w-[200px]"
            : "py-1 px-5 bg-[#cecece] w-[200px]"
        }
        onClick={() => setChoiceTab("Missions")}
      >
        Missions
      </button>
      {/* <button
        className={
          choiceTab !== "Avoir"
            ? "py-1 px-4 w-[250px] border-r-[1px]"
            : "py-1 px-5 w-[250px] bg-[#cecece] border-r-[1px]"
        }
        onClick={() => setChoiceTab("Avoir")}
      >
        Avoirs
      </button>
      <button
        className={
          choiceTab !== "Abonnement" ? "py-1 px-4 " : "py-1 px-5 bg-[#cecece]"
        }
        onClick={() => setChoiceTab("Abonnement")}
      >
        Abonnement
      </button> */}
    </div>
  );
};

export default Tabs;
