import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to create unpaid
 */
export const postUnpaid = (id: any, date: any) => {
    const data = {
        "ATR_DATE_ENCAISSEMENT": date
    }

    try {
        const response = axiosWithToken().post(`api/unpaids/${id}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getUnpaids = () => {
    try {
        const response = axiosWithToken().get(`api/unpaids`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}