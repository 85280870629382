import { create } from "zustand";
type DataCostState = {
    ANA_CODELIB: string | null,
    ANA_DATEREALISATION: Date | null,
    ANA_DATEECHEANCE: Date | null,
    ANA_EPRIXUNITAIRE: number | null,
    ANA_QUANTITE: number,
    ANA_EMTHT: number | null,
    ANA_EMTTVA:  number | null,
    ANA_EMTTTC: number | null,
    ANA_NOTES: string | null,
    ANA_REDACTEUR: string | null,
    AOR_REF: string | null,
    DOS_REF: number | null,
    MIS_REF: number | null,
    TVA_CLE: number | null,
    FIC_REF: number | null,
}
interface State {
  startDate: any,  
  setStartDate: (startDate: Date) => void,

  clients: any[],
  setClients: (clients: any[]) => void,

  selectedClient: number, 
  setSelectedClient : (options: number) => void,

  fileOptions: any[],
  setFileOptions: (options: any[]) => void,
  
  missions: any[],
  setMissions: (missions: any[]) => void,
  
  origins: any[],
  setOrigins: (origins: any[]) => void,
  
  libelles: any[],
  setLibelles: (libelles: any[]) => void,

  selectedFile: number,
  setSelectedFile: (file: number) => void,
  
  selectedMission: number,
  setSelectedMission: (mission: number) => void,
  
  selectedOrigin: any,
  setSelectedOrigin: (origin: any) => void,
  
  selectedLibelle: string,
  setSelectedLibelle: (libelle: string) => void,

  comments: any,
  setComments: (comments: any) => void,

  actors: any[],
  setActors: (actors: any[]) => void,

  selectedActor: string,
  setSelectedActor: (actor: string) => void,

  unitPrice: any,
  setUnitPrice: (price: any) => void,

  quantity: any,
  setQuantity: (quantity: any) => void,

  ht: any,
  setHt: (ht: any) => void,
  
  tva: any,
  setTva: (tva: any) => void,

  tvaRate: any[],
  setTvaRate: (tvaRate: any[]) => void,

  ttc: any,
  setTtc: (ttc: any) => void,

  selectedTvaRate: number,
  setSelectedTvaRate: (tva: number) => void,

  dataCost: DataCostState;
  setDataCost: (property: keyof DataCostState, value: any) => void;

  updateDataCost: DataCostState | null;
  setUpdateDataCost: (updateDataCost: DataCostState) => void;

}

export const createUpdateCostStore = create<State>((set) => ({
    startDate: new Date(),
    fileOptions: [],
    clients: [],
    selectedClient: 0,
    missions: [],
    origins: [],
    libelles: [],
    selectedFile: 0,
    selectedMission: 0,
    selectedOrigin: "",
    selectedLibelle: "",
    comments: "",
    actors: [],
    selectedActor: "",
    unitPrice: 0,
    quantity: 1,
    ht: 0,
    tva: 0,
    ttc: 0,
    tvaRate: [],
    selectedTvaRate: 3,
    dataCost: {
      ANA_CODELIB: "",
      ANA_DATEREALISATION: new Date(),
      ANA_DATEECHEANCE: new Date(),
      ANA_EPRIXUNITAIRE: 0,
      ANA_QUANTITE: 1,
      ANA_EMTHT: 0,
      ANA_EMTTVA: 0,
      ANA_EMTTTC: 0,
      ANA_NOTES: "",
      ANA_REDACTEUR: "",
      AOR_REF: "",
      DOS_REF: 0,
      MIS_REF: 0,
      TVA_CLE: 3,
      FIC_REF: 0,
    },
    updateDataCost: null,
    setStartDate: (startDate) => set({startDate: startDate}),
    setFileOptions: (options) => set({ fileOptions: options }),
    setClients: (clients) => set({clients: clients}),
    setSelectedClient: (selectedClient) => set({selectedClient: selectedClient}),
    setMissions: (missions) => set({ missions }),
    setOrigins: (origins) => set({ origins }),
    setLibelles: (libelles) => set({ libelles }),
    setSelectedFile: (file) => set({ selectedFile: file }),
    setSelectedMission: (mission) => set({ selectedMission: mission }),
    setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),
    setSelectedLibelle: (libelle) => set({ selectedLibelle: libelle }),
    setComments: (comments) => set({ comments: comments }),
    setActors: (actors) => set({ actors }),
    setSelectedActor: (actor) => set({ selectedActor: actor }),
    setUnitPrice: (price) => set({ unitPrice: price }),
    setQuantity: (quantity) => set({ quantity }),
    setHt: (ht) => set({ ht }),
    setTva: (tva) => set({ tva }),
    setTtc: (ttc) => set({ ttc }),
    setTvaRate: (tvaRate) => set({ tvaRate }),
    setSelectedTvaRate: (tva) => set({ selectedTvaRate: tva }),
    setDataCost: (property, value) => set((state) => ({ dataCost: { ...state.dataCost, [property]: value } })),
    setUpdateDataCost: (updateCost: DataCostState | null) => set({ updateDataCost: updateCost })
  }));