import time from '../assets/icons/navbar/temps-passe.png';
import report from '../assets/icons/navbar/report.png';
import calendar from '../assets/icons/navbar/planning.png';
import synthese from '../assets/icons/navbar/synthese_dossier.png';
import invoice from '../assets/FACTURATION.png';
import frais from '../assets/icons/navbar/frais.png';

const dataMenu = [
{
    id: 1,
    logo: synthese,
    title: 'CRM',
    path: '/crm',
    right: "EasyFile",
    description: "Création de vos dossiers, Création des missions, Gestion des intervenants, Gestion de vos documents, Gestion de vos mails",
},{
    id: 2,
    logo: time,
    title: "GESTION DU TEMPS",
    path: '/timemanagement',
    right: "BuroTime",
    description: 'Suivi des missions attachées au dossier, Accès à la gestion de vos temps passés et à votre planning des tâches',
},{
    id: 3,
    logo: calendar,
    title: 'CALENDRIER',
    path: '/calendar',
    right: "BuroCal",
    description: 'Vos Rendez-vous à ne pas manquer, Gestion de vos disponibilité et de vos congés',
},{
    id: 4,
    logo: invoice,
    title: 'FACTURATION',
    path: '/invoicing',
    right: "BuroFact",
    description: 'Suivi du cycle de facturation de vos dossiers.',
},{
    id: 5,
    logo: frais,
    title: 'FRAIS',
    path: '/costs',
    right: "Notes de frais",
    description: 'Saisie de vos frais de déplacement et notes de frais.',
},{
    id: 6,
    logo: report,
    title: "REPORTING",
    path: '/financialstatement',
    right: "Etats",
    description: "Accès aux rapports détaillés sur l'état d'avancement de chaque mission, Gestion de d’exploitation du Cabinet",
},{
    id: 7,
    logo: report,
    title: "ANALYSE D'ACTIVITÉ",
    path: '/analyse',
    right: "Analyse",
    description: "Analyse globale d'activité",
}] 

export default dataMenu;
