import { create } from 'zustand';

// définir le type pour l'état de la vue
type ViewState = {
  view: 'list' | 'edit' | 'day' | 'week';
  setView: (view: 'list' | 'edit' | 'day' | 'week') => void;
  editMode: boolean;
  setEditMode: any;
};

export const useViewStore = create<ViewState>((set) => ({
  view: 'week',
  setView: (view: 'list' | 'edit' | 'day' | 'week') => set(() => ({ view })),
  editMode: false,
  setEditMode: (mode: boolean) => set(() => ({ editMode: mode })),
}));
