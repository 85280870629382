import axios from "axios";
import { axiosWithToken } from "../axiosService"

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get time/filter
 */

export const getTimeFilterOrigin = () => {
    try {
        const response = axiosWithToken().get(`api/time/filter/origines`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getTimeFilterLibelles = (AOR_REF: string ) => {
    try {
        const response = axiosWithToken().get(`api/time/filter/libelles?AOR_REF=${AOR_REF}`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}