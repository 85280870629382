import { useEffect, useRef } from "react";
import { useCostStore } from "../store/Cost/Cost";
import { getCosts } from "../utils/Cost/Cost";
import { Toaster } from "react-hot-toast";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import useModal from "../hooks/useModal";
import ButtonsCost from "../components/Cost/ButtonsCost";
import SearchBar from "../components/Utils/SearchBar";
import CostTable from "../components/Cost/CostTable";
import Footer from "../components/Layout/Footer";
import { useUserContext } from "../context/UserContext";
import { useLoadingStore } from "../store/utils/loading";
import Filter from "../components/Invoice/buttons/Filter";
/**
 * @page
 * @name Cost
 * @description
 * Cost est la page principale de l'application pour la gestion des frais. Elle permet de visualiser, éditer et valider les coûts.
 * @author Iris Succi
 * @date 2023
 */
const Cost = () => {
  // Store
  const { choiceTab, setCosts, user, setUser, sendCost } = useCostStore();
  const { setIsLoading } = useLoadingStore();

  // Context
  const { id } = useUserContext();

  // Ref for tableWeek
  const tableRefForWeek = useRef();

  // Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    setUser(id);
    setIsLoading(true);
    getCosts()
      .then((res) => {
        setCosts(res.data || []);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [choiceTab, id, sendCost]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <Toaster position="top-center" reverseOrder />
      <Header
        openModal={openModal}
        closeModal={closeModal}
        user={user}
        setUser={setUser}
        tableRefForWeek={tableRefForWeek}
      />
      <div className="flex">
        <div className="h-auto">
          <Navbar />
        </div>

        {/* MODAL */}
        {isModalOpen ? modalContent : null}

        <div className="flex flex-col w-screen ">
          <div className="flex flex-col justify-between mt-5 mx-7 h-[80vh]">
            {/* BUTTONS */}
            <div className="w-full flex justify-between items-center">
              <div className="h-10 mb-2">
                <ButtonsCost />
              </div>
              <div className="flex">
                <Filter openModal={openModal} closeModal={closeModal} />
                <SearchBar />
              </div>
            </div>
            {/* TABLES */}
            <CostTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cost;
