import { useExplorer } from "../../../../store/Crm/Explorer";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";

/**
 * @component
 * @name DossiersTree
 * @description A component that displays a dossier and its list of missions and parapheurs.
 * @author Iris Succi
 * @date 2023
 */
const DossiersTree = ({ dossier }: any) => {
  // Store
  const { setFicRef, setDosRef, setClientFileName } = useExplorer();
  const { setChoiceTab, setFirstPage } = useUtilsCrmStore();

  // This is the old code, for subfolders

  /*   const handleFolderClick = (DOS_REF: any) => {
    getDossiersDatas(DOS_REF)
      .then((res) => {
        setMissions(res.data.Missions);
        setParapheurs(res.data.Parapheurs);
      })
      .catch((err) => console.log(err));

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (refresh === true) {
      getDossiersDatas(dosRef)
        .then((res) => {
          setMissions(res.data.Missions);
          setParapheurs(res.data.Parapheurs);
          setRefresh(false);
        })
        .catch((err) => console.log(err));
    }
  }, [refresh]); */

  return (
    <div className="w-[97%] m-auto flex flex-col space-y-4">
      <div className="relative">
        <div className="border-t-2 absolute top-[14px] left-[-15px] w-3" />
        <div
          className="flex items-center py-1" /* 
          onMouseEnter={() => setShowButton((prev) => !prev)}
          onMouseLeave={() => setShowButton((prev) => !prev)} */
        >
          <button
            className="flex items-center "
            onClick={() => {
              setFicRef(dossier?.FIC_REF_CLIENT);
              setChoiceTab("Fiche client");
              setFirstPage(true);
              setDosRef(dossier?.DOS_REF);
              setClientFileName(dossier?.FIC_TITRE_CLIENT);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="#f8c63d"
              stroke="#f8c63d"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-folder"
            >
              <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
            </svg>

            <div className="pl-1 font-light text-xs">
              {dossier.DOS_CODE} - {dossier.FIC_TITRE_CLIENT}
            </div>
          </button>
          {/*           {showButton && (
            <div className="flex ml-2">
              <button className="flex items-center relative">
                <img src={pen} alt="" className="h-4 w-4 ml-2" />
              </button>
              <button className="flex items-center relative">
                <img src={deleteTrash} alt="" className="h-4 w-4 ml-2" />
              </button>
              <button
                className="flex items-center relative"
                onClick={() => {
                  openModal(
                    <CreateFolder1
                      closeModal={closeModal}
                      dosRef={dossier?.DOS_REF}
                      setRefresh={setRefresh}
                    />
                  );
                }}
              >
                <img src={addFolder} alt="" className="h-4 w-4 ml-2" />
              </button>
            </div>
          )} */}
        </div>
        {/*  {isOpen
          ? Array.isArray(missions) &&
            missions.map((mission: any, index: number) => (
              <div className="relative">
                <div className="border-l-2 absolute top-0 bottom-0 left-[8px]" />
                <DossierData
                  key={index}
                  data={mission}
                  type="mission"
                  setRefresh={setRefresh}
                  openModal={openModal}
                  closeModal={closeModal}
                />
              </div>
            ))
          : null}
        {isOpen
          ? Array.isArray(parapheurs) &&
            parapheurs.map((parapheur: any, index: number) => (
              <div className="relative">
                <div className="border-l-2 absolute top-0 bottom-0 left-[8px]" />
                <DossierData
                  key={index}
                  data={parapheur}
                  type="parapheur"
                  setRefresh={setRefresh}
                  openModal={openModal}
                  closeModal={closeModal}
                />
              </div>
            ))
          : null} */}
      </div>
    </div>
  );
};

export default DossiersTree;
