import { useEffect } from "react";
import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";
import { getAllUsers } from "../../../utils/user";
/**
 * @component
 * @name GroupeSelect
 * @description
 * A component that renders a select dropdown for selecting a user group.
 * The component retrieves the list of all users from the API and sorts
 * them by user identification.
 * @author Iris Succi
 * @date 2023
 */
const GroupeSelect = () => {
  const { groupes, setGroupes, setSelectedGroupe } = useClientFileStore();

  useEffect(() => {
    getAllUsers()
      .then((res) => {
        const sortedUsers = res.data.sort((a: any, b: any) =>
          a.identifiant.localeCompare(b.identifiant)
        );
        setGroupes(sortedUsers);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <select
      className="w-full h-9 border-[1px] rounded-sm"
      value={groupes}
      onChange={(e) => setSelectedGroupe(e.target.value)}
    >
      <option value=""></option>
      {groupes.map((groupe) => (
        <option key={groupe.identifiant} value={groupe.identifiant}>
          {groupe.identifiant} - {groupe.firstname} {groupe.lastname}
        </option>
      ))}
    </select>
  );
};

export default GroupeSelect;
