import { create } from "zustand";
import { SelectOptionDossier } from "../../interface/selects";

interface MyState {
    missions: any[];
    setMissions: (missions: any[]) => void;

    fileOptions: SelectOptionDossier[];
    setFileOptions: (fileOptions: SelectOptionDossier[]) => void;
  
    startDateFilter: Date | null;
    setStartDateFilter: (date: Date | null) => void;
  
    endDateFilter: Date | null;
    setEndDateFilter: (date: Date | null) => void;
  
    selectedMisRef: number;
    setSelectedMisRef: (misRef: number) => void;
  
    selectedFileOption: SelectOptionDossier | null;
    setSelectedFileOption: (fileOption: SelectOptionDossier | null) => void;
  
    selectedOriginObject: any;
    setSelectedOriginObject: (originObject: any) => void;

    selectedLibelleObject: any;
    setSelectedLibelleObject: (originObject: any) => void;

    selectedFile: number;
    setSelectedFile: (file: number) => void;

    selectedMission: string;
    setSelectedMission: (mission: string) => void;

    selectedOrigin: string;
    setSelectedOrigin: (origin: string) => void;

    selectedLibelle: string;
    setSelectedLibelle: (libelle: string) => void;

    origins: SelectOptionDossier[];
    setOrigins: (origins: SelectOptionDossier[]) => void;

    libelles: SelectOptionDossier[];
    setLibelles: (libelles: SelectOptionDossier[]) => void;

    choice : string;
    setChoice: (choice: string) => void;

    optionNonFacturable: boolean;
    setOptionNonFacturable: (optionNonFacturable: boolean) => void;

    // For filter Cost
    clients: any[];
    setClients: (clients: any[]) => void;

    selectedClient: number;
    setSelectedClient: (options: number) => void;
  }

export const filterInvoicesStore = create<MyState>((set) => ({
  startDateFilter: null,
  setStartDateFilter: (date: Date | null) => set(() => ({ startDateFilter: date })),

  endDateFilter: null,
  setEndDateFilter: (date: Date | null) => set(() => ({ endDateFilter: date })),

  selectedMisRef: 0,
  setSelectedMisRef: (misRef: number) => set(() => ({ selectedMisRef: misRef })),

  selectedFileOption: null,
  setSelectedFileOption: (fileOption: SelectOptionDossier | null) => set(() => ({ selectedFileOption: fileOption })),

  selectedOriginObject: null,
  setSelectedOriginObject: (originObject: any) => set(() => ({ selectedOriginObject: originObject })),

  selectedLibelleObject: null,
  setSelectedLibelleObject: (libelleObject: any) => set(() => ({ selectedLibelleObject: libelleObject })),

  selectedFile: 0,
  setSelectedFile: (file: number) => set(() => ({ selectedFile: file })),

  selectedMission: "",
  setSelectedMission: (mission: string) => set(() => ({ selectedMission: mission })),

  selectedOrigin: "",
  setSelectedOrigin: (origin: string) => set(() => ({ selectedOrigin: origin })),

  selectedLibelle: "",
  setSelectedLibelle: (libelle: string) => set(() => ({ selectedLibelle: libelle })),

  missions: [],
  setMissions: (missions: any[]) => set(() => ({ missions: missions })),

  fileOptions: [],
  setFileOptions: (fileOptions: SelectOptionDossier[]) => set(() => ({ fileOptions: fileOptions })),

  origins: [],
  setOrigins: (origins: SelectOptionDossier[]) => set(() => ({ origins: origins })),

  libelles: [],
  setLibelles: (libelles: SelectOptionDossier[]) => set(() => ({ libelles: libelles })),

  choice: "",
  setChoice: (choice: string) => set(() => ({ choice: choice })),

  optionNonFacturable: false,
  setOptionNonFacturable: (optionNonFacturable: boolean) => set(() => ({ optionNonFacturable: optionNonFacturable })),

  clients: [],
  setClients: (clients: any[]) => set(() => ({ clients: clients })),

  selectedClient: 0,
  setSelectedClient: (options: number) => set(() => ({ selectedClient: options })),
}));
