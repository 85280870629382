import toast, { Toaster } from "react-hot-toast";
import useModal from "../hooks/useModal";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Tabs from "../components/Invoice/buttons/Tabs";
import list from "../assets/icons/list.svg";
import { useEffect, useState } from "react";
import { getAllFees, getFeesBillables } from "../utils/Invoice/fees";
import { useTabsStore } from "../store/Invoice/tabsStore";
import { dataHeaderInvoice } from "../data/dataInvoice";
import {
  getInvoices,
  getInvoicesRelaunchs,
  getProformats,
  getProvisional,
} from "../utils/Invoice/invoicing";
import { dataInvoicesFeelsStore } from "../store/Invoice/dataInvoicesFeelsStore";
import { useLoadingStore } from "../store/utils/loading";
import SearchBar from "../components/Utils/SearchBar";
import Export from "../components/Invoice/buttons/Export";
import Filter from "../components/Invoice/buttons/Filter";
import { InvoicesTable } from "../components/Invoice/tables/InvoicesTable";
import CreateFeeOrInvoice from "../components/Invoice/buttons/CreateFeeOrInvoice";
import CreditsTable from "../components/Invoice/tables/CreditsTable";
import ProformatTable from "../components/Invoice/tables/ProformatTable";
import useOutsideClick from "../hooks/useClickOutside";
import { useInvoiceStore } from "../store/Invoice/invoice";
import AcompteTable from "../components/Invoice/tables/AcompteTable";
import ProvisionTable from "../components/Invoice/tables/ProvisionTable";
import FeesTable from "../components/Invoice/tables/FeesTable";
import { getPrepayments } from "../utils/Invoice/prepayments";
import { useCreateInvoiceStore } from "../store/Invoice/createInvoice";
import {
  getUnvalidatedCredits,
  getValidatedCredits,
} from "../utils/Invoice/credits";
import CashInFlowTable from "../components/Invoice/tables/CashInFlowTable";
import { getCashInFlows } from "../utils/Invoice/cashInFlow";
import { errorRigth, errorSendNewTime } from "../utils/toaster";
import { useSearchBarStore } from "../store/utils/searchBar";
import { useCreateFeesOrInvoices } from "../store/Invoice/buttonsStore";
import VerifyUnpaid from "../components/Invoice/modal/VerifyUnpaid";
import { getUnpaids } from "../utils/Invoice/unpaid";
import { getSlips } from "../utils/Invoice/slips";
import SlipTable from "../components/Invoice/tables/SlipTable";
import RelaunchTable from "../components/Invoice/tables/RelaunchTable";
import { useRightInvoiceContext } from "../context/RightInvoiceContext";
import { useCreateFee } from "../store/Invoice/createFeeStore";
import { filterInvoicesStore } from "../store/Invoice/filterInvoicesStore";

/**
 * @page
 * @name Invoice
 * @description
 * Invoice page with the tabs, the modal and the tables. This page is the main page of the invoice part. Call api for each choiceTab.
 * @author Iris Succi
 * @date 2023
 */
const Invoice = () => {
  //State
  const [isOpenExport, setIsOpenExport] = useState(false);

  //Context
  const {
    acompteRight,
    avoirRight,
    bordereauxRight,
    provisionRight,
    encaissementRight,
    factureRight,
    relanceRight,
  } = useRightInvoiceContext();

  //Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false, () =>
    setIsOpenExport(false)
  );

  //Store
  const { choiceTab, sendData } = useTabsStore();
  const { selectedItems, setSelectedItems } = useInvoiceStore();
  const { setSearch } = useSearchBarStore();
  const { optionNonFacturable } = filterInvoicesStore();
  const { createUnpaid } = useCreateFeesOrInvoices();
  const { setSelectedIdsInvoices } = useCreateInvoiceStore();
  const {
    setFees,
    setInvoices,
    setProvisionals,
    setProformats,
    setCredits,
    setAcomptes,
    setCashInFlows,
    setSlips,
    setRelaunchs,
  } = dataInvoicesFeelsStore();
  const { setIsLoading } = useLoadingStore();
  const { sendFee } = useCreateFee();

  // This is for get data in tables
  useEffect(() => {
    setIsLoading(true);
    if (choiceTab === "Honoraire" && factureRight === 1) {
      if(optionNonFacturable) {
        getAllFees()
          .then((res) => {
            setFees(res.data || []);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(`Erreur : ${err}`);
            if (err.response.status === 403) {
              errorRigth();
            }
          });
      } else {
          getFeesBillables()
          .then((res) => {
            setFees(res.data || []);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(`Erreur : ${err}`);
            if (err.response.status === 403) {
              errorRigth();
            }
          });
        }
    } else if (choiceTab === "Facture" && factureRight === 1) {
      getInvoices()
        .then((res) => {
          setInvoices(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Avoir" && avoirRight === 1) {
      Promise.all([getUnvalidatedCredits(), getValidatedCredits()])
        .then(([unvalidatedResponse, validatedResponse]) => {
          const combinedCredits = {
            CreditsUnvalidated: unvalidatedResponse.data,
            CreditsValidated: validatedResponse.data,
          };

          setCredits(combinedCredits);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Acompte" && acompteRight === 1) {
      getPrepayments()
        .then((res) => {
          setAcomptes(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Provision" && provisionRight === 1) {
      getProvisional()
        .then((res) => {
          setProvisionals(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Proformat" && factureRight === 1) {
      getProformats()
        .then((res) => {
          setProformats(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Bordereau" && bordereauxRight === 1) {
      getSlips()
        .then((res) => {
          setSlips(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Relance" && relanceRight === 1) {
      getInvoicesRelaunchs()
        .then((res) => {
          setRelaunchs(res.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else if (choiceTab === "Encaissement" && encaissementRight === 1) {
      Promise.all([getCashInFlows(), getUnpaids()])
        .then(([cashInFlowsResponse, unpaidsResponse]) => {
          const cash = cashInFlowsResponse?.data || [];
          const unpaid = unpaidsResponse?.data || [];

          // Créer un ensemble des ATR_REFPERE présents dans 'unpaid'
          const unpaidRefs = new Set(
            unpaid?.map((item: any) => item.ATR_REFPERE)
          );

          // Ajoute la clé "impaye" aux éléments de 'cash' qui ont un ATR_REF correspondant
          const updatedCash = cash?.map((item: any) => {
            if (unpaidRefs.has(item.ATR_REF)) {
              return { ...item, impaye: true };
            }
            return item;
          });

          // Ajoute la clé "impaye" aux éléments correspondants de 'unpaid'
          const updatedUnpaids = unpaid?.map((item: any) => {
            if (
              updatedCash.some(
                (cashItem: any) => cashItem.ATR_REF === item.ATR_REFPERE
              )
            ) {
              return { ...item, impaye: true };
            }
            return item;
          });

          const combinedData = [...updatedCash, ...updatedUnpaids];

          setCashInFlows(combinedData);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorSendNewTime();
          setIsLoading(false);
          if (err.response.status === 403) {
            errorRigth();
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [choiceTab, sendFee, sendData]);

  // This is for selected items in invoice table
  const handleSelect = (item: any) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleToggle = () => {
    if (isOpenExport) {
      setIsOpenExport(false);
    }
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    setSelectedIdsInvoices((_prev) => []);
    setSearch("");
  }, [choiceTab]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <Toaster position="top-center" reverseOrder />
      <Header openModal={openModal} closeModal={closeModal} />
      <div className="flex">
        <div className="h-auto">
          <Navbar />
        </div>

        {/* MODAL */}
        {isModalOpen ? modalContent : null}

        <div className="flex flex-col w-screen ">
          <div className="flex flex-col justify-between mt-5 mx-7 h-[80vh]">
            {/* BUTTONS */}
            <div className="h-10">
              <CreateFeeOrInvoice />
            </div>
            <div className="flex justify-between items-center">
              <Tabs />
              <div className="flex justify-center items-center mt-5">
                <Filter openModal={openModal} closeModal={closeModal} />
                <SearchBar />
                {choiceTab === "Facture" && (
                  <div className="relative">
                    <button
                      className="w-12 h-8 bg-bc-orange border-[1px] flex justify-center items-center mx-2 px-4 rounded text-white hover:bg-bc-lightOrange transition-colors duration-200"
                      onClick={handleToggle}
                    >
                      <img
                        src={list}
                        alt="icon list"
                        className="h-12 w-12 z-50 "
                      />
                    </button>
                    {isVisible && (
                      <div
                        ref={ref}
                        className="absolute top-10 w-44 right-2 h-44 overflow-y-scroll border-[1px] bg-slate-200 rounded-md z-50 mx-2"
                      >
                        {isVisible && (
                          <div className="select-menu ">
                            {dataHeaderInvoice?.map((item, index) => (
                              <div key={index} className="select-option">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={selectedItems.includes(item)}
                                  className="mr-2"
                                  onChange={() => handleSelect(item)}
                                />
                                <label>{item}</label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="ml-2">
                  <Export />
                </div>
              </div>
            </div>
            {/* TABLES */}
            {choiceTab === "Honoraire" ? (
              <FeesTable />
            ) : choiceTab === "Facture" ? (
              <InvoicesTable />
            ) : choiceTab === "Avoir" ? (
              <CreditsTable />
            ) : choiceTab === "Acompte" ? (
              <AcompteTable />
            ) : choiceTab === "Proformat" ? (
              <ProformatTable />
            ) : choiceTab === "Provision" ? (
              <ProvisionTable />
            ) : choiceTab === "Encaissement" ? (
              <CashInFlowTable />
            ) : choiceTab === "Bordereau" ? (
              <SlipTable />
            ) : choiceTab === "Relance" ? (
              <RelaunchTable />
            ) : null}
            {createUnpaid ? <VerifyUnpaid /> : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Invoice;
