import axios from "axios";
import { axiosWithToken } from "./axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / put customerfile
 */

export const getVarDos = () => {
    try {
        const response = axiosWithToken().get(`api/customerfile/varfile`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
};

export const getVarDosLib = (number: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/varfilelib?number=${number}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getContacts = (DOS_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${DOS_REF}/contacts`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getClients = () => {
    try {
        const response = axiosWithToken().get(`api/customerfile/customers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getClientsByDOS = (DOS_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${DOS_REF}/customer`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getPostTitle = () => {
    try {
        const response = axiosWithToken().get(`api/customerfile/posttitle`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getPersonnesFile = (ficRef: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${ficRef}/personnesfile`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const postPersonnesFile = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/customerfile/customer`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getJuridicForm = () => {
    try {
        const response = axiosWithToken().get(`api/customerfile/juridicform`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const patchPersonnesFile = (DCLE: number, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/customerfile/customer/${DCLE}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getParticipantsByDos = (dos_ref: number, pent_id: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/participants?PENT_ID=${pent_id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getInvoicesCheckablesForPrepayment = (dos_ref: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/invoices/checkables`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getDocumentsByDos = (dos_ref: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/documents`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getLevelRelaunchs = () => {
    try {
        const response = axiosWithToken().get(`api/customerfile/relance`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getMissionsDetailedByDosRef = (dos_ref: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/missions/detailed`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getSuppliersByDosRef = (dos_ref: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/suppliers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getCustomerByDosRef = (dos_ref: number) => {
    try {
        const response = axiosWithToken().get(`api/customerfile/${dos_ref}/customers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}