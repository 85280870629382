import { useEffect } from "react";
import { getFeesSummary } from "../../../../utils/Invoice/fees";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { formatNumber } from "../../../../utils/functionService";

/**
 * Component that displays the total amount of the proforma invoice. It also shows the
 * breakdown of the total amount by tax rate.
 *
 * @function TableCreateProformaTotal
 * @name TableCreateProformaTotal
 * @description Component that displays the total amount of the proforma invoice.
 *
 * @prop {array<number>} selectedIdsInvoices - An array of the IDs of the selected invoices.
 * @prop {object} feesSummary - The summary of the fees for the selected invoices.
 * @prop {function} setFeesSummary - A function to update the feesSummary prop.
 */
const TableCreateProformaTotal = () => {
  const { selectedIdsInvoices, feesSummary, setFeesSummary, selectedDosRef } =
    useCreateInvoiceStore();

  useEffect(() => {
    getFeesSummary(selectedIdsInvoices, selectedDosRef)
      .then((res) => setFeesSummary(res.data))
      .catch((err) => console.log(err));
  }, [selectedIdsInvoices]);

  return (
    <div className="w-full bg-white border-[1px] rounded-md border-gray-600 h-full m-auto">
      <div className="w-5/6 m-auto grid grid-cols-6 mt-1 gap-y-2 h-full gap-x-2">
        <p className="col-start-3 col-end-4 font-bold text-center">H.T</p>
        <p className="col-start-4 col-end-5 font-bold text-center">T.V.A</p>
        <p className="col-start-5 col-end-6 font-bold text-center">N.S</p>
        <p className="col-start-6 col-end-7 font-bold text-center">T.T.C</p>
        <p className="col-start-1 col-end-3 row-start-2 row-end-3 font-bold">
          Prévisionnel
        </p>
        <p className="col-start-3 col-end-4 row-start-2 row-end-3 text-center">
          {formatNumber(feesSummary?.provisional?.HT)}
        </p>
        <p className="col-start-4 col-end-5 row-start-2 row-end-3 text-center">
          {formatNumber(feesSummary?.provisional?.TVA)}
        </p>
        <p className="col-start-5 col-end-6 row-start-2 row-end-3 text-center">
          {formatNumber(feesSummary?.provisional?.NS)}
        </p>
        <p className="col-start-6 col-end-7 row-start-2 row-end-3 text-center">
          {formatNumber(feesSummary?.provisional?.ENETAPAYER)}
        </p>
        <p className="col-start-1 col-end-3 row-start-3 row-end-4 font-bold">
          Automatique
        </p>
        <p className="col-start-3 col-end-4 row-start-3 row-end-4 text-center">
          0
        </p>
        <p className="col-start-4 col-end-5 row-start-3 row-end-4 text-center">
          0
        </p>
        <p className="col-start-5 col-end-6 row-start-3 row-end-4 text-center">
          0
        </p>
        <p className="col-start-6 col-end-7 row-start-3 row-end-4 text-center">
          0
        </p>
        <p className="col-start-1 col-end-3 row-start-4 row-end-5 font-bold">
          Sélection
        </p>
        <p className="col-start-3 col-end-4 row-start-4 row-end-5 text-center">
          {formatNumber(feesSummary?.selected?.HT)}
        </p>
        <p className="col-start-4 col-end-5 row-start-4 row-end-5 text-center">
          {formatNumber(feesSummary?.selected?.TVA)}
        </p>
        <p className="col-start-5 col-end-6 row-start-4 row-end-5 text-center">
          {formatNumber(feesSummary?.selected?.NS)}
        </p>
        <p className="col-start-6 col-end-7 row-start-4 row-end-5 text-center">
          {formatNumber(feesSummary?.selected?.ENETAPAYER)}
        </p>
        <p className="col-start-4 col-end-6 row-start-5 row-end-6 font-bold">
          Net à payer
        </p>
        <p className="col-start-6 col-end-7 row-start-5 row-end-6 text-center">
          {formatNumber(feesSummary?.netToPay?.ENETAPAYER)}
        </p>
        <p className="col-start-1 col-end-3 row-start-6 row-end-7 font-bold ">
          Reste à facturer
        </p>
        <p className="col-start-3 col-end-4 row-start-6 row-end-7 text-center">
          {formatNumber(feesSummary?.unbilled?.HT)}
        </p>
        <p className="col-start-4 col-end-5 row-start-6 row-end-7 text-center">
          {formatNumber(feesSummary?.unbilled?.TVA)}
        </p>
        <p className="col-start-5 col-end-6 row-start-6 row-end-7 text-center">
          {formatNumber(feesSummary?.unbilled?.NS)}
        </p>
        <p className="col-start-6 col-end-7 row-start-6 row-end-7 text-center">
          {formatNumber(feesSummary?.unbilled?.ENETAPAYER)}
        </p>
        <p className="col-start-1 col-end-3 row-start-7 row-end-8 font-bold">
          Marge
        </p>
      </div>
    </div>
  );
};

export default TableCreateProformaTotal;
