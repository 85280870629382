import { createContext, useContext, useState } from "react";
import { RightInvoiceContextValue } from "../interface/context";

const initialData = JSON.parse(localStorage.getItem("rightsInvoice") || "{}");

const RightInvoiceContext = createContext<RightInvoiceContextValue | null>(null);

export const RightInvoiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [acompteRight, setAcompteRight] = useState<number>(
    initialData["Acomptes Clients"] || 0
  );
  const [avoirRight, setAvoirRight] = useState<number>(
    initialData["Avoirs sur factures"] || 0
  );
  const [bordereauxRight, setBordereauxRight] = useState<number>(
    initialData["Bordereaux"] || 0
  );
  const [provisionRight, setProvisionRight] = useState<number>(
    initialData["Demandes de provision"] || 0
  );
  const [deboursExtRight, setDeboursExtRight] = useState<number>(
    initialData["Débours externes"] || 0
  );
  const [deboursIntRight, setDeboursIntRight] = useState<number>(
    initialData["Débours internes"] || 0
  );
  const [encaissementRight, setEncaissementRight] = useState<number>(
    initialData["Encaissements"] || 0
  );
  const [factureRight, setFactureRight] = useState<number>(
    initialData["Factures"] || 0
  );
  const [honorairesRight, setHonorairesRight] = useState<number>(
    initialData["Honoraires"] || 0
  );
  const [relanceRight, setRelanceRight] = useState<number>(
    initialData["Relances"] || 0
  );
  const [withoutMissionRight, setWithoutMissionRight] = useState<number>(
    initialData["Interdiction de saisie de missions"] || 0
  );
  const [mandatoryMissionRight, setMandatoryMissionRight] = useState<number>(
    initialData["Missions obligatoires"] || 0
  );

  return (
    <RightInvoiceContext.Provider
      value={{
        acompteRight,
        setAcompteRight,
        avoirRight,
        setAvoirRight,
        bordereauxRight,
        setBordereauxRight,
        provisionRight,
        setProvisionRight,
        deboursExtRight,
        setDeboursExtRight,
        deboursIntRight,
        setDeboursIntRight,
        encaissementRight,
        setEncaissementRight,
        factureRight,
        setFactureRight,
        honorairesRight,
        setHonorairesRight,
        relanceRight,
        setRelanceRight,
        withoutMissionRight,
        setWithoutMissionRight,
        mandatoryMissionRight,
        setMandatoryMissionRight,
      }}
    >
      {children}
    </RightInvoiceContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useRightInvoiceContext = () => {
  const context = useContext(RightInvoiceContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
