import { useCreateFeesOrInvoices } from "../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { useTabsStore } from "../../../store/Invoice/tabsStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { postUnpaid } from "../../../utils/Invoice/unpaid";
import { errorEntry } from "../../../utils/toaster";
import Datepicker from "../../Utils/dateManagement/DatePicker";

const VerifyUnpaid = () => {
  const { createUnpaid, setCreateUnpaid } = useCreateFeesOrInvoices();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    startDate,
    setStartDate,
  } = useCreateInvoiceStore();
  const { setSendData } = useTabsStore();
  const { setIsLoading } = useLoadingStore();

  const handleCreateUnpaid = () => {
    setIsLoading(true);
    setCreateUnpaid(false);
    postUnpaid(selectedIdsInvoices, startDate)
      .then(() => {
        setSelectedIdsInvoices((_prev) => []);
        setIsLoading(false);
        setSendData((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
        errorEntry();
      });
  };

  return (
    createUnpaid && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[25%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div className="flex flex-col justify-center items-center">
                <div className="text-sm md:text-base text-center p-5 font-bold">
                  Veuillez selectionner une date d'encaissement d'impayer.
                </div>
                <Datepicker startDate={startDate} setStartDate={setStartDate} />
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setCreateUnpaid(false);
                    setSelectedIdsInvoices((_prev) => []);
                  }}
                >
                  Annuler
                </button>
                <button
                  className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleCreateUnpaid();
                  }}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default VerifyUnpaid;
