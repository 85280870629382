import { create } from "zustand";

type LoginState = {
    identifiant: string;
    setIdentifiant: (identifiant: string) => void;

    password: string;
    setPassword: (password: string) => void;

    aliasList: any[];
    setAliasList: (aliasList: any[]) => void;
};

export const useLoginStore = create<LoginState>((set) => ({
    identifiant: "",
    setIdentifiant: (identifiant) => set({ identifiant }),

    password: "",
    setPassword: (password) => set({ password }),

    aliasList: [],
    setAliasList: (aliasList) => set({ aliasList }),
}));

