import { useEffect } from "react";
import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";
import { getAllUsers } from "../../../utils/user";

/**
 * @component
 * @name UserSelect
 * @description
 * A component that renders a select dropdown for selecting a collaborator.
 * The component retrieves the list of all users from the API and sorts
 * them by user identification.
 * @author Iris Succi
 * @date 2023
 */
const UserSelect = () => {
  const { collaborators, setCollaborators, setSelectedCollaborator } =
    useClientFileStore();

  useEffect(() => {
    getAllUsers()
      .then((res) => {
        const sortedUsers = res.data.sort((a: any, b: any) =>
          a.identifiant.localeCompare(b.identifiant)
        );
        setCollaborators(sortedUsers);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <select
      className="w-full h-9 border-[1px] rounded-sm"
      value={collaborators}
      onChange={(e) => setSelectedCollaborator(e.target.value)}
    >
      <option value=""></option>
      {collaborators.map((user) => (
        <option key={user.identifiant} value={user.identifiant}>
          {user.identifiant} - {user.firstname} {user.lastname}
        </option>
      ))}
    </select>
  );
};

export default UserSelect;
