import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get processus
 */

export const getProcessus = () => {
        try {
            const response = axiosWithToken().get(`api/processus?ids=2,3,4`)
            console.log(response)
            return response
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.statusText || "Une erreur est survenue");
              }
              throw error;
        }
}

export const getOriginsByProcessus = (processusId : number) => {
    try {
        const response = axiosWithToken().get(`api/processus/${processusId}/origines?AOR_OPTION_FACTURABLE=1`)
        console.log(response)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getOriginsByProcessusAll = (processusId : number) => {
    try {
        const response = axiosWithToken().get(`api/processus/${processusId}/origines?AOR_OPTION_FACTURABLE=2`)
        console.log(response)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}