import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete billables
 */

export const getAllFees = () => {
    try {
        const response = axiosWithToken().get(`api/billables`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
};

export const getFeesBillables = () => {
    try {
        const response = axiosWithToken().get(`api/billables?AOR_OPTION_FACTURABLE=1`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
};

export const getTVA = () => {
    try {
        const response = axiosWithToken().get(`api/billables/vat`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const postNewFee = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/billables`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getFeesSummary = (ids: any, dosRef: number) => {
    try {
        const response = axiosWithToken().get(`api/billables/summary?ids=${ids}&DOS_REF=${dosRef}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const deleteFee = (id: any) => {
    try {
        const response = axiosWithToken().delete(`api/billables?ANA_REFs=${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getFee = (id: any) => {
    try {
        const response = axiosWithToken().get(`api/billables/${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const updateFee = (id: any, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/billables/${id}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getBillableFormDatas = (selectedFile : number, selectedProcessus : number) => {
    try {
        const response = axiosWithToken().get(`api/billables/formdatas?DOS_REF=${selectedFile}&AOR_PROCESSUS=${selectedProcessus}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getBillableDisbursements = (selectedFile : number) => {
    try {
        const response = axiosWithToken().get(`api/billables/disbursements/formdatas?DOS_REF=${selectedFile}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }   
}

export const getBillableExternalDisbursements = (selectedFile : number) => {
    try {
        const response = axiosWithToken().get(`api/billables/externaldisbursements/formdatas?DOS_REF=${selectedFile}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }   
}

export const getTarifs = (AOR_REF : string, ALI_CODE: string) => {
    try {
        const response = axiosWithToken().get(`api/billables/formdatas/tarifs?AOR_REF=${AOR_REF}&ALI_CODE=${ALI_CODE}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}