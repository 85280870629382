import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / put cashinflow
 */

export const getCashInFlows = () => {
    try {
        const response = axiosWithToken().get(`api/inflow`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const postCashInFlow = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/inflow`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const updateCashInFlow = (ATR_REF: number, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/inflow/${ATR_REF}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getCashInFlow = (id: number) => {
    try {
        const response = axiosWithToken().get(`api/inflow/${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const allocateCashInFlow = (ATR_REF: number, data: any) => {
    try {
        const response = axiosWithToken().post(`api/inflow/${ATR_REF}/allocate`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const unallocateCashInFlow = (ATR_REF: number) => {
    try {
        const response = axiosWithToken().post(`api/inflow/${ATR_REF}/unallocate`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const deleteCashInFlow = (ids: any) => {
    try {
        const response = axiosWithToken().delete(`api/inflow?ATR_REFs=${ids}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getInvoicesToAllocate = (ATR_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/inflow/${ATR_REF}/invoices`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}