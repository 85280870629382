import { useTranslation } from "react-i18next";
import { modificationTime, postNewTime } from "../../../utils/TimeManager/time";
import {
  errorEntry,
  errorSendNewTime,
  errorTooMuchTime,
} from "../../../utils/toaster";
import { useUserContext } from "../../../context/UserContext";
import { dateFormatted } from "../../../utils/TimeManager/timeUtils";
import { useViewStore } from "../../../store/TimeManagement/viewStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { useDataStore } from "../../../store/TimeManagement/dataStore";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { useAddRowEditStore } from "../../../store/TimeManagement/addRowEdit";
import { editStore } from "../../../store/TimeManagement/editStore";
import { useEffect } from "react";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";
import SaveDataBeforeReturn from "../modals/SaveDataBeforeReturn";

/**
 * @component
 * @name ValidationOrAnnulationButtons
 * @author Succi Iris
 * @date 2023
 * @description
 * The `ValidationOrAnnulationButtons` component renders two buttons for validating or canceling changes, along with a "Retour" button.
 */
const ValidationOrAnnulationButtons: React.FC<any> = ({
  setValidatedSendTime,
  user,
  setRenderData,
  openModal,
  closeModal,
}) => {
  //Context
  const { id } = useUserContext();

  //Translation
  const { t } = useTranslation();
  //Hook
  const [, setIsTyping] = useUnsavedChangesWarning();

  //Store
  const { setView, setEditMode } = useViewStore();
  const { setAddRow, newLines, setNewLines } = useAddRowEditStore();
  const { updatedItems, setUpdatedItems } = useDataStore();
  const { setIsLoading } = useLoadingStore();
  const { dataListTime, resetDataListTime } = useListStore();
  const { selectedFile, setSelectedFile, setTimeId } = editStore();

  /**
   * submitDataTime Function
   * @author Succi Iris
   * @date 2023
   * @description
   * The `submitDataTime` function handles the submission of time data for validation or modification.
   *
   * @param {Function} setValidatedSendTime
   * @param {string} user
   * @param {Function} setRenderData
   * @param {Function} openModal
   * @param {Function} closeModal
   *
   * @async
   * The function is asynchronous and performs the following actions:
   *
   * 1. Checks if there are any times to be sent or modified.
   * 2. If there are times to be sent, it filters the list to remove empty items.
   * 3. It calls the `postNewTime` function to send the filtered list to the server for validation.
   * 4. It clears the list of times and resets the loading state.
   * 5. It sets the `validatedSendTime` flag to indicate successful validation.
   * 6. It clears the list of new lines, the updated items, the selected file, and the isTyping flag.
   * 7. If there are no times to be sent, it iterates through the updated items and sends any time entries with an empty time and ANA_REF set to 0.
   * 8. If any time entries were sent, it calls the `postNewTime` function to send them to the server for modification.
   * 9. It clears the list of times and resets the loading state.
   * 10. It sets the `validatedSendTime` flag to indicate successful modification.
   * 11. It clears the list of new lines, the updated items, the selected file, and the isTyping flag.
   * 12. Handles errors by logging the error message and calling the appropriate error handling functions.
   */
  const submitDataTime = async () => {
    setIsLoading(true);
    try {
      // This is a temporary fix to store the DOS_REF in the local storage
      localStorage.setItem("dosRef", JSON.stringify(dataListTime[0].DOS_REF));

      if (dataListTime.length === 0 && updatedItems.length === 0) {
        errorEntry();
      }
      if (dataListTime.length > 0) {
        const cleanDataListTime = dataListTime.filter(
          (item) => item.ANA_TEMPS !== ""
        );
        const dataReadyForSend = cleanDataListTime.filter(
          (item) => item.length !== 0
        );
        await postNewTime(dataReadyForSend);
        setValidatedSendTime((prev: any) => !prev);
        setNewLines([]);
        resetDataListTime();
        setIsLoading(false);
        setRenderData((prev: any) => !prev);
        setSelectedFile(0);
        setIsTyping(false);
        setTimeId(0);
      } else {
        const itemsToSend = [];
        for (let i = 0; i < updatedItems.length; i++) {
          const currentItem = updatedItems[i];
          for (let j = 0; j < currentItem?.Times.length; j++) {
            const currentTimes = currentItem.Times[j];
            // Vérifie si le temps est une chaîne et que ANA_REF est égal à 0
            if (
              typeof currentTimes.time === "string" &&
              currentTimes.ANA_REF === 0 &&
              currentTimes.updateTime !== null
            ) {
              const item = {
                DOS_REF: currentItem.DOS_REF,
                MIS_REF: currentItem.MIS_REF,
                ANA_TEMPS: Number(currentTimes.time),
                ANA_OPERATEUR: id,
                ANA_REDACTEUR: user,
                ANA_DATEREALISATION: currentTimes.updateTime
                  ? dateFormatted(new Date(currentTimes.updateTime))
                  : null,
                AOR_REF: currentItem.AOR_REF,
                ANA_CODELIB: currentItem.ANA_CODELIB.toString(),
                ANA_EPRIXUNITAIRE: Number(currentItem.ANA_EPRIXUNITAIRE),
                ANA_COMPLEMENTLIB: currentTimes.ANA_COMPLEMENTLIB,
                ANA_NOTES: currentTimes.ANA_NOTES,
              };

              itemsToSend.push(item);
            }
          }
        }

        if (itemsToSend.length > 0) {
          await postNewTime(itemsToSend);
          setIsLoading(false);
          setValidatedSendTime((prev: any) => !prev);
          setUpdatedItems([]);
          setSelectedFile(0);
          setIsTyping(false);
          setTimeId(0);
        } else {
          await modificationTime(updatedItems);
          setIsLoading(false);
          setValidatedSendTime((prev: any) => !prev);
          setUpdatedItems([]);
          setIsTyping(false);
          setTimeId(0);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        if (error.message === "Request failed with status code 403") {
          errorTooMuchTime();
        } else {
          errorSendNewTime();
        }
      } else {
        errorSendNewTime();
      }
      setIsLoading(false);
      setNewLines([]);
      resetDataListTime();
      setUpdatedItems([]);
      setRenderData((prev: any) => !prev);
      setIsTyping(false);
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {}, [selectedFile, updatedItems]);

  return (
    <div className="flex">
      {selectedFile !== 0 || updatedItems.length > 0 ? (
        <>
          <button
            className="w-auto lg:h-9 h-6 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-4 shadow-md"
            onClick={submitDataTime}
          >
            {t("Valider")}
          </button>
          <button
            className="w-auto lg:h-9 h-6 bg-bc-orange rounded flex justify-center items-center mr-10 text-white px-2 shadow-md transition-colors duration-200 hover:bg-bc-lightOrange"
            onClick={() => {
              setAddRow(false);
              setNewLines([]);
              setUpdatedItems([]);
              resetDataListTime();
              setTimeId(0);
            }}
          >
            {t("Annuler")}
          </button>
        </>
      ) : null}
      <button
        className="w-auto lg:h-9 h-6 bg-white border-[1px] border-bc-orange rounded flex justify-center text-bc-orange items-center mr-10 px-2 shadow-md transition-colors duration-200 "
        onClick={() => {
          if (
            selectedFile !== 0 ||
            (updatedItems.length > 0 && newLines.length !== 0)
          ) {
            openModal(<SaveDataBeforeReturn closeModal={closeModal} />);
          } else {
            setEditMode((prev: any) => !prev);
            setView("week");
            setAddRow(false);
            setNewLines([]);
            setValidatedSendTime((prev: any) => !prev);
            resetDataListTime();
            setRenderData((prev: any) => !prev);
            setUpdatedItems([]);
            setTimeId(0);
            setSelectedFile(0);
            localStorage.removeItem("dosRef");
          }
        }}
      >
        {t("Retour")}
      </button>
    </div>
  );
};

export default ValidationOrAnnulationButtons;
