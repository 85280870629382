import AlertBar from "../components/Home/AlertBar";
import Card from "../components/Home/Card";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import useModal from "../hooks/useModal";

/**
 * @page
 * @name Home
 * @description
 * Home page with the card and the alert bar
 * @author Iris Succi
 * @date 2023
 */

const Home = () => {
  //Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  return (
    <div className="w-screen h-screen overflow-hidden relative ">
      {/* MODAL */}
      {isModalOpen ? modalContent : null}
      <Header openModal={openModal} closeModal={closeModal} />

      <div className="flex flex-col justify-start items-center w-full h-screen m-auto bg-grey-Array">
        <div className="flex flex-col justify-center items-center h-2/3 w-11/12 mt-5">
          <Card />
        </div>
      </div>

      <div className="hidden 2xl:flex w-screen ">
        <AlertBar />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
