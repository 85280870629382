import { ColorTypes, PDFDocument, RGB } from 'pdf-lib';
import { deleteParentheses } from '../TimeManager/timeUtils';

export const weekTimGeneratePdf = async (days7: any, weekData: any) => {

    try {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([842, 595]);

        const outerMargin = 20;
        const borderThickness = 1;

        const pageWidth = 842 - 2 * outerMargin;
        const columnWidth = pageWidth / 7;

        const headerBackgroundColor: RGB = {
            type: ColorTypes.RGB,
            red: 243/255,
            green: 183/255,
            blue: 45/255
        };

        const borderColor: RGB = {
            type: ColorTypes.RGB,
            red: 50/255,
            green: 50/255,
            blue: 50/255
        };

        // 1. Transformer la déclaration de la fonction en une expression de fonction
        const splitText = (text: string, maxLength: number): string[] => {
            const result: string[] = [];
            let index = 0;

            while (index < text.length) {
                result.push(text.substr(index, maxLength));
                index += maxLength;
            }

            return result;
        };

        const drawHeader = (page: any, xPosition: number, yPosition: number, items: any) => {
            items.forEach((item: any, index: number) => {
                const titleToDraw = item.dayOfWeek;

                // Rectangle de fond pour l'en-tête
                page.drawRectangle({
                    x: xPosition + (index * columnWidth),
                    y: yPosition - 8,
                    width: columnWidth,
                    height: 24,
                    color: headerBackgroundColor,
                });

                // Bordure autour de chaque colonne
                page.drawRectangle({
                    x: xPosition + (index * columnWidth),
                    y: yPosition - 8,
                    width: columnWidth,
                    height: 24,
                    borderColor: borderColor,
                    borderThickness: borderThickness,
                });

                // Texte
                page.drawText(titleToDraw, {
                    x: xPosition + (index * columnWidth) + (columnWidth / 2) - (titleToDraw.length * 3),
                    y: yPosition,
                    size: 12,
                });

            });
        }


        let yPosition = (595 / 2) + (weekData.length * 10); // Centre vertical en fonction du nombre de lignes
        const xPosition = outerMargin;

        drawHeader(page, xPosition, yPosition, days7);
        yPosition -= 8;

        const cellHeight = 80;

        weekData.forEach((dayData: any, colIndex: number) => {
            dayData.forEach((itemData: any, rowIndex: number) => {
                
                // Exemple : dessiner le TD_FIC_TITRE_CLIENT pour l'item
                const textToDraw = itemData.TD_FIC_TITRE_CLIENT;
                const libelleToDraw = itemData.AO_AOR_LIBELLE;
                const libelleLibToDraw = itemData.AA_ANA_LIBELLELIB;
                const missionToDraw = deleteParentheses(itemData.VM_MIS_LIBELLE);
                const timeToDraw = "Total : " + itemData.AA_ANA_TEMPS;
                
                // 2. Dessiner chaque segment
                const drawTextWithWrap = (text: string, x: number, y: number, maxChars: number, lineSpacing: number) => {
                    const segments = splitText(text, maxChars);
                    
                    segments.forEach((segment, idx) => {
                        page.drawText(segment, {
                            x: x,
                            y: y - (idx * lineSpacing),
                            size: 8,
                        });
                    });

                    return segments.length; // Retourne le nombre de lignes écrites
                };

                // Position pour dessiner le texte
                const textXPosition = xPosition + (colIndex * columnWidth) + 2;  // 2 pixels à partir de la gauche
                const textYPosition = (yPosition + (rowIndex * cellHeight) - cellHeight) + 2;

                
                // Dessin de la bordure pour chaque cellule
                page.drawRectangle({
                    x: xPosition + (colIndex * columnWidth),
                    y: yPosition - (rowIndex * cellHeight) - cellHeight,
                    width: columnWidth,
                    height: cellHeight,
                    borderColor: borderColor,
                });
                
                // fonction pour dessiner le texte avec découpage
                const linesWritten = drawTextWithWrap(textToDraw, textXPosition, textYPosition - 10, 25, 10);

                // Ajuster la position y pour les autres textes en fonction du nombre de lignes écrites
                const newYPosition = textYPosition - 10 - (linesWritten * 10);
                page.drawText(libelleToDraw, {
                    x: textXPosition,
                    y: newYPosition,
                    size: 8,
                });

                
                page.drawText(libelleToDraw, {
                    x: textXPosition,
                    y: textYPosition - 20,
                    size: 8,
                });

                page.drawText(libelleLibToDraw, {
                    x: textXPosition,
                    y: textYPosition - 30,
                    size: 8,
                });

                page.drawText(missionToDraw, {
                    x: textXPosition,
                    y: textYPosition - 40,
                    size: 8,
                });

                page.drawText(timeToDraw, {
                    x: textXPosition,
                    y: textYPosition - 50,
                    size: 8,
                });

            });
        });


        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open(url);

        if (pdfWindow) {
            pdfWindow.onload = () => {
                pdfWindow.print();
            };
        } else {
            console.error("La fenêtre pop-up a été bloquée ou une autre erreur s'est produite.");
        }

    } catch (error) {
        console.error("Erreur lors de la génération du PDF:", error);
    }
};
