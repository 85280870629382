import Select from "react-select";
import { getAllDossiers } from "../../../utils/folders";
import { useEffect } from "react";
import { useClientFileStore } from "../../../store/FinancialStatement/inputStore";

/**
 * @component
 * @name ClientFileSelect
 * @description
 * A component that renders a select dropdown for selecting a client dossier file.
 * The component retrieves the list of all client dossiers from the API and sorts
 * them by client title.
 * @author Iris Succi
 * @date 2023
 */
const ClientFileSelect = () => {
  const { clientsFile, setClientsFile, selectedBinder, setSelectedClientFile } =
    useClientFileStore();

  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const sortedData = res.data.sort((a: any, b: any) =>
          a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT)
        );
        const formattedFiles = sortedData.map((file: any) => ({
          value: file.DOS_REF,
          label: file.FIC_TITRE_CLIENT,
        }));
        setClientsFile(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Select
      options={clientsFile}
      isClearable
      className="w-full h-9 z-40 rounded-sm mt-2 p-1 font-thin"
      isDisabled={selectedBinder.length >= 1}
      isSearchable
      onChange={(selectedOption) => {
        setSelectedClientFile(selectedOption?.value || 0);
      }}
    />
  );
};

export default ClientFileSelect;
