import { useState, useEffect } from "react";
/**
 * @hook
 * @name usePagination
 * @author Iris Succi
 * @date 2023
 * A custom React hook for implementing pagination logic.
 *
 * This hook manages the pagination of a dataset. It calculates the total number of pages based on the
 * provided data and the number of items per page. It also manages the current page and the range of items
 * displayed per page. Additionally, it allows for setting the start and end page numbers for display purposes.
 *
 * @param {Array} data - The dataset to be paginated.
 * @param {number} itemsPerPage - The number of items to display per page.
 *
 * @returns {Object} An object containing:
 *  - currentPage: The current page number.
 *  - setCurrentPage: A function to update the current page number.
 *  - startPage: The start page number for pagination controls.
 *  - setStartPage: A function to set the start page number.
 *  - endPage: The end page number for pagination controls.
 *  - setEndPage: A function to set the end page number.
 *  - filteredData: The subset of data for the current page.
 *  - setFilteredData: A function to manually set the filtered data.
 *  - totalPageCount: The total number of pages.
 *  - indexOfLastItem: The index of the last item on the current page.
 *  - indexOfFirstItem: The index of the first item on the current page.
 *
 * Usage:
 * const { currentPage, setCurrentPage, ... } = usePagination(data, itemsPerPage);
 *
 * This hook is typically used in components that require paginated data display, such as tables or lists.
 */
export const usePagination = (data: any, itemsPerPage: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const totalPageCount = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Triez les données avant de les paginer
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.ANA_DATEREALISATION).getTime();
    const dateB = new Date(b.ANA_DATEREALISATION).getTime();
    return dateB - dateA; // Triez du plus récent au plus ancien
  });

  useEffect(() => {
    setFilteredData(sortedData.slice(indexOfFirstItem, indexOfLastItem));
  }, [data, indexOfFirstItem, indexOfLastItem]);

  return {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    setFilteredData,
    totalPageCount,
    indexOfLastItem,
    indexOfFirstItem,
  };
};
