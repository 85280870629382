import React, { useState } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsByDos } from "../../../../utils/customerFile";
import InterlocuteursTree from "./InterlocuteursTree";
import users from "../../../../assets/icons/users.svg";

/**
 * @component
 * @name ParticipantsTree
 * @description A component that displays a participant category and its list of intervenants.
 * @author Iris Succi
 * @date 2023
 */
const ParticipantsTree: React.FC<any> = ({
  category,
  closeModal,
  openModal,
}) => {
  // Store
  const { participants, setParticipants, dosRef, PENT_ID, setPENT_ID } =
    useExplorer();

  // State
  const [isOpen, setIsOpen] = useState(false);
  /**
   * @function
   * @name handleOpen
   * @description
   * Handles the open/close state of the participants tree and fetches the list of intervenants if it is not already fetched.
   * @author Iris Succi
   * @date 2023
   * @param {() => void}
   */
  const handleOpen = () => {
    if (!isOpen && PENT_ID !== undefined) {
      getParticipantsByDos(dosRef, PENT_ID)
        .then((res) => setParticipants(res.data))
        .catch((err) => console.log(err));
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full relative ml-4">
      <div className="flex justify-start items-center cursor-pointer">
        <img src={users} alt="users" />
        <div className="flex flex-col">
          {category?.SubEntities && category.SubEntities.length > 0 && (
            <div className="ml-4">
              {category.SubEntities.map((subEntity: any) => (
                <div
                  key={subEntity.PENT_ID}
                  className="pl-4"
                  onClick={() => {
                    setPENT_ID(subEntity.PENT_ID);
                    handleOpen();
                  }}
                >
                  {subEntity.PENT_NOM}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isOpen &&
        participants &&
        participants?.length > 0 &&
        participants?.map((intervenants: any) => (
          <div className="relative">
            <div className="border-l-2 absolute top-0 bottom-0 left-[10px]" />
            <InterlocuteursTree
              intervenants={intervenants}
              closeModal={closeModal}
              openModal={openModal}
            />
          </div>
        ))}
    </div>
  );
};

export default ParticipantsTree;
