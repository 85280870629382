import { useExplorer } from "../../../store/Crm/Explorer";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";

/**
 * @component
 * @name Analytic
 * @description Analytic est un composant qui permet d'afficher les données analytiques d'un dossier client. Temps, Honoraires, Compte client, Frais.
 * @author Iris Succi
 * @date 2024
 */
const Analytic = () => {
  const { choiceTab } = useUtilsCrmStore();
  const { ficRef } = useExplorer();

  return choiceTab === "Analytique" && ficRef !== 0 ? (
    <div className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-md overflow-scroll text-sm">
      <p className="text-2xl text-bc-darkGrey 2xl:my-6">
        Nom du dossier : Analytique
      </p>
      <div className="flex flex-col w-11/12 h-auto">
        <div className="flex justify-center items-center">
          <p className="w-28 font-bold">Temps </p>
          <hr className="h-0.5 w-full bg-gray-700" />
        </div>
        <div className="w-10/12 m-auto grid grid-cols-4 mt-5">
          <p className="col-start-1 col-end-2">
            Plan de charge : <i></i>
          </p>
          <p className="col-start-2 col-end-3">
            Réalisés : <i></i>
          </p>
          <p className="col-start-3 col-end-4">
            Ecart : <i></i>
          </p>
        </div>
        <div className="flex justify-center items-center mt-5">
          <p className="w-28 font-bold">Honoraires </p>
          <hr className="h-0.5 w-full bg-gray-700" />
        </div>
        <div className="w-10/12 m-auto grid grid-cols-4 mt-5">
          <p className="col-start-1 col-end-2">
            Budgétés : <i></i>
          </p>
          <p className="col-start-2 col-end-3">
            Valorisation temps passés : <i></i>
          </p>
          <p className="col-start-3 col-end-4">
            Facturés : <i></i>
          </p>
          <p className="col-start-4 col-end-5">
            Reste à facturer : <i></i>
          </p>
          <p className="col-start-1 col-end-2 mt-4">
            Ecart/Budget : <i></i>
          </p>
          <p className="col-start-2 col-end-3 mt-4">
            Avancement : <i></i>
          </p>
          <p className="col-start-1 col-end-2 mt-4">
            Facturation : <i></i>
          </p>
          <p className="col-start-2 col-end-3 mt-4">
            <i></i>
          </p>
          <p className="col-start-3 col-end-4 mt-4">
            A facturer : <i></i>
          </p>
          <p className="col-start-4 col-end-5 mt-4">
            Reste à faire : <i></i>
          </p>
          <p className="col-start-1 col-end-2 mt-4">
            Complémentaire cabinet : <i></i>
          </p>
          <p className="col-start-2 col-end-3 mt-4">
            Acquisition cabinet : <i></i>
          </p>
          <p className="col-start-3 col-end-4 mt-4"></p>
          <p className="col-start-4 col-end-5 mt-4">
            PCA : <i></i>
          </p>
        </div>
        <div className="flex justify-center items-center mt-5">
          <p className="w-32 font-bold">Compte client </p>
          <hr className="h-0.5 w-1/2 bg-gray-700 mr-4" />
          <p className="w-28 font-bold text-center">Frais </p>
          <hr className="h-0.5 w-1/2 bg-gray-700" />
        </div>
        <div className="w-10/12 m-auto flex">
          <div className="w-1/2 flex flex-col">
            <p className="mt-4">
              Report à nouveau : <i></i>
            </p>
            <p className="mt-4">
              Factures émises : <i></i>
            </p>
            <p className="mt-4">
              Avoirs : <i></i>
            </p>
            <p className="mt-4">
              Acompte : <i></i>
            </p>
            <p className="mt-4">
              Réglements recus : <i></i>
            </p>
          </div>
          <div className="w-1/2 flex flex-col ml-44">
            <p className="mt-4">
              Engagés : <i></i>
            </p>
            <p className="mt-4">
              Facturés : <i></i>
            </p>
            <p className="mt-4">
              A facturer : <i></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Analytic;
