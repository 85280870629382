import { useEffect, useRef } from "react";
import { useLoadingStore } from "../../store/utils/loading";
import CircleLoarder from "../TimeManager/loader/CircleLoarder";
import { usePagination } from "../../hooks/usePagination";
import CreateCost from "./CreateCost";
import { useCostStore } from "../../store/Cost/Cost";
import { useSearchBarStore } from "../../store/utils/searchBar";
import { dateFormattedFr } from "../../utils/TimeManager/timeUtils";
import check from "../../assets/icons/check.png";
/**
 * @component
 * @name CostTable
 * @description
 * CostTable est le composant qui permet d'afficher le tableau des frais.
 * @author Iris Succi
 * @date 2023
 */
const CostTable = () => {
  const { isLoading } = useLoadingStore();
  const {
    displayCreateCost,
    costs,
    costsFilter,
    setCostsFilter,
    selectedIdsCosts,
    setSelectedIdsCosts,
    setSelectedCostsForValidate,
  } = useCostStore();
  const { search } = useSearchBarStore();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(costsFilter, 30);

  useEffect(() => {
    let data = costs?.length > 0 ? [...costs] : [...costsFilter];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_REDACTEUR"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTHT"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trier par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Trier du plus récent au plus ancien
    });

    setCostsFilter(data);
  }, [search, costs]);

  const handleCheckboxChange = (item: any, isChecked: any) => {
    const { ANA_REF, DOS_REF, ANA_REDACTEUR } = item; // Extrait les champs nécessaires
    const newItem = {
      ANA_REF: ANA_REF,
      DOS_REF: DOS_REF,
      UTI_CODE: ANA_REDACTEUR.trim(),
    };

    if (isChecked) {
      // Ajoute l'élément à l'état si la case est cochée
      setSelectedCostsForValidate((prev: any) => [...prev, newItem]);
      setSelectedIdsCosts((prev) => [...prev, item.ANA_REF]);
    } else {
      // Retire l'élément de l'état si la case est décochée
      setSelectedCostsForValidate((prev: any) =>
        prev.filter((el: any) => el.ANA_REF !== item.ANA_REF)
      );
      setSelectedIdsCosts((prev) => prev.filter((id) => id !== item.ANA_REF));
    }
  };

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : costs.length > 0 ? (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[2%] border-r-[1px] flex justify-center items-center"></tr>
                <tr className="w-[5%] border-r-[1px] p-2">Etat</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Date</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Mission</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Origine</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Libellé</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Acteur</tr>
                <tr className="w-[10%] border-r-[1px] p-2">P.U</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Quantité</tr>
                <tr className="w-[5%] border-r-[1px] p-2">H.T</tr>
                <tr className="w-[5%] border-r-[1px] p-2">NF</tr>
                <tr className="w-[20%] border-r-[1px] p-2">Commentaire</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((cost, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      cost?.Lié === false && cost?.Pointé === false
                        ? "bg-bc-orange-pale"
                        : index % 2 === 0
                        ? "bg-grey-Array"
                        : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      {cost?.ANA_REFPERE === null ? (
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          value={cost.ANA_REF}
                          checked={selectedIdsCosts.includes(cost.ANA_REF)}
                          onChange={(e) => {
                            handleCheckboxChange(cost, e.target.checked);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {cost?.ANA_REFPERE !== null ? (
                        <img
                          src={check}
                          alt="check"
                          className="2xl:w-5 2xl:h-5 2xl:ml-2 h-3 w-3"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {dateFormattedFr(cost?.ANA_DATEREALISATION)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cost?.DOS_TITRE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cost?.MIS_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cost?.AOR_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cost?.ANA_LIBELLELIB}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {cost?.ANA_REDACTEUR}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cost?.ANA_EPRIXUNITAIRE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {cost?.ANA_QUANTITE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {cost?.ANA_EMTHT}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {cost?.DOS_NOFACTURABLE === 1 ||
                      cost?.AOR_OPTION_FACTURABLE === 0
                        ? "x"
                        : ""}
                    </td>
                    <td className="w-[20%] border-r-[1px] p-2">
                      {cost?.ANA_NOTES}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucun frais</p>
        )}
        {displayCreateCost ? <CreateCost /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default CostTable;
