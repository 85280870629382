import { create } from 'zustand';

type TabsStore = {
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;

    sendData: boolean;
    setSendData: (sendData: boolean | ((prev: boolean) => boolean)) => void;

    displayPointOutAcompteQuestion: boolean;
    setDisplayPointOutAcompteQuestion: (displayPointOutAcompteQuestion: boolean | ((prev: boolean) => boolean)) => void;
};

export const useTabsStore = create<TabsStore>((set, get) => ({
    choiceTab: 'Honoraire',
    setChoiceTab: (choiceTab) => set({ choiceTab }),

    sendData: false,
    setSendData: (value) => {
        if (typeof value === "function") {
            const currentSendData = get().sendData;
            set({ sendData: value(currentSendData) });
        } else {
            set({ sendData: value });
        }
    },

    displayPointOutAcompteQuestion: false,
    setDisplayPointOutAcompteQuestion: (value) => {
        if (typeof value === "function") {
            const currentDisplayPointOutAcompte = get().displayPointOutAcompteQuestion;
            set({ displayPointOutAcompteQuestion: value(currentDisplayPointOutAcompte) });
        } else {
            set({ displayPointOutAcompteQuestion: value });
        }
    },
}));
