import { useTabsFSStore } from "../../../store/FinancialStatement/tabsStore";
import YearSelect from "../select/YearSelect";
import GroupeSelect from "../select/GroupeSelect";
import UserSelect from "../select/UserSelect";
import ClientFileSelect from "../select/ClientFileSelect";

/**
 * @component
 * @name Workload
 * @description
 * A component that generates a PDF report for the selected workload type.
 * The supported workload types are:
 *
 * - **Plan de charge par collab:** Displays a workload report for a specific collaborator.
 * - **Plan de charge synthétique:** Provides a summary of the company's workload.
 * - **Plan de charge par dossier:** Shows the workload for a specific client dossier.
 *
 * The component also allows users to filter the report by year and
 * select the group, collaborator, and client dossier.''
 * Note : This component is not used in the current version of the app.
 * @author Iris Succi
 * @date 2023
 */
const Workload = () => {
  const { workloadTypes, workloadTypeSelected, setWorkloadTypeSelected } =
    useTabsFSStore();

  return (
    <div className="w-[540px] h-[80%] border-[1px] ">
      <div className="w-11/12 m-auto flex flex-col justify-start items-start">
        <p className="mt-5 font-semibold">Type d'export :</p>
        <select
          name="type"
          id="type"
          className="border-[1px] h-9 rounded-sm mt-2 p-1 font-thin"
          onChange={(e) => setWorkloadTypeSelected(e.target.value)}
        >
          {workloadTypes.map((data) => (
            <option value={data}>{data}</option>
          ))}
        </select>

        <p className="mt-10 font-semibold">Année civile :</p>
        <YearSelect />

        {workloadTypeSelected === "Plan de charge par collab" ||
        workloadTypeSelected === "Plan de charge synthétique" ? (
          <>
            <p className="mt-10 font-semibold mb-2">Groupe :</p>
            <GroupeSelect />
            <p className="mt-10 font-semibold mb-2">Collaborateur :</p>
            <UserSelect />
          </>
        ) : null}

        {workloadTypeSelected === "Plan de charge par dossier" ? (
          <>
            <p className="mt-10 font-semibold mb-2">Dossier :</p>
            <ClientFileSelect />
          </>
        ) : null}
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <button className="w-40 h-8 border-[1px] rounded-sm border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer">
          Générer le rapport
        </button>
      </div>
    </div>
  );
};

export default Workload;
