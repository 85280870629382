import { create } from 'zustand';

type utilsCrm = {
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;
    
    firstPage: boolean;
    setFirstPage: (firstPage: boolean) => void;

    documentsList: any[];
    setDocumentsList: (documentsList: any[]) => void;

    missions : any[];
    setMissions : (missions : any[]) => void;
}

export const useUtilsCrmStore = create<utilsCrm>((set) => ({ 
    choiceTab: "",
    setChoiceTab: (choiceTab) => set({ choiceTab }),

    firstPage: true,
    setFirstPage: (firstPage) => set({ firstPage }),

    documentsList: [],
    setDocumentsList: (documentsList) => set({ documentsList }),

    missions : [],
    setMissions : (missions) => set({ missions })
}));