import axios from "axios"
import { axiosWithToken } from "./axiosService"

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get participants
 */

export const getParticipantsCategories = async (dosRef: number) => {
    try{
        const response = await axiosWithToken().get(`api/participants/categories?PDOS_ID=${dosRef}&PFIC_ID=1`)
        return response.data
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}