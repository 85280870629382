import { useEffect } from "react";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import chevron from "../../../../assets/icons/chevron-right.svg";
import Select from "react-select";
import { getClientsByDOS, getContacts } from "../../../../utils/customerFile";
import { useCreateProformaStore } from "../../../../store/Invoice/createProforma";
import { getProformaOrigins } from "../../../../utils/Invoice/origin";
import {
  getProformaById,
  postProforma,
  updateProforma,
} from "../../../../utils/Invoice/invoicing";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import {
  errorEntry,
  errorSelectFee,
  errorSendNewTime,
  successProforma,
  successUpdateData,
} from "../../../../utils/toaster";
import { useLoadingStore } from "../../../../store/utils/loading";
import TableUpdateProforma from "./TableUpdateProforma";
import TableCreateProformaTotal from "./TableCreateProformaTotal";
/**
 * @component
 * @name CreateProformaWithFee
 * @description A React component for creating or editing proforma invoices with associated fees. It utilizes several custom hooks for state management, data fetching, and interaction with backend services. The component provides a form interface for selecting various options (like interlocutors, origins, clients, etc.) and entering details necessary for proforma creation or modification. It handles state changes, user inputs, and submits the proforma data to the backend for processing.
 *
 * The component also manages display states and animations for the form, providing a dynamic user experience. It is designed to work within a larger application context, interacting with various stores and contexts to fetch and manage the data needed.
 *
 * @prop {boolean} createProformat - Indicates whether to create a new proforma invoice or edit an existing one.
 * @prop {boolean} displayDiv - Controls the display of the component.
 * @prop {Function} setDisplayDiv - Function to set the display state of the component.
 * @prop {Function} setAnimationClass - Function to set the animation class for the component.
 * @prop {string[]} selectedIdsInvoices - Array of selected invoice IDs for the proforma.
 * @prop {Function} setSelectedIdsInvoices - Function to set the selected invoice IDs.
 * @prop {Function} handleCreateProforma - Function to handle the creation of a new proforma invoice.
 * @prop {Function} handleUpdateProforma - Function to handle the update of an existing proforma invoice.
 * @prop {Function} reinitializeFields - Function to reinitialize all form fields.
 *
 * @author Iris Succi
 * @date 2023
 */
const CreateProformaWithFee = () => {
  // Store
  const { createProformat, setCreateProformat } = useCreateFeesOrInvoices();
  const { setChoiceTab, setSendData } = useTabsStore();
  const { setIsLoading } = useLoadingStore();
  const {
    startDate,
    setStartDate,
    interlocutors,
    setInterlocutors,
    selectedInterlocutor,
    setSelectedInterlocutor,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    setSelectedMask,
    dataProforma,
    setDataProforma,
    modeProforma,
    proformaDataUpdate,
    setProformaDataUpdate,
    checkedFees,
  } = useCreateProformaStore();

  const {
    animationClass,
    setAnimationClass,
    displayDiv,
    setDisplayDiv,
    selectedIdsInvoices,
    selectedDosRef,
    setSelectedDosRef,
    selectedMisRef,
    setSelectedMisRef,
    selectedMisName,
    setSelectedMisName,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();

  /**
   * @function
   * @author Iris Succi
   * @date 2023
   * @description This is for display or not the div for create proformat
   */
  useEffect(() => {
    if (createProformat === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createProformat]);

  /**
   * @description Fetches and sets the masques, clients, origines, and interlocutors data based on the selected dossier reference.
   * @author Iris Succi
   * @date 2023
   * @param {boolean} displayDiv - Whether or not to display the component
   */
  useEffect(() => {
    /* getProformaMasks()
      .then((res) => {
        const formattedFiles = Object.entries(res.data).map(([key, value]) => ({
          value: key,
          label: value,
        }));
        setMasks(formattedFiles);
      })
      .catch((err) => console.log(err));
 */
    getClientsByDOS(selectedDosRef)
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.FIC_REF,
          label: file.DOS_CODE + " - " + file.FIC_TITRE_ETAT,
        }));
        setClients(formattedFiles);
        setSelectedClient(formattedFiles[0]?.value || 0);
        setDataProforma("FIC_REF_CLIENT", formattedFiles[0]?.value || 0);
      })
      .catch((err) => console.log(err));

    getProformaOrigins()
      .then((res) => {
        const formattedOrigins = res?.data?.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
          libelles: origin.Libelles.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_LIBELLE,
          })),
        }));

        setOrigins(formattedOrigins);
      })
      .catch((err) => console.log(err));

    getContacts(selectedDosRef)
      .then((res) => {
        const formattedFiles = res?.data.map((contact: any) => ({
          value: contact.FIC_REF,
          label: contact.name + " " + contact.firstname,
        }));
        setInterlocutors(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, [displayDiv]);

  useEffect(() => {
    setDataProforma("ANA_REFs", selectedIdsInvoices);
    setDataProforma("DOS_REF", selectedDosRef);
    setDataProforma("MIS_REF", selectedMisRef);
  }, [selectedIdsInvoices, selectedLibelle]);

  /**
   * @function
   * @name handleOriginChange
   * @author Iris Succi
   * @date 2023
   * @description
   * Handles the change event of the origin select field. Updates the state of the selected origin and libelles variables.
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event object
   */
  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    setDataProforma("AOR_REF", selectedOrigin);

    const selectedOriginObj = origins.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    }
  };

  useEffect(() => {
    setDataProforma("ANA_DATEREALISATION", startDate);
    setDataProforma("DOS_REF", selectedDosRef);
    setDataProforma("MIS_REF", selectedMisRef);
  }, [startDate]);

  /**
   * @function
   * @author Iris Succi
   * @date 2023
   * @name handleCreateProforma
   * @description This is for create proforma
   */
  const handleCreateProforma = async () => {
    if (
      selectedDosRef === 0 ||
      selectedLibelle === "" ||
      selectedOrigin === ""
    ) {
      return errorEntry();
    }
    try {
      setIsLoading(true);
      setDisplayDiv(false);
      const response = await postProforma(dataProforma);
      console.log("Succès:", response);
      setSelectedIdsInvoices((_prev) => []);
      setSendData((prev) => !prev);
      setCreateProformat(false);
      setChoiceTab("Proformat");
      reinitializeFields();
      successProforma();
    } catch (error) {
      setIsLoading(false);
      errorSendNewTime();
      reinitializeFields();
      console.error("Erreur:", error);
    }
  };

  /**
   * @function
   * @author Iris Succi
   * @date 2023
   * @name reinitializeFields
   * @description This is for reinitialize all fields
   */
  const reinitializeFields = () => {
    setSelectedClient(0);
    setSelectedInterlocutor(0);
    setSelectedOrigin("");
    setSelectedLibelle("");
    setLibelles([]);
    setSelectedDosRef(0);
    setSelectedMisRef(0);
    setSelectedMask("");
    setInterlocutors([]);
    setSelectedIdsInvoices((_prev) => []);
    setDataProforma("FIC_REF_CLIENT", 0);
    setDataProforma("FIC_REF_INTERLOCUTEUR", 0);
    setDataProforma("ANA_CODELIB", "");
    setDataProforma("mask", "");
    setDataProforma("AOR_REF", "");
    setSelectedMisName("");
  };

  /******************* UPDATE PROFORMA ********************************/
  /**
   * @description Retrieves and updates the proforma data if the modeProforma is "edit".
   * @author Iris Succi
   * @date 2023
   * @param {string} modeProforma - Whether the proforma is to be created or edited
   * @param {number[]} selectedIdsInvoices - An array of the selected invoice IDs
   */
  useEffect(() => {
    if (modeProforma === "edit") {
      setIsLoading(true);
      try {
        getProformaById(selectedIdsInvoices[0])
          .then((res) => {
            const data = res.data[0];
            const selectedClientObj = clients?.find((client) => {
              return client.value === data.FIC_REF_CLIENT;
            });
            setSelectedClient(selectedClientObj?.value || 0);

            const selectedOriginObj = origins?.find(
              (origin) => origin.value === data.AOR_REF
            );
            setSelectedOrigin(selectedOriginObj?.value || 0);
            setLibelles(selectedOriginObj.libelles);

            if (selectedOriginObj !== "") {
              const selectedLibelleObj = selectedOriginObj.libelles.find(
                (libelle: any) => {
                  return libelle.value === data.ANA_CODELIB;
                }
              );
              setSelectedLibelle(selectedLibelleObj?.value || 0);
            }

            let startDateValue = new Date();
            if (data.ANA_DATEREALISATION) {
              const tempDate = new Date(data.ANA_DATEREALISATION);
              if (!isNaN(tempDate.getTime())) {
                startDateValue = tempDate;
              }
            }
            setStartDate(startDateValue);

            const selectedInterlocutorObj = interlocutors?.find(
              (interloc) => interloc.value === data.FIC_REF_INTERLOCUTEUR
            );
            setSelectedInterlocutor(selectedInterlocutorObj?.value || 0);
            setSelectedMisName(data.MIS_LIBELLE);
            setSelectedMisRef(data.MIS_REF);

            setProformaDataUpdate(data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  }, [modeProforma, selectedIdsInvoices]);

  /**
   * @function
   * @author Iris Succi
   * @date 2023
   * @name handleUpdateProforma
   * @description This is for update proforma
   */
  const handleUpdateProforma = async () => {
    const updateData = {
      DOS_REF: selectedDosRef,
      AOR_REF: selectedOrigin,
      MIS_REF: selectedMisRef,
      FIC_REF_CLIENT: selectedClient,
      ANA_DATEREALISATION: startDate,
      FIC_REF_INTERLOCUTEUR: selectedInterlocutor,
      ANA_CODELIB: selectedLibelle,
      ANA_REFs: checkedFees,
    };

    if (selectedOrigin === 0 || selectedLibelle === 0) {
      return errorEntry();
    }
    if (checkedFees.length === 0) return errorSelectFee();
    setIsLoading(true);

    try {
      setDisplayDiv(false);
      const response = await updateProforma(selectedIdsInvoices[0], updateData);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setCreateProformat(false);
      setIsLoading(false);
      setChoiceTab("Proformat");
      successUpdateData();
      reinitializeFields();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-center h-full w-full">
            <button
              className="h-full w-10 bg-gray-600 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsInvoices((_prev) => []);
                setCreateProformat(false);
                reinitializeFields();
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[80%] h-[90%] m-auto">
              <div className="flex justify-center items-center w-full">
                {modeProforma === "create" ? (
                  <p className="font-bold text-lg mb-5">
                    Création d'une proforma
                  </p>
                ) : (
                  <p className="font-bold text-lg mb-5">
                    Modification d'une proforma
                  </p>
                )}
              </div>
              <div className="grid grid-cols-7 w-full m-auto gap-4">
                {/* {modeProforma === "create" ? (
                  <>
                    <div className="col-start-1 col-end-2 ">Compteur :</div>
                    <div className="flex flex-col col-start-2 col-end-4">
                      <select
                        name="masks"
                        id="masks"
                        className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                        value={selectedMask}
                        onChange={(event) => {
                          setSelectedMask(event.target.value);
                          setDataProforma("mask", event.target.value);
                        }}
                      >
                        <option value={""}></option>
                        {masks?.map((mask, i) => (
                          <option key={i} value={mask?.value}>
                            {mask?.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : ( */}
                {modeProforma === "edit" && (
                  <>
                    <p className="col-start-1 col-end-3">Numéro de facture :</p>
                    <p className="flex flex-col col-start-3 col-end-4">
                      {proformaDataUpdate?.ANA_FACTURE}
                    </p>
                  </>
                )}
                {/* )} */}
                <div className="col-start-5 col-end-6 mt-1">Date :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <Datepicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
                <div className="col-start-1 col-end-2 mt-1">Client :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  {modeProforma === "edit" ? (
                    <Select
                      options={clients}
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={
                        modeProforma === "edit"
                          ? clients.find(
                              (option) => option.value === selectedClient
                            )
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedClient(selectedOption?.value || 0);
                      }}
                    />
                  ) : (
                    <Select
                      options={clients}
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={
                        dataProforma.FIC_REF_CLIENT !== null
                          ? clients.find(
                              (option) =>
                                option.value === dataProforma.FIC_REF_CLIENT
                            )
                          : null
                      }
                      onChange={(selectedClient: any | null) => {
                        setSelectedClient(selectedClient?.value || 0);
                        setDataProforma(
                          "FIC_REF_CLIENT",
                          selectedClient?.value
                        );
                      }}
                    />
                  )}
                </div>
                <div className="col-start-5 col-end-6 mt-1">Interloc. :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="Interloc"
                    id="Interloc"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedInterlocutor}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSelectedInterlocutor(parseInt(value));
                      setDataProforma("FIC_REF_INTERLOCUTEUR", parseInt(value));
                    }}
                  >
                    <option value={""}></option>
                    {interlocutors?.map((interloc, i) => (
                      <option key={i} value={interloc?.value}>
                        {interloc?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-1 col-end-2 mt-1">Origine :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="origin"
                    id="origin"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={handleOriginChange}
                  >
                    <option value={""}></option>
                    {origins?.map((origin, i) => (
                      <option key={i} value={origin?.value}>
                        {origin?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">Libellé :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="Libellé"
                    id="Libellé"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedLibelle}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const value = event.target.value;
                      setSelectedLibelle(value);
                      setDataProforma("ANA_CODELIB", value);
                    }}
                  >
                    <option value={""}></option>
                    {libelles?.map((libelle, i) => (
                      <option key={i} value={libelle?.value}>
                        {libelle?.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-start-1 col-end-2 mt-1">Mission :</div>
                <div className="col-start-2 col-end-4 mt-1">
                  {selectedMisName}
                </div>

                <button
                  className="col-start-6 col-end-7 w-full h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                  onClick={() => {
                    setChoiceTab("Proformat");
                    setCreateProformat(false);
                    setSelectedIdsInvoices((_prev) => []);
                    reinitializeFields();
                  }}
                >
                  Annuler{" "}
                </button>
                <button
                  className="col-start-7 col-end-8 w-full h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                  onClick={() => {
                    if (modeProforma === "edit") handleUpdateProforma();
                    else handleCreateProforma();
                  }}
                >
                  Valider
                </button>
                <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              </div>
              <div className="w-[98%] m-auto h-full">
                {modeProforma === "create" ? (
                  <TableCreateProformaTotal />
                ) : (
                  <TableUpdateProforma />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateProformaWithFee;
