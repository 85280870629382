/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Dropdown from "react-dropdown-select";
import { allUsers } from "../../../interface/data/user";
import { SelectOptionsUsers } from "../../../interface/selects";
/**
 * @component
 * @name SelectUsersFilterTime
 * @author Succi Iris
 * @date 2023
 * @description
 * `SelectUsersFilterTime` is a React Functional Component that provides a multi-select dropdown for user selection.
 * It is specifically designed to filter users for time-related data.
 *
 * @param {Object} props - The props passed to the SelectUsersFilterTime component.
 * @param {Array} props.users - An array of user objects to be displayed in the dropdown.
 * @param {Array} props.selectedUsersActeurs - An array of selected user identifiers.
 * @param {Function} props.setSelectedUsersActeurs - A function to update the selected users in the parent component.
 * @param {Function} props.setSelectedAllUsers - A function to update the state when all users are selected.
 */
const SelectUsersFilterTime: React.FC<SelectOptionsUsers> = ({
  users,
  selectedUsersActeurs,
  setSelectedUsersActeurs,
  setSelectedAllUsers,
}) => {
  const handleSelectChange = (selectedValues: any) => {
    if (selectedValues && selectedValues.length > 0) {
      const selectedUsersArray = selectedValues
        .filter((option: any) => option !== undefined)
        .map((option: any) => option.value);
      setSelectedUsersActeurs(selectedUsersArray);
    } else {
      setSelectedUsersActeurs([]);
    }
  };

  const handleSelectAllUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedAllUsers(users.map((user) => user.identifiant));
    } else {
      setSelectedAllUsers([]);
    }
  };

  const formattedUsers: any = [
    ...users.map((user: allUsers) => ({
      value: user.identifiant,
      label: user.firstname + " " + user.lastname,
    })),
  ];

  const customContentRenderer = ({ props }: any) => {
    const { placeholder, values } = props;
    const selectedCount = values.length;
    let content = "";

    if (selectedCount === 0) {
      content = placeholder;
    } else {
      content = `${selectedCount} acteurs sélectionnés`;
    }

    return (
      <div className="dropdown-content">
        <input
          className="dropdown-input rounded"
          readOnly
          tabIndex={-1}
          value={content}
        />
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col">
      <Dropdown
        options={formattedUsers}
        values={selectedUsersActeurs}
        onChange={handleSelectChange}
        multi
        searchable
        clearable
        keepSelectedInList
        closeOnSelect={false}
        contentRenderer={customContentRenderer}
        dropdownHeight="200px"
      />
      <div className="flex pt-1">
        <input
          id="select-all-users"
          type="checkbox"
          className="mr-2"
          onChange={handleSelectAllUsers}
          checked={selectedUsersActeurs.length == 0}
        />
        <label htmlFor="select-all-users" className="text-xs">
          Sélectionner tous les utilisateurs
        </label>
      </div>
    </div>
  );
};

export default SelectUsersFilterTime;
