import { useCurrentLangContext } from "../../context/LangContext";

/**
 * @function getCurrentWeekDays
 * @param {Date | null} startDate - The start date
 * @returns {Object[]} An array of 7 objects containing the day of the week and the day of the month
 * @description This function takes a start date and returns the current week days from this date. If no start date is provided, the current date is used.
 * @author IRIS SUCCI
 * @since 2023-06-30
*/
export const getCurrentWeekDays = (startDate: Date | null, lang: string ) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const start = startDate || new Date();
  // Adjustment to find the nearest past Monday
  start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
  
  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(start);
    date.setDate(date.getDate() + i);
    let formattedDate;
    if (lang === "FR") {
       formattedDate = new Intl.DateTimeFormat('fr-FR', { weekday: 'long', day: 'numeric' }).format(date);
    } else { 
       formattedDate = new Intl.DateTimeFormat('en-EN', { weekday: 'long', day: 'numeric' }).format(date);
    }
    const dayOfMonth = String(date.getDate()).padStart(2, '0');
    return {
      dayOfWeek: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
      dayOfMonth: dayOfMonth,
      fullDate: date 
    };
  });
};


/**
 * @function generateWeekData
 * @param {Date | null} startDate - The start date
 * @param {DataType[]} data - The array of data
 * @returns {Object} An object containing the week data array and the week days
 * @description This function takes a start date and a data array, and returns the current week data and the week days.
 * @author IRIS SUCCI
 * @since 2023-06-30
 */
export const generateWeekData = (startDate: Date | null, data: any, dataDaysOffWeek: any, lang : string) => {
  const days7 = getCurrentWeekDays(startDate, lang);
  // Sort data by date
  const sortedData = data.sort((a: any, b: any) =>
    new Date(a.AA_ANA_DATEREALISATION).getTime() - new Date(b.AA_ANA_DATEREALISATION).getTime()
  );

  // Create an empty array for each day of the week
  const weekData: any = days7.map(() => []);

  // Breaks down the data into the corresponding tables
  sortedData.forEach((item: any) => {
    const itemDate = new Date(item.AA_ANA_DATEREALISATION).getDate();
    const index = days7.findIndex((day) => parseInt(day.dayOfMonth, 10) === itemDate);
    if (index !== -1) {
      weekData[index].push(item);
    }
  });

  dataDaysOffWeek.forEach((dayOff: any) => {
    const itemDate = new Date(dayOff.JCP_JOUR).getDate();
    const index = days7.findIndex((day) => parseInt(day.dayOfMonth, 10) === itemDate);
    if (index !== -1) {
      weekData[index].push(dayOff);
    }
  });

  return { weekData, days7 };
};


/**
 * @function formatDate
 * @param {Date | null} startDate - The start date
 * @returns {string} A formatted string of the weekday and the day of the month
 * @description This function takes a start date and returns a formatted string of the weekday and the day of the month. If no start date is provided, an empty string is returned.
 * @author IRIS SUCCI
 * @since 2023-06-30 
*/
export const formatDate = (startDate: Date | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { lang } = useCurrentLangContext();

  if (startDate) {
    const date = new Date(startDate);
    let formattedDate;
    if (lang === "FR") {
       formattedDate = new Intl.DateTimeFormat('fr-FR', { weekday: 'long', day: 'numeric' }).format(date);
    } else { 
       formattedDate = new Intl.DateTimeFormat('en-EN', { weekday: 'long', day: 'numeric' }).format(date);
    }
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }
  
  return '';
}


/**
 * @function calculateDailyTotals
 * @param {DataType[][]} weekData - The week data array
 * @returns {number[]} An array of daily totals in minutes
 * @description This function takes a week data array and returns an array of daily totals in minutes.
 * @author IRIS SUCCI
 * @since 2023-06-30 
 */
export const calculateDailyTotals = (weekData: any) => {
  return weekData.map((dayData: any) =>
    dayData.reduce((totalMinutes: number, item: any) => {
      if (!item.JCP_LIBELLE) {
        return totalMinutes + (item.AA_ANA_TEMPS * 60 || 0);
      } else {
        return totalMinutes;
      }
    }, 0)
  );
};

/**
 * @function calculateDayTotals
 * @param {DataType[]} dayData - The day data array
 * @returns {number} The day total in minutes
 * @description This function takes a day data array and returns the day total in minutes.
 * @author IRIS SUCCI
 * @since 2023-06-30 
 */
export const calculateDayTotals = (dayData: any) => {
  return dayData.reduce((totalMinutes: number, item: any) => {
    return totalMinutes + item.AA_ANA_TEMPS * 60;
  }, 0);
}

/**
 * @function calculateWeekTotals
 * @param {DataTypeWeek[]} dataTimeWeekByClient - The week data array by client
 * @returns {number} The week total in minutes
 * @description This function takes a week data array by client and returns the week total in minutes.
 * @author IRIS SUCCI
 * @since 2023-06-30 
 */
export const calculateWeekTotals = (dataTimeWeekByClient: any) => {
  return dataTimeWeekByClient.reduce((totalMinutes: number, item: any) => {
    const [hours, minutes] = item.time.split(":").map(Number);
    return totalMinutes + hours * 60 + minutes;
  }, 0);
};

/** 
 * @function dateFormatted
 * @param {Date | null} date - The date to be formatted.
 * @returns {string} The formatted date string.
 * @description Formats a given Date object into a 'MM/DD/YYYY' string. Throws an error if the date is null.
 * @throws {Error} If the provided date is null.
 * @author Succi Iris
 * @since 2023-06-30
 */
export const dateFormatted = (date: Date | null): string => {
  if (!date) {
    throw new Error('La date ne peut pas être null');
  }
  
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
};

/* déso pour la fonction eclatée on me demande des dingueries */
export const dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso = (date: any): any => {
  if (!date || date === null) {
    return "";
  }
  
  let dateObj;
  if (typeof date === 'string') {
    dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) { // Vérifie si la date est invalide
      return "";
    }
  } else if (date instanceof Date) {
    dateObj = date;
  } else {
    return ""; // Retourne une chaîne vide si le type n'est ni une chaîne ni un objet Date
  }
  
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
};


 /** 
 * @function getMonday
 * @param {Date | null} date - The date to be formatted.
 * @returns {Date} A new Date object representing the Monday of the week of the given date.
 * @description Returns the Monday of the week for a given Date object.
 * @throws {Error} If the provided date is null.
 * @author Succi Iris
 * @since 2023-06-30
 */
export const getMonday= (date: any) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  const monday = new Date(date);
  monday.setDate(diff);
  return monday;
}

/**
 * @function convertToHoursAndMinutes
 * @param {number} time - The decimal time value to be converted.
 * @returns {string} A string representing the time in hours and minutes format.
 * @description Converts a decimal time value to a formatted string representing hours and minutes.
 * @author Succi Iris
 * @since 2023-06-30
 */
export const convertToHoursAndMinutes = (time: any) => {
  const hours = Math.floor(time);
  const minutes = Math.round((time % 1) * 60);
  const paddedMinutes = String(minutes).padStart(2, '0');
  return `${hours}h${paddedMinutes}m`;
}

/**
 * @function dateFormattedFr
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} The formatted date string in the 'DD/MM/YYYY' format.
 * @description Formats a given date string into the French date format 'DD/MM/YYYY'.
 * @since 2023-06-30
 * @author Succi Iris
 */
export const dateFormattedFr = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const delphi2html_color = (int_delphi_color = 0) => {
  // Convertit une couleur Delphi (valeur entière) en un code hexadecimal HTML (RGB).
  // Cette conversion est nécessaire car dans la valeur entière Delphi, les 2 valeurs
  // hexadécimales de poids faible (Bleu) et de poids fort (Rouge), sont permutées.
  // Ils faut donc permuter ces valeurs pour obtenir un RGB HTML correct.
  // Renvoi la couleur en hexadecimal, comme une chaîne (sans le dièse).
  // Si int_delphi_color = 0, ce signifie que la couleur n'est pas définie. Dans ce
  // cas on renvoi du blanc (FFFFFF), sinon on aurait du noir (000000).

  if (int_delphi_color == 0 || int_delphi_color == null || int_delphi_color == undefined) {
    // on renvoi du blanc:
    return '#FFFFFF';  
  }

  // on convertit la couleur Delphi en hexa:
  let str_hexa = int_delphi_color.toString(16);

  // Si nécessaire, on complète à 6 caractères, avec des zéros à gauche:
  while (str_hexa.length < 6) {
    str_hexa = '0' + str_hexa;
  }

  // on permute les valeurs de poids faible et fort:
  const str_hexa_html = str_hexa.slice(4, 6) + str_hexa.slice(2, 4) + str_hexa.slice(0, 2);

  return '#' +str_hexa_html;
}

/**
 * @function deleteParentheses
 * @param {string} 
 * @returns {string} delete parentheses in string
 * @description delete parentheses in string
 * @since 2023-06-30
 * @author Succi Iris
 */
export const deleteParentheses = (string: string) => {
  const regex = /\([^()]*\)/g;
  return string.replace(regex, "");
}