import axios from "axios";
import { axiosWithToken } from "./axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get mission
 */

export const getMissionByDosRef = (DOS_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/mission/bydosref/${DOS_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getModels = () => {
    try {
        const response = axiosWithToken().get(`api/mission/models`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}