import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete billables/entry 
 */

export const getFeesOrigins = (DOS_REF : number, MIS_REF : number) => {
    try {
        const response = axiosWithToken().get(`api/billables/entry/origin?DOS_REF=${DOS_REF}&MIS_REF=${MIS_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}