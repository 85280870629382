import { create } from 'zustand';

type dataStore = {
    fees: any[];
    setFees: (feels: any ) => void;

    invoices: any[];
    setInvoices: (invoice: any ) => void;

    feesFilter: any[];
    setFeesFilter: (feesFilter: any ) => void;

    invoicesFilter: any[];
    setInvoicesFilter: (invoicesFilter: any ) => void;

    credits: any;
    setCredits: (credits: any ) => void;

    creditsFilter: any[];
    setCreditsFilter: (creditsFilter: any ) => void;

    provisionals: any[];
    setProvisionals: (provisionals: any ) => void;

    provisionalFilter: any[];
    setProvisionalFilter: (provisionalFilter: any ) => void;

    proformats: any[];
    setProformats: (proformats: any ) => void;

    proformatsFilter: any[];
    setProformatsFilter: (proformatsFilter: any ) => void;

    acomptes: any[];
    setAcomptes: (acomptes: any ) => void;

    acomptesFilter: any[];
    setAcomptesFilter: (acomptesFilter: any ) => void;

    cashInFlows: any[];
    setCashInFlows: (cashInFlow: any ) => void;

    cashInFlowFilters: any[];
    setCashInFlowFilters: (cashInFlowFilters: any ) => void;

    slips: any[];
    setSlips: (slips: any ) => void;

    slipsFilter: any[];
    setSlipsFilter: (slipsFilter: any ) => void;

    relaunchs: any[];
    setRelaunchs: (relaunchs: any) => void;

    relaunchsFilter: any[];
    setRelaunchsFilter: (relaunchsFilter: any) => void;
};

export const dataInvoicesFeelsStore = create<dataStore>((set) => ({
    fees: [],
    setFees: (fees) => set({ fees }),

    invoices: [],
    setInvoices: (invoices) => set({ invoices }),

    feesFilter: [],
    setFeesFilter: (feesFilter) => set({ feesFilter }),

    invoicesFilter: [],
    setInvoicesFilter: (invoicesFilter) => set({ invoicesFilter }),

    credits: [],
    setCredits: (credits) => set({ credits }),

    creditsFilter: [],
    setCreditsFilter: (creditsFilter) => set({ creditsFilter }),

    provisionals: [],
    setProvisionals: (provisionals) => set({ provisionals }),

    provisionalFilter: [],
    setProvisionalFilter: (provisionalFilter) => set({ provisionalFilter }),

    proformats: [],
    setProformats: (proformats) => set({ proformats }),

    proformatsFilter: [],
    setProformatsFilter: (proformatsFilter) => set({ proformatsFilter }),
    
    acomptes: [],
    setAcomptes: (acomptes) => set({ acomptes }),

    acomptesFilter: [],
    setAcomptesFilter: (acomptesFilter) => set({ acomptesFilter }),

    cashInFlows: [],
    setCashInFlows: (cashInFlows) => set({ cashInFlows }),

    cashInFlowFilters: [],
    setCashInFlowFilters: (cashInFlowFilters) => set({ cashInFlowFilters }),

    slips: [],
    setSlips: (slips) => set({ slips }),

    slipsFilter: [],
    setSlipsFilter: (slipsFilter) => set({ slipsFilter }),

    relaunchs: [],
    setRelaunchs: (relaunchs) => set({ relaunchs }),

    relaunchsFilter: [],
    setRelaunchsFilter: (relaunchsFilter) => set({ relaunchsFilter }),
}));