import { useViewStore } from "../../../store/TimeManagement/viewStore";
import { useTranslation } from "react-i18next";
import { postTimeValidate } from "../../../utils/TimeManager/time";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { errorSelectTime } from "../../../utils/toaster";
import { useLoadingStore } from "../../../store/utils/loading";

/**
 * @component
 * @name ValidateTime
 * @author Succi Iris
 * @date 2023
 * @description
 * The `ValidateTime` component renders a button that triggers the validation of selected times for confirmation.
 */
const ValidateTime = ({ setRenderData }: any) => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { setView } = useViewStore();
  const { setIsLoading } = useLoadingStore();
  const { selectedTimeForValidated, setSelectedTimeForValidated } =
    useListStore();

  const handleValidate = () => {
    setIsLoading(true);
    postTimeValidate(selectedTimeForValidated)
      .then(() => {
        setIsLoading(false);
        setSelectedTimeForValidated([]);
        setRenderData((prev: any) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <button
      className="w-20 lg:h-9 h-6 border-[1px] border-bc-green rounded flex justify-center items-center mr-2 px-2 shadow-md"
      onClick={() => {
        setView("list");
        if (selectedTimeForValidated.length === 0) return errorSelectTime();
        else handleValidate();
      }}
    >
      {t("Valider")}
    </button>
  );
};

export default ValidateTime;
