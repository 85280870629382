import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @description Call API to get 
 */

export const getChartsProduction = () => {
    try {
        const response = axiosWithToken().get(`api/charts/production`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getChartsBillables = () => {
    try {
        const response = axiosWithToken().get(`api/charts/billin`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getChartsCashInFlow = () => {
    try { 
        const response = axiosWithToken().get(`api/charts/cashflow`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getChartsProductionPercentage = () => {
    try {
        const response = axiosWithToken().get(`api/charts/production/percentage`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getChartsBillablesPercentage = () => {
    try {
        const response = axiosWithToken().get(`api/charts/billin/percentage`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getChartsCashInFlowPercentage = () => {
    try {
        const response = axiosWithToken().get(`api/charts/cashflow/percentage`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}