import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { printPlugin } from "@react-pdf-viewer/print";
import "@react-pdf-viewer/print/lib/styles/index.css";
import printAsset from "../../assets/icons/printer.svg";
import {
  RenderCurrentPageLabelProps,
  RenderGoToPageProps,
  pageNavigationPlugin,
} from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import chevronUp from "../../assets/icons/chevron-upThin.svg";
import chevronDown from "../../assets/icons/chevron-downThin.svg";
import download from "../../assets/icons/downloadBlack.svg";
import { RenderDownloadProps, getFilePlugin } from "@react-pdf-viewer/get-file";
import { useLoadingStore } from "../../store/utils/loading";
import { useTabsFSStore } from "../../store/FinancialStatement/tabsStore";
import CircleLoarder from "../TimeManager/loader/CircleLoarder";

/**
 * @component
 * @name PdfViewer
 * @description
 * A React component that displays a PDF file using the PDF.js library.
 * It also includes page navigation buttons, a print button, and a download button.
 * @author Iris Succi
 * @date 2023
 */
const PdfViewer = () => {
  // Store
  const { isLoading } = useLoadingStore();
  const { documentUrl, count } = useTabsFSStore();

  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToFirstPage, GoToNextPage, CurrentPageLabel } =
    pageNavigationPluginInstance;

  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  return (
    <div className="flex flex-col justify-center items-start w-full h-[83%] m-auto mt-4 border-[1px] rounded-t-md">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
      <div className="w-full flex justify-between items-center h-8 bg-[#cecece] rounded-t-md sticky top-0">
        <div className="ml-4 pt-2">
          <GoToFirstPage>
            {(props: RenderGoToPageProps) => (
              <button onClick={props.onClick} className="mr-4">
                <img src={chevronUp} alt="chevron" />
              </button>
            )}
          </GoToFirstPage>
          <GoToNextPage>
            {(props: RenderGoToPageProps) => (
              <button
                disabled={props.isDisabled}
                onClick={props.onClick}
                className="mr-4"
              >
                <img src={chevronDown} alt="chevron" />
              </button>
            )}
          </GoToNextPage>
        </div>
        <CurrentPageLabel>
          {(props: RenderCurrentPageLabelProps) => (
            <span className="font-light text-sm">{`${
              props.currentPage + 1
            } of ${props.numberOfPages}`}</span>
          )}
        </CurrentPageLabel>
        <div className="mr-4">
          <button onClick={print} className="pl-2 my-2 mr-4">
            <img src={printAsset} alt="imprimer" />
          </button>
          <Download>
            {(props: RenderDownloadProps) => (
              <button onClick={props.onClick}>
                <img src={download} alt="Télécharger" />
              </button>
            )}
          </Download>
        </div>
      </div>{" "}
      {documentUrl === "" && count === 0 ? (
        <p className="h-full w-full text-center mt-2 italic font-thin">
          Vous n'avez pas encore généré de pdf
        </p>
      ) : isLoading && count !== 0 ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
          <CircleLoarder />
        </div>
      ) : (
        documentUrl !== "" && (
          <Viewer
            fileUrl={documentUrl}
            plugins={[
              printPluginInstance,
              pageNavigationPluginInstance,
              getFilePluginInstance,
            ]}
            withCredentials={true}
          />
        )
      )}
    </div>
  );
};

export default PdfViewer;
