import { create } from "zustand";

type myState = {
    startDate: Date;
    setStartDate: (startDate: Date) => void;

    bankCash: any[];
    setBankCash: (bankCash: any) => void;
    
    selectedBankCash: any[];
    setSelectedBankCash: (selectedBankCash: any) => void;

    slipDocument: any;
    setSlipDocument: (slipDocument: any) => void;

    abaRef: any;
    setAbaRef: (abaRef: any) => void;
}

export const useSlip = create<myState>((set) => ({
    startDate: new Date(),
    setStartDate: (startDate) => set({ startDate }),

    bankCash: [],
    setBankCash: (bankCash) => set({ bankCash }),

    selectedBankCash: [],
    setSelectedBankCash: (selectedBankCash) => set({ selectedBankCash }),

    slipDocument: "",
    setSlipDocument: (slipDocument) => set({ slipDocument }),

    abaRef: 0,
    setAbaRef: (abaRef) => set({ abaRef }),
}));