/**
 * @function decrementDate
 * @param {Date} startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description This function decrements the start date by 7 days and sets this new date as the start date. If no start date is provided, no action is taken.
 * @author IRIS SUCCI
 * @since 2023-06-30  
*/
export const decrementDate = (startDate : Date, setStartDate: (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 7);
      setStartDate(newDate);
    }
  };

export const decrementDateByDay = (startDate : Date, setStartDate: (date: Date) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 1);
      setStartDate(newDate);
    }
  };

/**
 * @function incrementDate
 * @param {Date } startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description This function increments the start date by 7 days and sets this new date as the start date. If no start date is provided, no action is taken.
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const incrementDate = (startDate : Date , setStartDate : (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 7);
      setStartDate(newDate);
    }
  };
  
  
export const incrementDateByDay = (startDate : Date, setStartDate : (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 1);
      setStartDate(newDate);
    }
  };


/**
 * @function getFormattedDate
 * @returns {string} The current date formatted as DD/MM/YYYY
 * @description This function returns the current date formatted as DD/MM/YYYY.
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const getFormattedDate = () => {
  const date = new Date();
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
};

export const getFormattedDateEN = (date: any) => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
};

export const getFormattedDateEN2 = (dateStr: string) => {
  const date = new Date(dateStr);

  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
};
