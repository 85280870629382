import { useTranslation } from "react-i18next";
import FilterInvoice from "../modal/Filters/FilterInvoice";
import { useLocation } from "react-router-dom";
import FilterCost from "../../Cost/FilterCost";

/**
 * @component
 * @name Filter
 * @description
 * Filter est un composant qui permet d'afficher le bouton pour ouvrir le modal de filtre.
 *
 * @param {function} openModal - A function to open the filter modal.
 * @param {function} closeModal - A function to close the filter modal.
 */
const Filter = ({ openModal, closeModal }: any) => {
  //Translation
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;

  return (
    <button
      className="w-24 h-8 bg-bc-orange border-[1px] flex justify-center items-center px-2 rounded text-white hover:bg-bc-lightOrange transition-colors duration-200 "
      onClick={() => {
        if (openModal && path === "/costs") {
          openModal(<FilterCost closeModal={closeModal} />);
        } else {
          openModal(<FilterInvoice closeModal={closeModal} />);
        }
      }}
    >
      {t("Filtre")}
    </button>
  );
};

export default Filter;
