import { create } from "zustand";

type State = {
  choiceTab: string;
  setChoiceTab: (choiceTab: string) => void;

  choiceTabEvoOrRev: string;
  setChoiceTabEvoOrRev: (choiceTabEvoOrRev: string) => void;

  data: any[];
  setData: (data: any[]) => void;
};

export const useUtilsAnalyseStore = create<State>((set) => ({
  choiceTab: "Production",
  setChoiceTab: (choiceTab) => set({ choiceTab }),

  choiceTabEvoOrRev: "Evolution",
  setChoiceTabEvoOrRev: (choiceTabEvoOrRev) => set({ choiceTabEvoOrRev }),

  data: [],
  setData: (data) => set({ data }),
}));
