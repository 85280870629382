/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useUtilsAnalyseStore } from "../../store/Analyse/utilsAnalyse";
import { useLoadingStore } from "../../store/utils/loading";
import CircleLoarder from "../TimeManager/loader/CircleLoarder";
import { useEffect, useState } from "react";

/**
 * @author Iris Succi
 * @function getRandomColor
 * @description Genere une couleur aléatoire
 * @param none
 * @date 23/01/2024
 * @returns random color
 */
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const colors: { [key: string]: string } = {};
/**
 * @author Iris Succi
 * @function getColorForKey
 * @description add grey color
 * @param none
 * @date 23/01/2024
 * @returns grey color or normal color
 */
const getColorForKey = (key: string, isGrey: boolean = false) => {
  if (!colors[key]) {
    colors[key] = getRandomColor();
  }
  const color = colors[key];

  // Si isGrey est vrai, retourne la couleur avec une opacité réduite
  if (isGrey) {
    // Converti la couleur hex en RGB et applique une opacité
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }

  // Sinon, retourne la couleur normale
  return color;
};

/**
 * @author Iris Succi
 * @function getMaxYAxisValue
 * @description This function is used to get the maximum value of the Y axis
 * @param data
 * @date 23/01/2024
 * @returns maxVal
 */
const getMaxYAxisValue = (data: any) => {
  let maxVal = 0;

  // Parcoure toutes les données pour calculer les totaux mensuels
  data.forEach((item: any) => {
    let totalN1 = 0;
    let totalCurrent = 0;

    // Parcoure les propriétés de l'objet item
    Object.keys(item).forEach((key) => {
      // Accumulez les valeurs pour "_N-1"
      if (key.includes("_N-1")) {
        totalN1 += item[key];
      }
      // Accumule les valeurs pour "_current"
      else if (key.includes("_current")) {
        totalCurrent += item[key];
      }
    });

    // Prend le maximum entre les totaux de "_N-1" et "_current"
    const maxMonthlyTotal = Math.max(totalN1, totalCurrent);
    // Met à jour maxVal si nécessaire
    maxVal = Math.max(maxVal, maxMonthlyTotal);
  });

  // Arrondi au cent supérieur
  return Math.ceil(maxVal / 100) * 100;
};

/**
 * @function transformData
 * @description This function is used to transform the data
 * @date 23/01/2024
 * @author Iris Succi
 * @param productions
 * @returns newItem
 */
const transformData = (productions: any) => {
  return productions?.map((item: any) => {
    const newItem: { [key: string]: any } = {
      date: item.date,
    };

    item?.responsables?.forEach((responsable: any) => {
      const key = Object.keys(responsable)[0];
      newItem[key + "_current"] = responsable[key]; // Clé pour les données actuelles
    });

    item?.responsablesPrevious?.forEach((responsable: any) => {
      const key = Object.keys(responsable)[0];
      newItem[key + "_N-1"] = responsable[key]; // Clé pour les données précédentes
    });
    return newItem;
  });
};

/**
 * @function CustomTooltip
 * @description This function is used to create a custom tooltip
 * @date 23/01/2024
 * @param active
 * @param payload
 * @param label
 * @param colors
 * @author Iris Succi
 */
const CustomTooltip = ({ active, payload, label, colors }: any) => {
  if (active && payload && payload.length) {
    // Calculer le total pour les données actuelles et précédentes
    let totalCurrent = 0;
    let totalPrevious = 0;

    payload.forEach((entry: any) => {
      if (entry.name.includes("_current")) {
        totalCurrent += entry.value;
      } else if (entry.name.includes("_N-1")) {
        totalPrevious += entry.value;
      }
    });

    const currentData = payload.filter((entry: any) =>
      entry.dataKey.endsWith("_current")
    );
    const previousData = payload.filter((entry: any) =>
      entry.dataKey.endsWith("_N-1")
    );

    return (
      <div className="custom-tooltip border-[1px] bg-white rounded-sm p-1">
        <p>{label}</p>
        {currentData?.length > 0 ? <p>Données actuelles:</p> : null}
        <p className="font-bold">Total Actuel: {totalCurrent.toFixed(2)} k€</p>
        <ul>
          {currentData?.map((entry: any) => (
            <li key={entry.dataKey}>
              <span
                style={{ color: colors[entry.dataKey.replace("_current", "")] }}
                className="font-bold"
              >
                {entry.name.replace("_current", "")}:
              </span>{" "}
              {entry.value}
            </li>
          ))}
        </ul>
        <p>Données précédentes:</p>
        <p className="font-bold">
          Total précédent: {totalPrevious.toFixed(2)} k€
        </p>
        <ul>
          {previousData?.map((entry: any) => (
            <li key={entry.dataKey}>
              <span
                style={{ color: colors[entry.dataKey.replace("_N-1", "")] }}
                className="font-bold"
              >
                {entry.name.replace("_N-1", "")}:
              </span>{" "}
              {entry.value}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

/**
 * @function CustomLegend
 * @description This function is used to create a custom legend
 * @date 23/01/2024
 * @author Iris Succi
 * @param payload
 * @returns legend
 */
const CustomLegend = (props: any) => {
  const { payload } = props;

  // Construire un ensemble des clés 'baseKey' qui existent dans '_current'
  const currentKeysSet: Set<string> = new Set(
    payload
      .map((entry: any) => {
        return entry.value.includes("_current")
          ? entry.value.replace("_current", "")
          : null;
      })
      .filter((key: any) => key !== null)
  );

  return (
    <ul className="custom-legend w-full flex justify-center items-center">
      {Array.from(currentKeysSet).map((baseKey: string, index: number) => (
        <li
          key={`item-${index}`}
          style={{ color: colors[baseKey] }}
          className="mr-4 flex justify-center items-center"
        >
          <div
            style={{ backgroundColor: colors[baseKey] }}
            className=" w-2 h-2 mr-1"
          ></div>
          {baseKey}
        </li>
      ))}
    </ul>
  );
};

/**
 * @function Chart
 * @description This function is used to create a chart
 * @date 23/01/2024
 * @author Iris Succi
 * @param none
 * @returns chart
 */
const Chart = () => {
  const { isLoading } = useLoadingStore();
  const { data, choiceTab } = useUtilsAnalyseStore();
  // Initialiser l'état local
  const [transformedProductions, setTransformedProductions] = useState([]);
  const [maxYAxisValue, setMaxYAxisValue] = useState(0);

  useEffect(() => {
    const newTransformedProductions = transformData(data);
    setTransformedProductions(newTransformedProductions);

    const newMaxYAxisValue = getMaxYAxisValue(newTransformedProductions);
    setMaxYAxisValue(newMaxYAxisValue);
  }, [data, choiceTab]); // Dépendances : data et choiceTab

  const yAxisTicks = [];
  for (let i = 0; i <= maxYAxisValue; i += 50) {
    yAxisTicks.push(i);
  }

  const barKeys1: any[] = [];
  const barKeys2: any[] = [];
  transformedProductions?.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      const baseKey = key.replace("_current", "").replace("_N-1", "");
      if (key.includes("_current") && !barKeys1.includes(key)) {
        barKeys1.push(key);
        if (!colors[baseKey]) {
          colors[baseKey] = getRandomColor();
        }
      } else if (key.includes("_N-1") && !barKeys2.includes(key)) {
        barKeys2.push(key);
      }
    });
  });

  const currentKeys: { [key: string]: boolean } = {};
  transformedProductions?.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      if (key.includes("_current")) {
        const baseKey = key.replace("_current", "");
        currentKeys[baseKey] = true;
      }
    });
  });

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey mt-4">
          <CircleLoarder />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={transformedProductions}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              domain={[0, maxYAxisValue]}
              ticks={yAxisTicks}
              tickFormatter={(value) => `${value} K€`}
            />
            <Tooltip content={<CustomTooltip colors={colors} />} />
            <Legend content={<CustomLegend />} />
            {barKeys1?.map((key) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={getColorForKey(key.replace("_current", ""))}
              />
            ))}
            {barKeys2?.map((key) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="b"
                fill={getColorForKey(key.replace("_N-1", ""), true)}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Chart;
