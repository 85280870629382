import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * @file i18n.ts
 * @description
 File used to manage the translation of the application
 * @author Iris Succi
 * @date 2023
 */

const resources = {
    FR: {
        translation: {
            // Page de connexion
            "Identifiant" : "Identifiant",
            "Mot de passe" : "Mot de passe",
            "Se connecter" : "Se connecter",
            "Mot de passe oublié" : "Mot de passe oublié",
            "A vos cotés, hier, aujourd'hui et demain" : "A vos cotés, hier, aujourd'hui et demain",

            // Page d'accueil
            "Etats de production" : "Etats de production",
            "Gestion de temps" : "Gestion de temps",
            "Facturation" : "Facturation",
            "Echange de documents" : "Echange de documents",
            "Calendrier": "Calendrier",
            "Chrono": "Chrono",

            // Page de gestion de temps
            "Ajouter un temps" : "Ajouter un temps",
            "Semaine" : "Semaine",
            "Jour" : "Jour",
            "Mois" : "Mois",
            "Dossier" : "Dossier",
            "Mission" : "Mission",
            "Origine" : "Origine",
            "Libellé" : "Libellé",
            "Tx horaire" : "Tx horaire",
            "Commentaire" : "Commentaire",
            "Edition" : "Saisie", 
            "Total heures" : "Total heures",
            "Valider" : "Valider",
            "Annuler" : "Annuler",
            "Ajouter un commentaire" : "Ajouter un commentaire",
            "Fermer" : "Fermer",
            "Total" : "Total",
            "Imprimer" : "Imprimer",
            "Aujourd'hui" : "Aujourd'hui",
            "Retour": "Retour",
            "Liste": "Liste",
            "Filtre": "Filtre",
            "Filtrer les temps": "Filtrer les temps",
            "Du": "Du",
            "Au": "Au",
            "Modèle": "Modele",
            "Acteur": "Acteur",
            "Variable": "Variable",
            "Etat": "Etat",
            "Tout" : "Tout",
            "Cloturée": "Cloturée",
            "En cours": "En cours",
            "Toutes les écritures": "Toutes les écritures",
            "Ecritures validées": "Ecritures validées",
            "Ecriture non validées": "Ecriture non validées",
            "Classeur": "Classeur",
            "Temps": "Temps",
            "Effacer les filtres": "Effacer les filtres",
            "Recherche un dossier": "Recherche un dossier",

            //Page honoraire
            "Honoraires": "Honoraires",
            "Filtrer les honoraires": "Filtrer les honoraires",
        }
    },
    EN: {
        translation: {
            // Page de connexion
            "Identifiant" : "Identifiant",
            "Mot de passe" : "Password",
            "Se connecter" : "Log in",
            "Mot de passe oublié" : "Forgot password",
            "A vos cotés, hier, aujourd'hui et demain" : "By your side, yesterday, today and tomorrow",

            // Page d'accueil
            "Etats de production" : "Production states",
            "Gestion de temps" : "Time management",
            "Facturation" : "Invoicing",
            "Echange de documents" : "Document exchange",
            "Calendrier": "Calendar",
            "Chrono": "Chrono",

            // Page de gestion de temps
            "Ajouter un temps" : "Add time",
            "Semaine" : "Week",
            "Jour" : "Day",
            "Mois" : "Month",
            "Dossier" : "File",
            "Mission" : "Mission",
            "Origine" : "Origin",
            "Libellé" : "Label",
            "Tx horaire" : "Hourly rate",
            "Commentaire" : "Comment",
            "Edition" : "Edit",
            "Total heures" : "Total hours",
            "Valider" : "Validate",
            "Annuler" : "Cancel",
            "Ajouter un commentaire" : "Add a comment",
            "Fermer" : "Close",
            "Total" : "Total",
            "Imprimer" : "Print",
            "Aujourd'hui" : "Today",
            "Retour": "Back",
            "Liste": "List",
            "Filtre": "Filter",
            "Filtrer les temps": "Filter times",
            "Du": "From",
            "Au": "To",
            "Modèle": "Model",
            "Acteur": "Editor",
            "Variable": "Variable",
            "Etat": "State",
            "Tout" : "All",
            "Cloturée": "Closed",
            "En cours": "In progress",
            "Toutes les écritures": "All writings",
            "Ecritures validées": "Validated writings",
            "Ecriture non validées": "Non-validated writings",
            "Classeur": "Binder",
            "Temps": "Time",
            "Effacer les filtres": "Clear filters",
            "Recherche un dossier": "Search a file",

            //Page honoraire
            "Honoraires": "Fees",
            "Filtrer les honoraires": "Filter fees",
        }
    }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "FR", 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;