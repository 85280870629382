import { getDossiers } from "../../../../utils/folders";
import DossiersTree from "./DossiersTree";
import { useSearchBarStore } from "../../../../store/utils/searchBar";
import { useExplorer } from "../../../../store/Crm/Explorer";

/**
 * @component
 * @name ClasseursTree
 * @description A component that displays a "classeur" and its list of dossiers.
 * @author Iris Succi
 * @date 2023
 */
const ClasseursTree = ({ classeur, isOpen, onToggle }: any) => {
  // Store
  const { search } = useSearchBarStore();
  const { dossiers, setDossiers, setPDOS_ID } = useExplorer();

  /**
   * @function
   * @name handleOpen
   * @description Toggles the open/close state of the "classeur" and fetches the list of dossiers if the classeur is opened.
   * @author Iris Succi
   * @date 2023
   */
  const handleOpen = () => {
    // I get the PDOS_ID to be able to display the interlocutors and files
    setPDOS_ID(classeur.PDOS_ID);
    if (!isOpen) {
      getDossiers(classeur.PDOS_ID)
        .then((res) => {
          const sortedDossiers = res?.data.sort((a: any, b: any) => {
            return a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT);
          });
          setDossiers(sortedDossiers);
        })
        .catch((err) => console.log(err));
    }
    onToggle();
  };

  const filteredLocalDossiers = dossiers.filter(
    (dossier: any) =>
      dossier.FIC_TITRE_CLIENT.toLowerCase().includes(search.toLowerCase()) ||
      dossier.DOS_CODE.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="w-full relative ml-4">
      <div className="flex justify-start items-center cursor-pointer">
      <div onClick={handleOpen} className="font-light w-full flex justify-start items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill={classeur?.CLASSEUR_COLOR}
          stroke={classeur?.CLASSEUR_COLOR}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-folder"
        >
          <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
        </svg>
        
          <p className="pl-1">{classeur?.PDOS_NOM}</p>
        </div>
      </div>
      {isOpen &&
        filteredLocalDossiers &&
        filteredLocalDossiers.length > 0 &&
        filteredLocalDossiers?.map((dossier: any) => (
          <div className="relative">
            <div className=" absolute " />
            <DossiersTree dossier={dossier} />
          </div>
        ))}
    </div>
  );
};

export default ClasseursTree;
