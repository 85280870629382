import { useState, useEffect } from "react";
/**
 * @hook
 * @name useUnsavedChangesWarning
 * @author Iris Succi
 * @date 2023
 * A custom React hook for warning users about unsaved changes before leaving a page.
 *
 * This hook provides a mechanism to detect if the user is in the middle of typing or making changes and
 * prompts them with a warning message when they attempt to leave the page, helping to prevent data loss.
 *
 * @returns {Array} An array containing:
 *  - isTyping: A boolean state indicating whether the user has unsaved changes (is in the process of typing).
 *  - setIsTyping: A function to set the isTyping state.
 *
 * Usage:
 * const [isTyping, setIsTyping] = useUnsavedChangesWarning();
 *
 * Attach `setIsTyping` to onChange events of input elements to track changes.
 * The hook will automatically prompt the user with a warning message if they try to leave the page with unsaved changes.
 */

const useUnsavedChangesWarning = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [isTyping, setIsTyping] = useState(false);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (isTyping) {
      const message =
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?";
      event.returnValue = message;
      return message;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isTyping]);

  return [isTyping, setIsTyping];
};

export default useUnsavedChangesWarning;
